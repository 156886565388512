import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import clsx from "clsx";
import { FormikErrors, useFormik } from "formik";
import React, { useEffect, useState, useRef } from "react";
import BannerService from "../../services/BannerService";
import { BannerType } from "../../types/type";
import { BannerImage } from "../BannerImage/BannerImage";
import { DataInput } from "../DataInput/DataInput";
import s from "./Banner.module.scss";

type Props = {
    creating: boolean;
    addBanner?: (banner: BannerType) => void;
    delBanner?: (id: number) => void;
} & Partial<BannerType>;

type BannerForm = {
    desktopImage: File | string;
    mobileImage: File | string;
    link: string;
    title: string;
    priority: string;
    enabled: boolean;
};

const validate = (values: BannerForm) => {
    const errors: FormikErrors<BannerForm> = {};

    if (!values.link) {
        errors.link = "Обязательное поле";
    }
    if (!values.title) {
        errors.title = "Обязательное поле";
    }
    if (!values.priority) {
        errors.priority = "Обязательное поле";
    } else if (Number.isNaN(+values.priority)) {
        errors.priority = "Приоритет должен быть числом";
    }
    if (!values.desktopImage) {
        errors.desktopImage = "Обязательное поле";
    }
    if (!values.mobileImage) {
        errors.mobileImage = "Обязательное поле";
    }

    return errors;
};

export const Banner: React.FC<Props> = ({
    id,
    creating,
    link,
    title,
    priority,
    enabled,
    desktopImage,
    mobileImage,
    addBanner,
    delBanner,
}) => {
    const [validationWay, setWay] = useState(!creating);
    const [changed, setChanged] = useState(creating);
    const [links, setLinks] = useState({
        desktopImage: desktopImage ?? "",
        mobileImage: mobileImage ?? "",
    });
    const formik = useFormik({
        initialValues: {
            desktopImage: desktopImage ?? "",
            mobileImage: mobileImage ?? "",
            link: link ?? "",
            title: title ?? "",
            priority: priority === undefined ? "" : `${priority}`,
            enabled: enabled ?? false,
        },
        validate: (values) => {
            setChanged(true);
            return validate(values);
        },
        validateOnChange: validationWay,
        validateOnBlur: validationWay,
        onSubmit: (values) => {
            const bs = new BannerService();
            const jwt = window.localStorage.getItem("jwt") ?? "";
            if (creating) {
                const formdata = new FormData();
                formdata.append("desktopImage", values.desktopImage);
                formdata.append("mobileImage", values.mobileImage);
                formdata.append(
                    "bannerCreationDto",
                    new Blob(
                        [
                            JSON.stringify({
                                link: values.link,
                                title: values.title,
                                priority: values.priority,
                                enabled: values.enabled,
                            }),
                        ],
                        { type: "application/json" }
                    )
                );
                bs.createBanner(formdata, jwt)
                    .then((res) => {
                        if (addBanner)
                            addBanner({
                                ...values,
                                priority: +values.priority,
                                id: res.bannerId,
                                mobileImage: links.mobileImage,
                                desktopImage: links.desktopImage,
                            });
                        formik.resetForm();
                        setLinks({ desktopImage: "", mobileImage: "" });
                        setWay(false);
                    })
                    .catch((err) => console.log(err));
            } else {
                const formdata = new FormData();
                if (typeof values.desktopImage !== "string") {
                    formdata.append("newDesktopImage", values.desktopImage);
                } else {
                    formdata.append("newDesktopImage", new Blob(undefined));
                }
                if (typeof values.mobileImage !== "string") {
                    formdata.append("newMobileImage", values.mobileImage);
                } else {
                    formdata.append("newMobileImage", new Blob(undefined));
                }
                formdata.append(
                    "bannerChangingDto",
                    new Blob(
                        [
                            JSON.stringify({
                                id,
                                link: values.link,
                                title: values.title,
                                priority: values.priority,
                                enabled: values.enabled,
                            }),
                        ],
                        { type: "application/json" }
                    )
                );
                bs.changeBanner(formdata, jwt).catch((err) => console.log(err));
            }
            setChanged(false);
        },
    });

    useEffect(() => {
        if (formik.submitCount >= 1) setWay(true);
    }, [formik.submitCount]);

    const handleImageChange = (field: "desktopImage" | "mobileImage", img: File, tlink: string) => {
        formik.setFieldValue(field, img, validationWay);
        const newLinks = links;
        newLinks[field] = tlink;
        setLinks(newLinks);
    };

    const buttonSubmit: React.RefObject<HTMLButtonElement> | null | undefined = useRef(null);
    const handleClick = () => {
        if (buttonSubmit) {
            buttonSubmit?.current?.click();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        formik.setFieldValue("enabled", value, validationWay);
    };

    const deleteBanner = () => {
        if (delBanner && id) delBanner(id);
    };

    const color = creating ? "secondary" : "primary";

    return (
        <div className={clsx({ [s.container]: true, [s.container__coloured]: !creating })}>
            <Box className={s.banner}>
                <BannerImage
                    img={links.desktopImage}
                    title="Десктопная версия"
                    handleAdd={(blob: File, tlink: string) => handleImageChange("desktopImage", blob, tlink)}
                    error={formik.errors.desktopImage}
                />
                <BannerImage
                    img={links.mobileImage}
                    title="Мобильная версия"
                    handleAdd={(blob: File, tlink: string) => handleImageChange("mobileImage", blob, tlink)}
                    error={formik.errors.mobileImage}
                    mobile
                />
                <form onSubmit={formik.handleSubmit} className={s.form}>
                    <DataInput
                        label="Заголовок баннера"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        color={formik.errors.title ? "error" : color}
                        helperText={formik.errors.title}
                        variant={creating ? undefined : "outlined"}
                        name="title"
                        position={0}
                    />
                    <Box display="flex" gap="1.5rem">
                        <DataInput
                            label="Ссылка  перехода"
                            value={formik.values.link}
                            onChange={formik.handleChange}
                            color={formik.errors.link ? "error" : color}
                            helperText={formik.errors.link}
                            variant={creating ? undefined : "outlined"}
                            name="link"
                            position={0}
                        />
                        <DataInput
                            label="Приоритет"
                            value={formik.values.priority}
                            onChange={formik.handleChange}
                            color={formik.errors.priority ? "error" : color}
                            helperText={formik.errors.priority}
                            variant={creating ? undefined : "outlined"}
                            name="priority"
                            position={3}
                        />
                    </Box>
                    <FormControl>
                        <RadioGroup row sx={{ gap: "1.5rem" }} value={formik.values.enabled} onChange={handleChange}>
                            <FormControlLabel value control={<Radio />} label="Показан" />
                            <FormControlLabel value={false} control={<Radio />} label="Отключен" />
                        </RadioGroup>
                    </FormControl>
                    {creating && <Button type="submit" ref={buttonSubmit} hidden />}
                    {!creating && (
                        <Box display="flex" gap="1.5rem">
                            <Button onClick={deleteBanner} fullWidth variant="outlined">
                                Удалить
                            </Button>
                            <Button type="submit" disabled={!changed} fullWidth variant="contained">
                                Изменить
                            </Button>
                        </Box>
                    )}
                </form>
            </Box>
            {creating && (
                <Button onClick={handleClick} fullWidth variant="contained">
                    Добавить баннер
                </Button>
            )}
        </div>
    );
};
