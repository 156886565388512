import { Box, Typography, Divider, useMediaQuery } from "@mui/material";
import React from "react";
import { PersonalOrderForm } from "../../components/forms/PersonalOrder/PersonalOrderForm";

type Props = unknown;

export const PersonalOrder: React.FC<Props> = () => {
    const smallPC = useMediaQuery("(max-width: 1020px)");
    return (
        <Box mt={smallPC ? "1.5rem" : "70px"} mb="2rem" width="100%">
            <Typography variant="h1" mb={smallPC ? "1.5rem" : "2.25rem"}>
                Форма индивидуального заказа
            </Typography>
            <Divider />
            <Box
                display="flex"
                flexWrap="wrap"
                justifyContent={smallPC ? "center" : undefined}
                gap="3.75rem"
                rowGap="2rem"
                mt={smallPC ? "1.5rem" : "2rem"}>
                <PersonalOrderForm />
                <Typography variant="body1" maxWidth={smallPC ? undefined : "461px"}>
                    Мы принимаем индивидуальные заказы на лимитированные модели обуви в нужном вам размере, релиз
                    которых прошел недавно, давно или ожидается в скором времени. Заполнив форму индивидуального заказа,
                    вы становитесь нашим клиентом и ваш персональный менеджер сделает все возможное, чтобы найти
                    специально для вас эксклюзивную модель по вашему заказу.
                    <br />
                    <br />
                    Преимущество индивидуального заказа:
                    <ul>
                        <li>вы получите консультацию персонального менеджера по подбору обуви и размера</li>
                        <li>вы будете в курсе новых релизов и сможете в день релиза получить желаемую модель первым</li>
                        <li>вам не нужно тратить время на поиск желаемой модели</li>
                        <li>
                            каждая пара проходит проверку на оригинальность и вы можете быть уверены в качестве
                            приобретаемой обуви
                        </li>
                        <li>
                            предоплата индивидуального заказа требуется в том случае, если модель является редкой и
                            непопулярной
                        </li>
                    </ul>
                    <br />
                    <b>
                        Наша коллекция регулярно пополняется новыми моделями, поэтому мы с легкостью найдем, ту пару,
                        которая нужна именно ВАМ!
                    </b>
                </Typography>
            </Box>
        </Box>
    );
};
