import React from "react";
import clsx from "clsx";
import { url } from "../../constants/constants";
import s from "./LogoLink.module.scss";
import { ReactComponent as Logo } from "../../assets/Logo.svg";

type Props = { hidden?: boolean; small?: boolean };

export const LogoLink: React.FC<Props> = ({ hidden, small }) => {
    if (hidden) return null;

    return (
        <a href={url.main}>
            <div className={clsx({ [s.logo__small]: small, [s.logo__big]: !small, [s.logo]: true })}>
                <Logo />
            </div>
        </a>
    );
};
