import { Container, IconButton, Link, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import clsx from "clsx";
import { ReactComponent as Instagram } from "../../assets/Inst.svg";
import { ReactComponent as Visa } from "../../assets/payment/visa.svg";
import { ReactComponent as MasterCard } from "../../assets/payment/mastercard.svg";
import { ReactComponent as Mir } from "../../assets/payment/mir.svg";
import { ReactComponent as PayKeeper } from "../../assets/payment/paykeeper.svg";
import s from "./Footer.module.scss";
import { colors } from "../../theme";

export const Footer: React.FC = () => {
    const mobile = useMediaQuery("(max-width: 900px)");

    return (
        <Box component="footer" className={s.footer}>
            <Container maxWidth="lg">
                <Box className={clsx({ [s.container]: true, [s.container__mobile]: mobile })}>
                    <Typography
                        className={mobile ? s.hidden : undefined}
                        align="center"
                        color={colors["500"]}
                        variant="subtitle2">
                        Огородный проезд, 10
                    </Typography>

                    <Box display={mobile ? "none" : "flex"} gap="1.5rem" px="1rem">
                        <Link
                            underline="none"
                            type="phone"
                            variant="subtitle2"
                            href="tel:+79162241555"
                            color={colors["600"]}
                            noWrap>
                            +7(916)224-15-55
                        </Link>
                        <Link
                            type="email"
                            href="mailto:pinktab@list.ru"
                            variant="subtitle2"
                            underline="none"
                            color={colors["600"]}
                            noWrap>
                            pinktab@list.ru
                        </Link>
                        <Link
                            href="./info.pdf"
                            target="_blank"
                            variant="subtitle2"
                            underline="none"
                            color={colors["600"]}
                            noWrap>
                            Как оформить заказ
                        </Link>
                    </Box>

                    <Box display="flex" gap="1rem" px="4.5rem">
                        <Link href="https://www.instagram.com/pinktab.ru/" target="_blank">
                            <IconButton sx={{ width: "2rem", height: "2rem" }}>
                                <Instagram />
                            </IconButton>
                        </Link>
                    </Box>

                    <Box display={mobile ? "none" : "flex"} gap="1rem" ml={mobile ? "" : "auto"}>
                        <PayKeeper />
                        <Visa />
                        <MasterCard />
                        <Mir />
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};
