import { Box, Typography, Divider } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { PDInputType } from "../components/DataInput/DataInput";
import { PersonalDataType, SelectOptions } from "../types/type";

export const headerHeight = "5.75rem";
export const footerHeight = "5.75rem";
export const url = {
    main: "/",
    shop: "/shop",
    contacts: "/contacts",
    delivery: "/delivery",
    personalAccount: "/profile",
    emailEnter: "/restore-password",
    createOrder: "/create-order",
    createProduct: "/create-product",
    search: "/search",
    personalOrder: "/personal-order",
    restorePassword: "/pswd",
};

export enum OrderStatusOptions {
    "order_created" = "Заказ создан",
    "order_prepaid" = "Заказ был успешно оплачен",
    "order_canceled" = "Что-то пошло не так, заказ не был создан",
}

export enum UserEnum {
    "admin" = "ADMIN",
    "user" = "USER",
}

export const userData: PersonalDataType = {
    userData: {
        personalData: {
            blockName: (email: string) => (
                <>
                    <Box display="flex" justifyContent="space-between" flexWrap="wrap" gap="0.75rem">
                        <Typography variant="h3">Персональные данные</Typography>
                        <Typography variant="body2">{email}</Typography>
                    </Box>
                    <Divider flexItem />
                </>
            ),
            values: [
                { label: "Фамилия", position: 1, name: "lastName", value: "" },
                { label: "Имя", position: 1, name: "firstName", value: "" },
                { label: "Отчество", position: 1, name: "patronymic", value: "" },
                { label: "Контактный телефон", position: 1, name: "phone", value: "" },
            ],
        },
        address: {
            blockName: "Адрес",
            values: [
                { label: "Город, улица", position: 0, name: "street", value: "" },
                { label: "Дом, корпус, строение", position: 1, name: "building", value: "" },
                { label: "Номер квартиры", position: 2, name: "flat", value: "" },
            ],
        },
        passwordChange: {
            blockName: "Изменить пароль",
            values: [
                {
                    label: "Новый пароль",
                    position: 1,
                    name: "newPassword",
                    value: "",
                    type: "password",
                    autoComplete: "new-password",
                },
                {
                    label: "Повторить пароль",
                    position: 2,
                    name: "newPassword2",
                    value: "",
                    type: "password",
                    autoComplete: "new-password",
                },
            ],
        },
    },
};

export const tabs = {
    header: [
        { name: "Кроссовки", url: url.shop.slice(1) },
        { name: "Доставка и оплата", url: url.delivery.slice(1) },
        { name: "Персональный заказ", url: url.personalOrder.slice(1) },
        { name: "Контакты", url: url.contacts.slice(1) },
    ],
};

export const sortVariants: SelectOptions[] = [
    {
        value: "Рандомно",
        id: "random",
    },
    {
        value: "По цене",
        id: "price",
    },
    {
        value: "PRIORITY",
        id: "popular",
    },
];

export const shops = [
    {
        id: 0,
        address: "Огородный проезд, 10",
        street: "Огородный проезд",
        building: "10",
        weekTime: "11:00 - 20:00",
        weekendTime: "12:00 - 18:00",
        phone: "+7 (916) 224-15-55",
    },
];

export const deliveryWays = [
    {
        id: 0,
        way: "Курьером в пределах МКАД",
    },
    {
        id: 1,
        way: "Курьером СДЭК",
    },
    {
        id: 2,
        way: "СДЭК",
    },
    {
        id: 3,
        way: "Самовывоз",
    },
];

export const onSitePayment = "На сайте";

export const orderText = [
    <Typography variant="subtitle2">
        Доставка осуществляется в пределах МКАДа.
        <br /> Срок 1-3 дня, после оформления заказа.
        <br /> Возможна примерка (в течение 15 минут).
        <br /> Стандартная стоимость доставки - 500 рублей.
        <br /> Бесплатная доставка — при покупке от 25 000 рублей.
    </Typography>,
    <Typography variant="subtitle2">
        Доставка осуществляется в пределах Московской области.
        <br /> Срок доставки ориентировочно 3-4 рабочих дня с момента оформления заказа.
        <br /> Возможна примерка (в течение 15 минут).
        <br /> Стоимость доставки 750 рублей вне зависимости от суммы покупки.
    </Typography>,
    <Typography variant="subtitle2">
        Срок доставки ориентировочно 2-5 рабочих дней с момента отправления заказа (срок отправки заказа 1-2 дня, не
        считая дня поступления заказа).
        <br />
        Услуга примерки отсутствует.
        <br />
        Возможность отслеживания посылки по трек-номеру.
        <br />
        Стоимость доставки — 790 рублей.
    </Typography>,
];

export const inputValues: Array<PDInputType> = [
    {
        label: "Фамилия",
        position: 0,
        variant: "outlined",
        value: "",
        name: "lastName",
    },
    {
        label: "Имя",
        position: 0,
        variant: "outlined",
        value: "",
        name: "firstName",
    },
    {
        label: "Электронная почта",
        position: 0,
        variant: "outlined",
        value: "",
        name: "email",
    },
    {
        label: "Телефон",
        position: 0,
        variant: "outlined",
        value: "",
        name: "phone",
    },
    {
        label: "Название модели",
        position: 0,
        variant: "outlined",
        value: "",
        name: "modelName",
    },
];

export const deliveryText = (
    <Typography variant="body1" component="p" color="primary">
        Доставка осуществляется:
        <br />
        <br />
        Курьером в пределах МКАДа в течении 1-3 дней, стандартная стоимость 500 рублей.
        <br />
        <br />
        Курьером СДЭК в пределах Московской области в течении 3-4 рабочих дней, стоимость доставки 750 рублей.
        <br />
        <br />
        Службой СДЭК на территории России в течении 4-8 дней, стоимость доставки 790 рублей.
        <br />
        <br />
        Самовывозом из магазина.
        <br />
        <br />
        Подробнее вы можете узнать на странице <Link to={url.delivery}>Доставка и оплата</Link>.
    </Typography>
);

export const returnText = (
    <Typography variant="body1" component="p" color="primary">
        Возврат товара.
        <br />
        <br />
        • Клиент вправе отказаться от заказанного Товара в любое время до его получения, а после получения Товара - в
        течение 14 дней, не считая дня покупки.
        <br />
        <br />
        • Возврат Товара надлежащего качества возможен в случае, если сохранены его товарный вид, потребительские
        свойства, а также документ, подтверждающий факт и условия покупки указанного Товара.
        <br />
        <br />
        • Клиент не вправе отказаться от Товара, имеющего индивидуально-определенные свойства, если указанный Товар
        может быть использован исключительно приобретающим его Клиентом.
        <br />
        <br />• После возврата товара требуется 1-2 дня проверки пары на оригинальность, после чего денежные средства
        будут возвращены в соответствие с банковским регламентом.
    </Typography>
);
