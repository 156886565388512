import { Box, Button, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useState, useRef, useEffect } from "react";
import s from "./BannerImage.module.scss";

type Props = {
    title: string;
    img?: string;
    handleAdd: (blob: File, link: string) => void;
    error?: string;
    mobile?: boolean;
};

export const BannerImage: React.FC<Props> = React.memo(({ title, img, handleAdd, error, mobile }) => {
    const [image, setImage] = useState<File | string | undefined>(img);

    useEffect(() => {
        setImage(img);
    }, [img]);

    const input: React.RefObject<HTMLInputElement> | null | undefined = useRef(null);
    const onClick = () => {
        if (input) {
            input?.current?.click();
        }
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        if (files) {
            const reader = new FileReader();
            reader.onloadend = (e) => {
                if (e?.target?.result) {
                    setImage(e?.target?.result.toString());
                    handleAdd(files[0], e?.target?.result.toString());
                }
            };
            reader.readAsDataURL(files[0]);
        }
    };

    let content;
    if (image) {
        content = (
            <Box
                className={s.container}
                sx={{
                    background: `url(${image})`,
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                }}>
                <Button onClick={onClick} variant="outlined">
                    <Typography variant="subtitle2">Изменить</Typography>
                    <div className={s.buttonBG}>
                        <Typography variant="subtitle2">Изменить</Typography>
                    </div>
                </Button>
            </Box>
        );
    } else {
        content = (
            <Box className={clsx([s.container, s.container__color])}>
                <Typography variant="caption" align="center">
                    {mobile
                        ? "Загрузите изображение в формате png, jpg с соотношением сторон 20:9 и разрешением не меньше 72ppi"
                        : "Загрузите изображение в формате png, jpg с соотношением сторон 15:7 и разрешением не меньше 72ppi"}
                </Typography>
                <Button onClick={onClick} variant="outlined">
                    <Typography variant="subtitle2">Добавить изображение</Typography>
                </Button>
            </Box>
        );
    }

    return (
        <div>
            <Typography variant="subtitle2" mb="0.5rem">
                {title}
            </Typography>
            <input type="file" ref={input} accept="image/*" hidden onChange={handleChange} />
            {content}
            <Typography align="right" variant="caption" component="h6" color="error">
                {error}
            </Typography>
        </div>
    );
});
