import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Search } from "../Search/Search";
import s from "./MobileSearch.module.scss";
import { ReactComponent as Close } from "../../assets/Close.svg";
import { ProductCard } from "../cards/ProductCard/ProductCard";
import GoodsService from "../../services/GoodsService";
import { Goods } from "../../types/type";
import { Loader } from "../Loader/Loader";

type Props = {
    onClick: () => void;
};

export const MobileSearch: React.FC<Props> = ({ onClick }) => {
    const [search, setSearch] = useState("");
    const [goods, setGoods] = useState<Goods[] | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [changed, setChanged] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
        setChanged(true);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (changed) {
                setChanged(false);
                setLoading(true);
                const gs = new GoodsService();
                gs.getBootsSortedByName(search)
                    .then((res) => {
                        if (search === res.search) setGoods(res.res);
                        setLoading(false);
                        setError(false);
                    })
                    .catch(() => {
                        setLoading(false);
                        setError(true);
                    });
            }
        }, 500);
        return () => clearInterval(interval);
    }, [changed, search]);

    let content;
    if (error) {
        content = <Typography variant="h3">Что-то пошло не так</Typography>;
    } else if (loading) {
        content = <Loader size={80} />;
    } else if (goods === null) {
        content = <Typography variant="h3">Введите название или бренд интересующего вас товара</Typography>;
    } else if (!goods[0]) {
        content = <Typography variant="h3">По вашему запросу ничего не найдено</Typography>;
    } else {
        content = goods.map((itemValue) => {
            return <ProductCard {...itemValue} key={`pr_card_${itemValue.id}`} />;
        });
    }

    return (
        <div>
            <div className={s.searchModal}>
                <Search mobile value={search} onChange={handleChange} />
                <IconButton className={s.close} color="secondary" onClick={onClick}>
                    <Close />
                </IconButton>
            </div>

            <Box className={s.goods_container__mobile}>{content}</Box>
        </div>
    );
};
