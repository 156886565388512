import React from "react";
import { createTheme } from "@mui/material";
import { ReactComponent as Checked } from "./assets/toggle/checked.svg";
import { ReactComponent as Unchecked } from "./assets/toggle/unchecked.svg";
import { ReactComponent as Arrow } from "./assets/Arrow-drop-down.svg";
import { ReactComponent as RadioChecked } from "./assets/toggle/r_on.svg";
import { ReactComponent as RadioUnchecked } from "./assets/toggle/r_off.svg";

export const colors = {
    black: "#1F1F1F",
    800: "#4C4C4C",
    700: "#626262",
    600: "#797979",
    500: "#A5A5A5",
    400: "#BCBCBC",
    300: "#D2D2D2",
    200: "#E9E9E9",
    100: "#F4F4F4",
    white: "#FFFFFF",
    red: {
        200: "#FFF9F9",
        800: "#FF8686",
        900: "#F02727",
    },
};

// creating new theme
const theme = createTheme({
    breakpoints: { values: { xs: 0, sm: 600, md: 900, lg: 1280, xl: 1536 } },

    palette: {
        background: { default: colors.white },
        primary: { main: colors.black, contrastText: colors.white },
        secondary: { main: colors["300"], contrastText: colors["800"] },
        text: { primary: colors.black, secondary: colors["500"] },
        divider: colors["200"],
        tonalOffset: 0.4,
        error: { main: colors.red["900"], contrastText: colors.red["900"] },
    },
    typography: {
        h1: {
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "2.25rem",
            lineHeight: "2.625rem",
            color: colors.black,
        },
        h2: {
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "1.375rem",
            lineHeight: "1.625rem",
            color: colors.black,
        },
        h3: {
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "1.25rem",
            lineHeight: "1.4375rem",
            color: colors.black,
            textDecoration: "none",
        },
        body1: {
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "1rem",
            lineHeight: "1.1875rem",
            color: colors.black,
        },
        body2: {
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "1.125rem",
            lineHeight: "130%",
            color: colors.black,
        },
        subtitle1: {
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "0.875rem",
            lineHeight: "1rem",
            color: colors.black,
        },
        subtitle2: {
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "0.875rem",
            lineHeight: "1rem",
            color: colors.black,
        },
        button: {
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "0.875rem",
            lineHeight: "1rem",
            textTransform: "none",
        },
        caption: {
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "0.75rem",
            lineHeight: "0.875rem",
            color: colors.black,
        },
    },
});

theme.components = {
    MuiTabs: {
        styleOverrides: {
            indicator: {
                height: "0.125rem",
                width: "100%",
            },
            flexContainer: {
                gap: "1.5rem",
                "@media (max-width: 1050px)": {
                    gap: "0.5rem",
                },
            },
            flexContainerVertical: {
                "& ~ .MuiTabs-indicator": {
                    backgroundColor: "transparent",
                    borderBottom: "0.125rem solid black",
                },
            },
            vertical: {
                "& .MuiTabs-scroller": {
                    marginTop: "-2px",
                },
            },
        },
    },
    MuiTab: {
        styleOverrides: {
            root: {
                fontSize: "1rem",
                lineHeight: "19px",
                padding: "0.5rem",
            },
        },
    },
    MuiDrawer: {
        styleOverrides: {
            paper: {
                justifyContent: "center",
                alignItems: "flex-start",
                width: "65%",
                padding: "0 1.875rem",
                "@media (max-width: 600px)": {
                    width: "85%",
                },
            },
        },
    },
    MuiButton: {
        defaultProps: {
            disableElevation: true,
        },
        styleOverrides: {
            root: { borderRadius: 0, width: "fit-content", height: "fit-content" },
            disabled: { color: colors["200"] },
            startIcon: {
                height: "1.25rem",
                width: "1.25rem",
                margin: "-0.25rem",
                marginRight: "0.5rem",
                alignItems: "center",
            },
            endIcon: {
                height: "1.25rem",
                width: "1.25rem",
                margin: "-0.25rem",
                marginLeft: "0.5rem",
                alignItems: "center",
            },
            sizeLarge: { fontSize: "1rem", lineHeight: "19px" },

            contained: {
                background: colors.black,
                color: colors.white,
                "@media (min-width: 900px)": {
                    "&:hover": { background: "#4C4C4C" },
                },
            },
            containedSizeMedium: { padding: "0.625rem 1rem" },
            containedSizeLarge: { padding: "12.5px 1rem" },

            outlined: {
                border: `1px solid ${colors["400"]}`,
                "@media (min-width: 900px)": {
                    "&:hover": { color: colors["600"], border: `1px solid ${colors["600"]}` },
                },
            },
            outlinedSizeMedium: {
                padding: "calc(0.625rem - 1px) calc(1rem - 1px)",
                color: colors.black,
            },
            outlinedSizeLarge: { padding: "calc(12.5px - 1px) calc(1rem - 1px)" },

            sizeSmall: {
                padding: "0.625rem",
                width: "2rem",
                fontSize: "0.875rem",
                lineHeight: "0.75rem",
                borderRadius: "0",
                minWidth: "0",
                transition: "none",
            },
            containedSizeSmall: {
                background: colors.black,
                color: colors.white,
                "@media (min-width: 900px)": {
                    "&:hover": {
                        background: colors["200"],
                        color: colors.black,
                    },
                },
            },

            textSizeSmall: {
                fontSize: "0.875rem",
                lineHeight: "0.75rem",
                color: colors.black,
                "@media (min-width: 900px)": {
                    "&:hover": {
                        background: colors["200"],
                    },
                },
            },

            fullWidth: { width: "100%" },
        },
    },
    MuiAppBar: {
        defaultProps: { color: "default" },
        styleOverrides: {
            colorDefault: { backgroundColor: colors.white },
        },
    },
    MuiFormControlLabel: {
        defaultProps: {
            componentsProps: {
                typography: {
                    variant: "subtitle2",
                    color: colors.black,
                },
            },
        },
        styleOverrides: {
            root: {
                margin: "0",
                "@media (min-width: 900px)": {
                    "&:hover": {
                        color: colors["600"],
                        "& .MuiCheckbox-root": {
                            color: colors["400"],
                        },
                        "& .MuiRadio-root": {
                            color: colors["400"],
                        },
                        "& .MuiCheckbox-colorError:not(.Mui-checked)": {
                            color: colors.red["800"],
                        },
                        "& .MuiRadio-colorError:not(.Mui-checked)": {
                            color: colors.red["800"],
                        },
                    },
                },
            },
        },
    },
    MuiFormLabel: {
        styleOverrides: {
            root: {
                width: "100%",
                textAlign: "left",
                "@media (max-width: 900px)": {
                    // marginTop: "1.5rem",
                },
                color: colors["500"],
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "0.875rem",
                lineHeight: "1rem",
            },
        },
    },
    MuiSelect: {
        defaultProps: {
            IconComponent: Arrow,
            MenuProps: {
                PaperProps: {
                    square: true,
                    variant: "outlined",
                    elevation: 0,
                },
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
            },
        },
        styleOverrides: {
            outlined: {
                color: colors.black,
                minHeight: "0 !important",
                padding: "0.5rem 2rem 0.5rem 0.5rem",
                alignItems: "center",
            },
            icon: {
                top: "0.25rem",
                color: colors.black,
            },
        },
    },
    MuiTextField: {
        defaultProps: {
            variant: "filled",
            InputProps: {
                disableUnderline: true,
            },
        },
        styleOverrides: {
            root: {
                "& .MuiFilledInput-root": {
                    width: "100%",
                    border: "none",
                    borderRadius: "0",
                    textAlign: "left",
                    fontSize: "0.875rem",
                    lineHeight: "1rem",

                    padding: "1px 0.25rem 0.25rem 0.5rem",
                    "&.MuiInputBase-colorSecondary": {
                        color: colors.black,
                        background: colors["200"],
                    },
                    "&.MuiInputBase-colorPrimary": {
                        color: colors["500"],
                        background: colors.white,
                    },
                },
                "& .MuiFilledInput-input": {
                    padding: "0",
                    paddingTop: "0.125rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                    borderRadius: "0",
                },
                "& .MuiSelect-input": {
                    padding: "0",
                    paddingTop: "0.125rem",
                },
                "& .MuiOutlinedInput-input": {
                    padding: "0",
                    paddingTop: "0.125rem",
                },
                "& .MuiInputBase-input": {
                    padding: "0",
                    paddingTop: "0.375rem",
                },
                "& .MuiOutlinedInput-root": {
                    width: "100%",
                    borderRadius: "0",
                    textAlign: "left",
                    fontSize: "0.875rem",
                    lineHeight: "1rem",

                    padding: "1px 0.25rem 0.25rem 0.5rem",
                    "&.MuiInputBase-colorSecondary": {
                        color: colors.black,
                        background: colors["200"],
                        border: `1px solid ${colors["300"]}`,
                    },
                    "&.MuiInputBase-colorPrimary": {
                        color: colors.black,
                        background: colors.white,
                        border: `1px solid ${colors["300"]}`,
                    },
                },
                "& .MuiInputBase-colorSecondary.MuiSelect-root": {
                    background: colors["200"],
                    border: `1px solid ${colors["200"]}`,
                    padding: 0,
                    "@media (min-width: 900px)": {
                        "&:hover": {
                            transition: "all 0.4s ease",
                            background: colors.white,
                            border: `1px solid ${colors["300"]}`,
                        },
                    },
                    "&:focus-visible": {
                        background: colors.white,
                        border: `1px solid ${colors["300"]}`,
                    },
                    "& .MuiSelect-select": {
                        padding: "0.5rem 2rem 0.4rem 0.5rem",
                    },
                },
                "& .MuiInputBase-colorPrimary.MuiSelect-root": {
                    background: colors.white,
                    border: `1px solid ${colors["200"]}`,
                    padding: 0,
                    "@media (min-width: 900px)": {
                        "&:hover": {
                            transition: "all 0.4s ease",
                            background: colors.white,
                            border: `1px solid ${colors["300"]}`,
                        },
                    },

                    "&:focus-visible": {
                        background: colors.white,
                        border: `1px solid ${colors["300"]}`,
                    },
                    "& .MuiSelect-select": {
                        padding: "0.5rem 2rem 0.4rem 0.5rem",
                    },
                },
                "& .MuiFormHelperText-root": {
                    margin: "0.5rem 0 0 0",
                },

                "& .MuiInputBase-colorError": {
                    background: colors.red["200"],
                    border: ` 1px solid ${colors.red["800"]}`,
                    color: colors.red["900"],
                    "@media (min-width: 900px)": {
                        "&:hover": {
                            background: colors.red["200"],
                            border: ` 1px solid ${colors.red["800"]}`,
                        },
                    },
                },
                "& .MuiInputBase-colorError ~ .MuiFormHelperText-root": {
                    position: "absolute",
                    right: "0",
                    width: "100%",
                    bottom: "-1.12rem",
                    whiteSpace: "nowrap",
                    textAlign: "right",
                    color: colors.red["900"],
                    "*": {
                        color: colors.red["900"],
                    },
                },
                "& .MuiInputBase-colorError.MuiSelect-root": {
                    padding: "0",
                    "& .MuiSelect-select": {
                        padding: "0.5rem 2rem 0.4rem 0.5rem",
                    },
                },
            },
        },
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                color: colors["500"],
                background: colors.white,
            },
            colorSecondary: {
                color: colors.black,
                background: colors["200"],
            },
        },
    },
    MuiCheckbox: {
        defaultProps: { icon: <Unchecked />, checkedIcon: <Checked /> },
        styleOverrides: {
            root: {
                color: colors.white,
                marginRight: "0.5rem",
                padding: 0,
                "&.MuiCheckbox-colorError:not(.Mui-checked)": {
                    "& svg path:nth-of-type(2)": {
                        fill: colors.red["800"],
                    },
                    color: colors.red["200"],
                },
                "&.MuiCheckbox-colorError.Mui-checked": {
                    color: colors.black,
                },
            },
        },
    },
    MuiIconButton: {
        styleOverrides: {
            root: { padding: 0 },
            colorPrimary: { color: colors.black },
            colorSecondary: { color: colors["700"] },
            disabled: {
                color: colors["300"],
            },
            sizeSmall: {
                width: "1.125rem",
                height: "1.125rem",
            },
        },
    },
    MuiList: {
        styleOverrides: {
            root: {
                padding: "0",
            },
            padding: {
                padding: "0.25rem 0.5rem",
            },
        },
    },
    MuiListItem: {
        styleOverrides: {
            root: {
                gap: "1rem",
            },
        },
    },
    MuiListItemIcon: {
        styleOverrides: {
            root: {
                minWidth: "0",
            },
            alignItemsFlexStart: {
                marginTop: "3px",
            },
        },
    },
    MuiPaper: {
        defaultProps: {
            elevation: 0,
        },
        styleOverrides: {
            root: {
                borderRadius: 0,
            },
            outlined: {
                backgroundColor: colors["200"],
            },
        },
    },
    MuiMenu: {
        styleOverrides: {
            root: {
                "& *": {
                    whiteSpace: "break-spaces",
                },
            },
            list: {
                display: "flex",
                gap: "0.5rem",
                flexDirection: "column",
            },
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                "@media (max-width: 600px)": {
                    minHeight: "auto",
                },
            },
            selected: {
                background: colors["300"],
            },
        },
    },
    MuiCard: {
        styleOverrides: {
            root: {
                backgroundColor: colors.white,
                minWidth: "368px",
                "@media (max-width: 600px)": {
                    minWidth: "175px",
                },
                "@media (max-width: 400px)": {
                    minWidth: "134px",
                },
            },
        },
    },
    MuiCardContent: {
        styleOverrides: {
            root: {
                padding: "1.375rem 1rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "@media (max-width: 600px)": {
                    padding: "16.5px 0",
                },
            },
        },
    },
    MuiContainer: {
        styleOverrides: {
            root: {
                "@media (max-width: 600px)": {
                    paddingRight: "1.25rem",
                    paddingLeft: "1.25rem",
                },
                "@media (min-width: 600px)": {
                    paddingRight: "2rem",
                    paddingLeft: "2rem",
                },
                "@media (min-width: 1050px)": {
                    paddingRight: "4rem",
                    paddingLeft: "4rem",
                },
            },
        },
    },
    MuiDivider: {
        defaultProps: {
            light: true,
        },
        styleOverrides: {
            root: {
                borderColor: colors["600"],
            },
            middle: {
                marginTop: "0.125rem",
                marginBottom: "0.125rem",
            },
            light: {
                borderColor: colors["200"],
            },
        },
    },
    MuiBreadcrumbs: {
        styleOverrides: {
            root: {
                display: "flex",
                padding: "2rem 0",
                width: "100%",
                overflow: "hidden",
                position: "relative",
                "@media (max-width: 600px)": {
                    padding: "1rem 0",
                },
                "&:after": {
                    content: '""',
                    width: "0.625rem",
                    height: "100%",
                    top: "0",
                    right: "0",
                    display: "block",
                    position: "absolute",
                    boxShadow: "inset -0.625rem 0rem 0.375rem #FFFFFF",
                },
            },
            ol: {
                gap: "0.75rem",
                flexWrap: "nowrap",
            },
            li: {
                whiteSpace: "nowrap",
            },
            separator: {
                margin: "0.125rem 0",
                flex: "1",
                alignSelf: "stretch",
                height: "auto",
            },
        },
    },
    MuiRadio: {
        defaultProps: { icon: <RadioUnchecked />, checkedIcon: <RadioChecked /> },
        styleOverrides: {
            root: {
                color: colors.white,
                marginRight: "0.5rem",
                padding: 0,
            },
        },
    },
    MuiFormGroup: {
        styleOverrides: {
            root: {
                gap: "0.75rem",
            },
            row: {
                gap: "0.5rem",
            },
        },
    },
    MuiSnackbar: {
        styleOverrides: {
            root: {
                "@media(min-width: 0rem)": {
                    bottom: 0,
                    left: 0,
                    right: 0,
                    boxShadow: "0rem -0.5rem 0.9375rem rgba(0, 0, 0, 0.08)",
                },
            },
        },
    },
    MuiSnackbarContent: {
        styleOverrides: {
            root: {
                position: "relative",
                borderRadius: 0,
                background: colors.white,
                color: colors["300"],
                justifyContent: "center",
                alignItems: "flex-start",
                padding: "1rem",
                "@media(min-width: 0rem)": {
                    width: "100%",
                },
            },
            message: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.5rem",
                "& svg": {
                    height: "2rem",
                    width: "auto",
                },
            },
            action: {
                position: "absolute",
                top: "1rem",
                right: "1rem",
                padding: 0,
            },
        },
    },
    MuiTable: {
        styleOverrides: {
            root: {
                borderCollapse: "collapse",
            },
        },
    },
    MuiTableCell: {
        defaultProps: {
            align: "center",
        },
        styleOverrides: {
            root: {
                border: `1px solid ${colors["300"]}`,
                padding: "10px",
            },
            head: {
                background: colors["100"],
                fontWeight: 500,
                fontSize: "1.25rem",
                lineHeight: "1.4375rem",
            },
            body: {
                color: colors["600"],
                fontSize: "1rem",
                lineHeight: "1.1875rem",
            },
        },
    },
};

export { theme };
