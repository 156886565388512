import { Box, Button, useMediaQuery, Typography } from "@mui/material";
import clsx from "clsx";
import { FormikErrors, useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import UserService from "../../services/UserService";
import { PersonalDataType, UserData } from "../../types/type";
import { InputBlock } from "../InputBlock/InputBlock";
import { Loader } from "../Loader/Loader";
import { Snack } from "../snacks/Snack";
import s from "./PersonalData.module.scss";

type FormikDataType = {
    firstName: string;
    lastName: string;
    patronymic: string;
    phone: string;
    street: string;
    building: string;
    flat: string;
    newPassword: string;
    newPassword2: string;
};

export const PersonalData: React.FC<PersonalDataType> = ({ userData }) => {
    const tablet = useMediaQuery("(max-width: 900px)");
    const mobile = useMediaQuery("(max-width: 600px)");

    const [userInfo, setUserInfo] = useState<UserData>();
    const [pageError, setError] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [message, setMessage] = useState("");
    const [loadingUserData, setLoadingUserData] = useState(false);
    const data = [userData.personalData, userData.address, userData.passwordChange];

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            patronymic: "",
            phone: "",
            street: "",
            building: "",
            flat: "",
            newPassword: "",
            newPassword2: "",
        },
        validate: (values) => {
            const errors: FormikErrors<FormikDataType> = {};

            if (values.newPassword !== values.newPassword2) {
                errors.newPassword2 = "Пароли должны совпадать";
            }

            return errors;
        },
        validateOnChange: false,
        onSubmit: (values) => {
            const formValues: Partial<FormikDataType> = {
                firstName: values.firstName,
                lastName: values.lastName,
                patronymic: values.patronymic,
                phone: values.phone,
                street: values.street,
                building: values.building,
                flat: values.flat,
                newPassword: values.newPassword,
            };

            const us = new UserService();
            const token = window.localStorage.getItem("jwt") ?? "";
            us.putUserInfo(token, formValues)
                .then(() => {
                    formik.setFieldValue("newPassword", "", false);
                    formik.setFieldValue("newPassword2", "", false);
                    setMessage("Данные изменены");
                    setOpenSnack(true);
                })
                .catch(() => {
                    setMessage("Что-то пошло не так");
                    setOpenSnack(true);
                });
        },
    });

    const setFormikValues = useCallback(
        (res: Partial<UserData>) => {
            formik.setValues({ ...formik.initialValues, ...res });
        },
        [formik]
    );

    useEffect(() => {
        if (!userInfo && !loadingUserData && !pageError) {
            const es = new UserService();
            const token = window.localStorage.getItem("jwt");
            setLoadingUserData(true);
            es.getUserInfo(token ?? "")
                .then((res) => {
                    setUserInfo(res);
                    setFormikValues(res);
                    setError(false);
                })
                .catch((e: Error) => {
                    if (e.message === "403") {
                        const elem: HTMLButtonElement | null = document.querySelector("button#header_user_button");
                        if (elem) elem.click();
                    }
                    setError(true);
                })
                .finally(() => setLoadingUserData(false));
        }
    }, [userInfo, setFormikValues, formik.values, loadingUserData, pageError]);

    if (pageError) {
        return (
            <Box display="flex" width="100%" justifyContent="center" pt="4rem">
                <Typography variant="h3">Что-то пошло не так (</Typography>
            </Box>
        );
    }

    if (!userInfo) {
        return <Loader />;
    }

    return (
        <Box display="flex" flexDirection="column" gap="1.5rem">
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <div className={clsx({ [s.container]: true, [s.container__mobile]: tablet })}>
                    {data.map(({ blockName, values }) => {
                        let newname;
                        if (typeof blockName === "string") {
                            newname = blockName;
                        } else if (blockName) {
                            newname = () => blockName(userInfo?.email ?? "");
                        }
                        const newValues = values.map((val) => {
                            const newVal = val;
                            const name = val.name as keyof FormikDataType;
                            newVal.value = formik.values[name];
                            newVal.helperText = formik.errors[name];
                            newVal.color = formik.errors[name] ? "error" : "secondary";
                            newVal.onChange = formik.handleChange;
                            return newVal;
                        });
                        return <InputBlock key={values[0].label} blockName={newname} values={newValues} />;
                    })}
                    <Button variant="contained" size="large" type="submit" fullWidth={mobile}>
                        Сохранить изменения
                    </Button>
                </div>
            </form>
            <Snack
                open={openSnack}
                handleClose={() => setOpenSnack(false)}
                message={<Typography variant="body2">{message}</Typography>}
            />
        </Box>
    );
};
