import { Box, BoxProps, Button, Typography } from "@mui/material";
import { FormikErrors, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import UserService from "../../../services/UserService";
import { MessageForm } from "../../../types/type";
import { DataInput } from "../../DataInput/DataInput";
import { Snack } from "../../snacks/Snack";
import s from "./ContactForm.module.scss";

type Props = {
    wrap?: boolean;
} & BoxProps;

const validate = (values: MessageForm) => {
    const errors: FormikErrors<MessageForm> = {};

    if (!values.phone) {
        errors.phone = "Обязательное поле";
    } else if (!/^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/.test(values.phone)) {
        errors.phone = "Неверный номер телефона";
    }
    if (!values.message) {
        errors.message = "Обязательное поле";
    }

    return errors;
};

export const ContactForm: React.FC<Props> = ({ wrap, ...props }) => {
    const [validationWay, setWay] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const formik = useFormik({
        initialValues: {
            lastName: "",
            firstName: "",
            email: "",
            phone: "",
            message: "",
        },
        validate,
        validateOnChange: validationWay,
        validateOnBlur: validationWay,
        onSubmit: (values) => {
            const us = new UserService();
            us.sendMessage(values)
                .then(() => {
                    setOpen(true);
                    setMessage("Ваше сообщение отправлено");
                    formik.resetForm();
                })
                .catch(() => {
                    setOpen(true);
                    setMessage("Что-то пошло не так");
                });
        },
    });

    useEffect(() => {
        if (formik.submitCount >= 1) setWay(true);
    }, [formik.submitCount]);

    return (
        <form className={s.form} onSubmit={formik.handleSubmit}>
            <Box {...props} className={s.container}>
                <Typography variant="h3" align="center">
                    Связаться с нами
                </Typography>
                <Box display="flex" gap="1.25rem" flexWrap={wrap ? "wrap" : undefined}>
                    <DataInput
                        label="Фамилия"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        color={formik.errors.lastName ? "error" : undefined}
                        helperText={formik.errors.lastName}
                        name="lastName"
                        position={0}
                    />
                    <DataInput
                        label="Имя"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        color={formik.errors.firstName ? "error" : undefined}
                        helperText={formik.errors.firstName}
                        name="firstName"
                        position={0}
                    />
                </Box>
                <Box display="flex" gap="1.25rem" flexWrap={wrap ? "wrap" : undefined}>
                    <DataInput
                        label="Электронная почта"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        color={formik.errors.email ? "error" : undefined}
                        helperText={formik.errors.email}
                        name="email"
                        position={0}
                    />
                    <DataInput
                        label="Телефон"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        color={formik.errors.phone ? "error" : undefined}
                        helperText={formik.errors.phone}
                        name="phone"
                        position={0}
                    />
                </Box>
                <Box display="flex" gap="1.25rem">
                    <DataInput
                        label="Сообщение"
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        color={formik.errors.message ? "error" : undefined}
                        helperText={formik.errors.message}
                        name="message"
                        position={0}
                        multiline
                        placeholder="Текст вашего сообщения"
                    />
                </Box>
                <Button fullWidth type="submit" variant="contained">
                    Отправить
                </Button>
            </Box>
            <Snack
                message={<Typography variant="body1">{message}</Typography>}
                open={open}
                handleClose={() => setOpen(false)}
            />
        </form>
    );
};
