import { OrderCreationType, PersonalOrderType } from "../types/type";

export default class OrdersService {
    apiUserBase = `${process.env.REACT_APP_SITE_URL}/rest/users`;
    apiAdminBase = `${process.env.REACT_APP_SITE_URL}/rest/admins`;
    apiBase = `${process.env.REACT_APP_SITE_URL}/rest/orders`;

    getAdminOrders = async (token: string) => {
        const res = await fetch(encodeURI(`${this.apiBase}`), {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (!res.ok) {
            throw new Error(`Error ${res.status} ${res.statusText} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };

    getUserOrders = async (token: string) => {
        const res = await fetch(encodeURI(`${this.apiUserBase}/orders`), {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (!res.ok) {
            throw new Error(`Error ${res.status} ${res.statusText} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };

    getUserRefunds = async (token: string) => {
        const res = await fetch(encodeURI(`${this.apiUserBase}/refunds`), {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (!res.ok) {
            throw new Error(`Error ${res.status} ${res.statusText} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };

    getUserOrderInfo = async (id: number, token: string) => {
        const res = await fetch(encodeURI(`${this.apiBase}/${id}`), {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (!res.ok) {
            throw new Error(`Error ${res.status} ${res.statusText} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };

    getAdminOrderInfo = async (id: number, token: string) => {
        const res = await fetch(encodeURI(`${this.apiAdminBase}/orders?order_id=${id}`), {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (!res.ok) {
            throw new Error(`Error ${res.status} ${res.statusText} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };

    createPersonalOrder = async (formdata: PersonalOrderType) => {
        const res = await fetch(encodeURI(`${this.apiBase}/personal`), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formdata),
        });

        if (!res.ok) {
            throw new Error(`Error ${res.status} acquired while fetching ${res.url}`);
        }

        return res;
    };

    createOrder = async (formdata: OrderCreationType) => {
        const sendingData = JSON.stringify(formdata);
        const res = await fetch(encodeURI(this.apiBase), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: sendingData,
        });

        if (!res.ok) {
            throw new Error(`Error ${res.status} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result ?? true;
    };

    changeOrderStatus = async (id: number, status: string, token: string) => {
        const res = await fetch(encodeURI(`${this.apiBase}/${id}/status?newStatus=${status}`), {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (!res.ok) {
            throw new Error(`Error ${res.status} acquired while fetching ${res.url}`);
        }

        const result = await res.text();
        return result;
    };
}
