import { ReactText } from "react";
import { DataType } from "../components/InputBlock/InputBlock";

export type MainPageADCardProps = {
    img: string;
    value: string;
    urlKey: string;
};

export type EmailSendingForm = {
    email: string;
    url: string;
};

export type PasswordSetForm = {
    email: string;
    uuid: string;
    newPassword: string;
};

export type UserForm = {
    firstName: string;
    email: string;
    password: string;
};

export type ProductBuyingInfo = {
    productId: number;
    size: string;
    amount: number;
};

export type ImageProps = {
    imageId: number;
    imageUrl: string;
};

export type OrderCreationType = {
    firstName: string;
    lastName?: string;
    patronymic?: string;
    email: string;
    phone: string;
    street: string;
    building: string;
    flat?: string;
    zipCode?: string;
    comment?: string;
    paymentType: string;
    deliveryType: string;
    orderProductDtoList: ProductBuyingInfo[];
};

export type UserData = {
    firstName: string;
    lastName: string;
    patronymic: string;
    phone: string;
    email: string;
    street: string;
    building: string;
    flat: string;
    newPassword: string;
};

export enum Sex {
    "men" = "Мужской",
    "women" = "Женский",
    "child" = "Детский",
}

export enum ReverseSex {
    "Мужские" = "men",
    "Женские" = "women",
    "Детские" = "child",
}

export type CheckBoxFilterProps = {
    key: string;
    value: ReactText;
};

export type SizeTableProps = {
    ru: "string";
    eu: "string";
    us: "string";
    uk: "string";
    footLength: "string";
};

/**
 * Тип сущности Товар
 */
export type Goods = {
    id: number;
    brand: string;
    minPrice: number;
    modelName: string;
    mainImage: ImageProps;
    sex: Array<Sex>;
};

export type AdminGoods = {
    id: number;
    brand: string;
    name: string;
    img: string;
    sex: Array<Sex>;
    article: string;
    amount: number;
    priority: number;
};

export type SelectOptions = {
    id: ReactText;
    value: ReactText;
};

export type RecievedOrder = {
    building: string;
    dateTime: string; // yyyy-mm-ddThh:mm:ss.ms+Z
    deliveryType: string;
    firstName: string;
    flat: string;
    id: number;
    lastName?: string;
    patronymic?: string;
    paymentType: string;
    status: string;
    street: string;
    totalCost: number;
    uuid: string;
    zipCode?: string;
};

export type SizeType = {
    size: string;
    price: string;
    amount: string;
};

export interface SizeNewPriceType extends SizeType {
    cardPrice: string;
}

export interface ProductInfo extends Partial<Goods> {
    article: string;
    mainImage: ImageProps;
    images: Array<ImageProps>;
    colors: Array<string>;
    sizes: Array<SizeNewPriceType>;
    description: ReactText;
    material: string;
    lining: string;
    top: string;
    priority: number;
    manufacturerCountry: string;
}

export type SizeProps = {
    size: string;
    amount: number;
    price: number;
};

export interface SizeCardPriceInterface extends SizeProps {
    cardPrice: number;
}

export type OrderProductInfo = {
    id: number;
    brand: string;
    modelName: string;
    mainImage: ImageProps;
    article: string;
    amount: number;
    colors: Array<string>;
    price: number;
    size: SizeCardPriceInterface;
};

export type OrderInfo = {
    orderNumber: string;
    orderDate: string;
    orderTime: string;
    person: string;
    address: string;
    fullPrice: number;
    orderStatus: string;
    paymentStatus?: string;
    phone: string;
    mail: string;
    deliveryPrice: number;
    discountPrice: number;
    comment: string;
    goods: Array<OrderProductInfo>;
};

export type ShopBagProductType = {
    brand: string;
    modelName: string;
    colors: Array<string> | string;
    size: SizeCardPriceInterface;
    amount: number;
    mainImage: ImageProps;
    id: number;
    price?: number;
    color?: string;
};

export type PersonalDataType = {
    userData: {
        personalData: DataType;
        address: DataType;
        passwordChange: DataType;
    };
};

export type OrderProps = {
    orderNumber: string;
    orderDate: string;
    orderTime: string;
    person: string;
    address: string;
    orderType: string;
    price: number;
    userOrderStatus?: string;
    paymentStatus?: string;
    adminOrderStatus?: string;
};

export enum Months {
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
}

type ProductType = {
    amount: number;
    article: string;
    brand: string;
    colors: string[];
    id: number;
    mainImage: ImageProps;
    modelName: string;
    price: number;
    size: string;
};

export type FullOrderInfoBack = {
    building: string;
    comment?: string;
    dateTime: string;
    email: string;
    firstName: string;
    flat: string;
    lastName?: string;
    patronymic?: string;
    phone: string;
    products: ProductType[];
    status: string;
    street: string;
    totalCost: number;
    uuid: string;
    zipCode?: string;
    paymentType?: string;
    deliveryPrice: number;
};

export type PersonalOrderType = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    modelName: string;
    sizes: string[];
    comment: string;
};

export type Product = {
    id: number;
    brand: string;
    modelName: string;
    mainImage: ImageProps;
    article: string;
    values?: Array<{ key?: string; value: string | Array<string> }>;
    price?: string;
};

export interface AdminProduct extends Product {
    amount: number;
    sex: Array<string>;
    priority: number;
}

export type MessageForm = {
    lastName: string;
    firstName: string;
    email: string;
    phone: string;
    message: string;
};

export type BannerType = {
    id: number;
    link: string;
    title: string;
    priority: number;
    enabled: boolean;
    desktopImage: string;
    mobileImage: string;
};

export interface BannerAdminServerType {
    id: number;
    link: string;
    title: string;
    priority: number;
    enabled: boolean;
    desktopImage: ImageProps;
    mobileImage: ImageProps;
}

export type BannerUserType = {
    id: number;
    link: string;
    title: string;
    desktopImage: ImageProps;
    mobileImage: ImageProps;
};
