import { Box, Button, Divider, Typography, useMediaQuery } from "@mui/material";
import clsx from "clsx";
import { FormikErrors, useFormik } from "formik";
import React, { useState } from "react";
import { DataInput } from "../../components/DataInput/DataInput";
import { url } from "../../constants/constants";
import UserService from "../../services/UserService";
import s from "./ResetPassword.module.scss";

type Props = unknown;

interface FormValues {
    email: string;
}

const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.email) {
        errors.email = "Обязательное поле";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = "Неверный адрес почты";
    }

    return errors;
};

export const EmailEnter: React.FC<Props> = () => {
    const mobile = useMediaQuery("(max-width: 600px)");
    const [send, setSend] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validate,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values) => {
            const formValues = {
                email: values.email,
                url: `${process.env.REACT_APP_SITE_URL}${url.restorePassword}`,
            };

            const us = new UserService();
            us.sendEmail(formValues)
                .then(() => {
                    setSend(true);
                })
                .catch((error) => console.log(error));
        },
    });

    const authorizeOpen = () => {
        const elem: HTMLButtonElement | null = document.querySelector("button#header_user_button");
        if (elem) elem.click();
    };

    if (send) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                maxWidth="500px"
                margin="3rem auto"
                gap="1.5rem">
                <Typography variant="h1" align="center">
                    На ваш e-mail отправлено письмо для восстановления пароля
                </Typography>
                <div className={s.emailBlock}>
                    <Typography align="center" variant="body2">
                        {formik.values.email}
                    </Typography>
                </div>
                <Typography align="center" variant="body1">
                    Проверьте свою почту по указанному e-mail
                </Typography>
                <Box display="flex" flexDirection={mobile ? "column" : "row"} gap="1.5rem">
                    <form onSubmit={formik.handleSubmit}>
                        <Button variant="contained" fullWidth={mobile} type="submit">
                            Отправить еще раз
                        </Button>
                    </form>
                    <Button variant="outlined" fullWidth={mobile} onClick={authorizeOpen}>
                        Авторизироваться
                    </Button>
                </Box>
            </Box>
        );
    }

    return (
        <form className={clsx({ [s.container]: true, [s.container__mobile]: mobile })} onSubmit={formik.handleSubmit}>
            <Typography variant="h2">Восстановить пароль</Typography>
            <Divider />
            <DataInput
                label="Email"
                position={0}
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                helperText={formik.errors.email}
                color={formik.errors.email ? "error" : "primary"}
            />
            <Button type="submit" variant="contained" fullWidth>
                Отправить
            </Button>
        </form>
    );
};
