import React from "react";
import clsx from "clsx";
import s from "./CheckButton.module.scss";
import { ReactComponent as Check } from "../../assets/Check.svg";

type Props = {
    value: React.ReactNode;
    checked?: boolean;
    onClick?: () => void;
    big?: boolean;
    fullWidth?: boolean;
    size?: boolean;
};

export const CheckButton: React.FC<Props> = ({ checked, value, onClick, big, fullWidth, size }) => {
    return (
        <div
            className={clsx({
                [s.checkBox]: true,
                [s.checkBox__checked]: checked,
                [s.checkBox__full]: fullWidth,
                [s.checkBox__sizes]: size,
            })}
            onClick={onClick}
            onKeyDown={onClick}
            role="button"
            tabIndex={0}>
            {value}
            <div className={clsx({ [s.check]: true, [s.check__big]: big })}>
                <Check />
            </div>
        </div>
    );
};
