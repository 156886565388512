import { Typography, Box, Divider, Button, useMediaQuery } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import { colors as themeColors } from "../../../theme";
import { LineList } from "../../LineList/LineList";
import { ProductWrapper } from "../../ProductWrapper/ProductWrapper";
import s from "./FullOrder.module.scss";
import { SelectOption } from "../../SelectOption/SelectOption";
import { OrderProduct } from "../../cards/OrderProduct/OrderProduct";
import { ReactComponent as Back } from "../../../assets/Arrow-Left.svg";
import { OrderInfo, SelectOptions } from "../../../types/type";
import EnumsService from "../../../services/EnumsServices";
import TransformTypes from "../../../utils/typeTransformers/TransformTypes";
import { Loader } from "../../Loader/Loader";
import OrdersService from "../../../services/OrdersService";
import { Snack } from "../../snacks/Snack";
import { url, UserEnum } from "../../../constants/constants";

type Props = {
    handleClose: () => void;
    userType: string;
    change: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
};

export const FullOrder: React.FC<Props> = ({ handleClose, userType, change }) => {
    const location = useLocation();
    const mobile = useMediaQuery("(max-width: 800px)");
    const smallPC = useMediaQuery("(max-width: 1200px)");
    const [selectOptions, setSelectOptions] = useState<Array<SelectOptions> | null>(null);
    const orderNumberUrl = +location.search.split("?order=")[1];
    const [order, setOrder] = useState<OrderInfo>();
    const [select, setSelect] = useState("");
    const [open, setOpen] = useState(false);
    const [loadingSelectOptions, setLoadingSelectOptions] = useState(false);
    const [errorSelectOptions, setErrorSelectOptions] = useState(false);
    const [loadingOrder, setLoadingOrder] = useState(false);
    const [errorOrder, setErrorOrder] = useState(false);

    useEffect(() => {
        if (!selectOptions && !loadingSelectOptions && !errorSelectOptions) {
            if (userType === UserEnum.admin) {
                const es = new EnumsService();
                const tt = new TransformTypes();
                setLoadingSelectOptions(true);
                es.getOrderStatus()
                    .then((res) => tt.valueToSelect(res))
                    .then((res) => {
                        setSelectOptions(res);
                        setErrorSelectOptions(false);
                    })
                    .catch(() => setErrorSelectOptions(true))
                    .finally(() => setLoadingSelectOptions(false));
            }
        }
    }, [selectOptions, userType, loadingSelectOptions, errorSelectOptions]);

    useEffect(() => {
        if (!order && orderNumberUrl && !loadingOrder && !errorOrder) {
            const os = new OrdersService();
            const token = window.localStorage.getItem("jwt") ?? "";
            const tt = new TransformTypes();
            setLoadingOrder(true);
            if (userType === UserEnum.admin) {
                os.getAdminOrderInfo(orderNumberUrl, token)
                    .then((res) => tt.orderToOrder(res, orderNumberUrl))
                    .then((res) => {
                        setOrder(res);
                        setSelect(res.orderStatus);
                        setErrorOrder(false);
                    })
                    .catch(() => setErrorOrder(true))
                    .finally(() => setLoadingOrder(false));
            } else {
                os.getUserOrderInfo(orderNumberUrl, token)
                    .then((res) => tt.orderToOrder(res, orderNumberUrl))
                    .then((res) => {
                        setOrder(res);
                        setErrorOrder(false);
                    })
                    .catch(() => setErrorOrder(true))
                    .finally(() => setLoadingOrder(false));
            }
        }
    }, [order, orderNumberUrl, loadingOrder, userType, errorOrder]);

    if (!orderNumberUrl) {
        handleClose();
        return null;
    }

    if (errorOrder) {
        return <Typography variant="h2">Что-то пошло не так</Typography>;
    }

    if (!order || loadingSelectOptions) {
        return <Loader />;
    }

    const {
        orderDate,
        orderTime,
        person,
        address,
        fullPrice,
        paymentStatus,
        orderStatus,
        phone,
        mail,
        deliveryPrice,
        discountPrice,
        comment,
        goods,
    } = order;

    const handleSort = (e: ChangeEvent<HTMLInputElement>) => setSelect(e.target.value);

    const allGoods = goods.map((item) => {
        const { size, colors, amount } = item;
        const values = [
            {
                key: "Размер",
                value: `${size.size}`,
            },
            {
                key: "Цвет",
                value: colors.join(", "),
            },
            {
                key: "Кол-во",
                value: `${amount} шт.`,
            },
        ];

        return { ...item, values, price: `${item.price} ₽` };
    });

    const summ = (fullPrice + deliveryPrice) * (1 - discountPrice);

    const values = [
        {
            key: "Доставка",
            value: `${deliveryPrice} ₽`,
        },
        {
            key: (
                <Typography variant="body2" color="primary">
                    Итого
                </Typography>
            ),
            value: (
                <Typography variant="body2" color="primary">
                    {summ} ₽
                </Typography>
            ),
        },
    ];

    const prePayed = paymentStatus ? (
        <Typography variant="subtitle2" color="primary" className={smallPC ? s.text : undefined}>
            {paymentStatus}
        </Typography>
    ) : null;

    const changeOrderStatus = () => {
        const os = new OrdersService();
        const token = window.localStorage.getItem("jwt") ?? "";
        os.changeOrderStatus(orderNumberUrl, select, token)
            .then(() => {
                change[1](true);
                setOpen(true);
            })
            .catch((err) => console.log(err));
    };

    let content;
    if (userType === UserEnum.admin) {
        content = (
            <>
                <Divider flexItem />
                <LineList values={values} />
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    mb="0.5rem"
                    flexWrap={smallPC ? "wrap" : undefined}
                    gap={smallPC ? "1.25rem" : "2rem"}>
                    {prePayed}
                    <Box className={clsx({ [s.select]: true, [s.select__mobile]: smallPC })}>
                        {errorSelectOptions || !selectOptions ? (
                            <Typography variant="h3">Что-то пошло не так</Typography>
                        ) : (
                            <SelectOption
                                value={select}
                                onChange={handleSort}
                                options={selectOptions}
                                color="primary"
                                sx={{ flexGrow: 1 }}
                            />
                        )}
                        <Button variant="contained" size="medium" onClick={changeOrderStatus}>
                            Применить
                        </Button>
                    </Box>
                </Box>
            </>
        );
    } else {
        content = (
            <>
                <LineList values={values} />
                <div className={s.order}>
                    <Typography variant="caption" color="primary">
                        {orderStatus}
                    </Typography>
                </div>
            </>
        );
    }

    let goodsContent = (
        <>
            <ProductWrapper url={url.shop} allGoods={allGoods} />
            <Divider flexItem />
        </>
    );

    if (mobile) {
        goodsContent = (
            <>
                {goods.map(({ colors, ...other }) => (
                    <Box display="flex" flexDirection="column" gap="1.25rem" key={other.id}>
                        <OrderProduct
                            {...other}
                            color={typeof colors === "string" ? colors : colors.join(", ")}
                            changing={false}
                            url={url.shop}
                        />
                        <Divider flexItem />
                    </Box>
                ))}
            </>
        );
    }

    return (
        <Box width="100%" height="100%" pt="2rem" display="flex" flexDirection="column">
            <Box
                display="flex"
                flexDirection={mobile ? "column" : undefined}
                gap={mobile ? "0.75rem" : "5rem"}
                mb={mobile ? "1.25rem" : "2rem"}>
                <Typography variant="h3" color="primary">
                    № {orderNumberUrl}
                </Typography>
                <Typography variant="subtitle2" color={themeColors["400"]}>
                    {`${orderDate} / ${orderTime}`}
                </Typography>
            </Box>

            <Box display="flex" flexDirection="column" gap="1rem" height="100%">
                <div className={clsx({ [s.container]: true, [s.container__mobile]: mobile })}>
                    <div>
                        <Typography variant="body2" color="primary">
                            {person}
                        </Typography>
                        <Typography variant="subtitle2" color={themeColors["400"]}>
                            {address}
                        </Typography>
                    </div>
                    <Typography variant="subtitle2">{phone}</Typography>
                    <Typography variant="subtitle2">{mail}</Typography>
                </div>
                <Divider flexItem />
                <div className={s.goodsBox}>{goodsContent}</div>
                <Box display="flex" flexDirection="column" gap="0.25rem">
                    <Typography variant="caption" color={themeColors["500"]}>
                        Комментарий
                    </Typography>
                    <Typography variant="subtitle2">{comment}</Typography>
                </Box>
                {content}
                {mobile ? (
                    <Button fullWidth onClick={handleClose} variant="outlined" size="large" startIcon={<Back />}>
                        Назад
                    </Button>
                ) : null}
                <Snack
                    open={open}
                    handleClose={() => setOpen(false)}
                    message={<Typography variant="body2">Статус заказа изменен</Typography>}
                />
            </Box>
        </Box>
    );
};
