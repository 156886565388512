import { Typography, Box, IconButton, useMediaQuery, Link } from "@mui/material";
import React from "react";
import clsx from "clsx";
import s from "./Contacts.module.scss";
import { ReactComponent as Location } from "../../assets/Location.svg";
import { ReactComponent as Clock } from "../../assets/Clock.svg";
import { ReactComponent as Email } from "../../assets/Email.svg";
import { ReactComponent as Phone } from "../../assets/Phone.svg";
import { ReactComponent as Instagram } from "../../assets/contactinst.svg";
import { colors } from "../../theme";
import { ContactForm } from "../../components/forms/Contact/ContactForm";

type Props = unknown;

export const Contacts: React.FC<Props> = () => {
    const smallPc = useMediaQuery("(max-width: 1258px)");
    const tablet = useMediaQuery("(max-width: 1100px)");
    const mobile = useMediaQuery("(max-width: 700px)");
    return (
        <Box width="100%" mb={mobile ? "2rem" : "72px"}>
            <Box
                display="flex"
                flexWrap="wrap"
                width="100%"
                gap="57px"
                rowGap={mobile ? "2.5rem" : "105px"}
                mt={mobile ? "1.25rem" : "50px"}
                alignItems={mobile ? "center" : "flex-start"}>
                <div
                    className={clsx({
                        [s.shopPicture]: true,
                        [s.shopPicture__small]: smallPc,
                        [s.shopPicture__mobile]: mobile,
                    })}
                />
                <Box maxWidth={clsx({ "553px": !smallPc, "40%": smallPc && !tablet, "100%": tablet })}>
                    <Typography maxWidth={tablet ? "100%" : "466px"} variant="h1">
                        Магазин модных кроссовок PinkTab
                    </Typography>
                    <Typography variant="body1" mt="1.5rem">
                        Интернет-магазин модных кроссовок PinkTab предлагает продукцию любимых европейских и
                        американских брендов Nike, Jordan, Adidas, Off-White, Supreme и т.д. У нас вы найдете большой
                        ассортимент редких моделей лимитированных серий, выпущенных ограниченным тиражом. Приобрести
                        можно как новинки, так и бестселлеры, релиз которых уже прошел. Мы следим за современными
                        трендами, релизами и новыми коллаборациями известных брендов. Благодаря этому, наши покупатели
                        одними из первых становятся обладателями эксклюзивных новинок.
                        <br />
                        <br />
                        Кроссовки для повседневной жизни и спорта – это наша специализация и основной ассортимент.
                        Помимо обуви, в нашем магазине также представлены одежда и аксессуары street lifestyle.
                        <br />
                        <br />
                        Уже более 2 лет мы занимаемся продажей исключительно оригинальной обуви. При покупке доступны и
                        приветствуются любые проверки, также можно ознакомиться с ежедневными положительными отзывами
                        наших клиентов в группе ВКонтакте и Инстаграм.
                        <br />
                        <br />
                        Мы ждем вас в оффлайн магазине, где вы сможете увидеть весь ассортимент и примерить
                        понравившиеся модели. Если вы не нашли свой размер или желаемую модель на сайте – заполните
                        форму персонального заказа и мы с радостью поможем вам приобрести любые, даже редкие пары в
                        рамках нашей компетенции и концепции street lifestyle.
                    </Typography>
                </Box>
                {mobile ? null : <div className={clsx({ [s.mapPicture]: true, [s.mapPicture__mobile]: tablet })} />}
                <Box
                    maxWidth={clsx({ "100%": tablet || mobile, "490px": !smallPc, "42%": smallPc && !tablet })}
                    width={clsx({ undefined: !smallPc, "100%": mobile, "38%": smallPc || tablet })}
                    display="flex"
                    flexDirection="column"
                    justifyContent={tablet ? "center" : undefined}
                    gap={tablet ? "1.5rem" : "2rem"}>
                    <Typography variant="h2">
                        Наш магазин находится <br /> в торговом центре «Зелёный»
                    </Typography>
                    <Box display="flex" flexDirection="column" gap="1.5rem" alignItems="flex-start">
                        <Box display="flex" gap="0.5rem">
                            <div className={s.icon}>
                                <Location />
                            </div>
                            <Typography variant="body1">
                                Огородный проезд, д.10
                                <br />
                                Ближайшие станции метро — «Бутырская».
                            </Typography>
                        </Box>
                        <Box display="flex" gap="0.5rem" alignItems="flex-start">
                            <div className={s.icon}>
                                <Clock />
                            </div>
                            <div>
                                <Typography variant="body1" component="span" color={colors["700"]}>
                                    Пн. - Пт.
                                </Typography>
                                <Typography variant="body1" component="span">
                                    11:00 - 20:00
                                </Typography>
                                <br />
                                <Typography variant="body1" component="span" color={colors["700"]}>
                                    Сб. - Вс.
                                </Typography>
                                <Typography variant="body1" component="span">
                                    12:00 - 18:00
                                </Typography>
                            </div>
                        </Box>
                        <Box display="flex" gap="0.5rem" alignItems="flex-start">
                            <div className={s.icon}>
                                <Email />
                            </div>
                            <Typography variant="body1">pinktab@list.ru</Typography>
                        </Box>
                        <Box display="flex" gap="0.5rem" alignItems="flex-start">
                            <div className={s.icon}>
                                <Phone />
                            </div>
                            <Typography variant="body1">+7(916) 224-15-55</Typography>
                        </Box>
                        <Box display="flex" gap="0.5rem">
                            <Link href="https://www.instagram.com/pinktab.ru/" target="_blank">
                                <IconButton>
                                    <Instagram />
                                </IconButton>
                            </Link>
                        </Box>
                        <Typography variant="body1" color={colors.black}>
                            ООО &quot;Пинктаб&quot;
                        </Typography>
                        <Box display="flex" flexDirection="column" gap="1rem">
                            <Typography variant="body1" color={colors["400"]}>
                                ОГРН{" "}
                                <Typography component="span" color={colors.black}>
                                    1217700330572
                                </Typography>
                            </Typography>
                            <Typography variant="body1" color={colors["400"]}>
                                ИНН{" "}
                                <Typography component="span" color={colors.black}>
                                    9715403621
                                </Typography>
                            </Typography>
                        </Box>
                    </Box>
                    {mobile ? <div className={clsx({ [s.mapPicture]: true, [s.mapPicture__mobile]: mobile })} /> : null}
                    {!smallPc || mobile ? <ContactForm wrap={mobile} /> : null}
                </Box>
                {smallPc && !mobile ? <ContactForm wrap={tablet} /> : null}
            </Box>
            <Box display="flex" flexDirection="column" mt={tablet ? "2.5rem" : "105px"}>
                <Typography variant="h1" component="h2" ml={mobile ? undefined : "100px"} width="fit-content">
                    Как нас найти
                </Typography>
                <div>
                    <Box className={clsx({ [s.cardsBox]: true, [s.cardsBox__mobile]: mobile })}>
                        {[1, 2, 3, 4, 5].map((val) => (
                            <Box
                                key={val}
                                className={clsx({
                                    [s.wayPicture]: true,
                                    [s.wayPicture__mobile]: mobile,
                                    [s[`wayPicture_${val}`]]: true,
                                })}
                            />
                        ))}
                    </Box>
                </div>
            </Box>
        </Box>
    );
};
