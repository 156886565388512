import { EmailSendingForm, MessageForm, PasswordSetForm, UserData, UserForm } from "../types/type";

type AuthForm = {
    username: string;
    password: string;
};

export default class UserService {
    apiBase = `${process.env.REACT_APP_SITE_URL}/rest`;

    postUser = async (form: UserForm) => {
        const res = await fetch(encodeURI(`${this.apiBase}/users`), {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(form),
        });

        if (!res.ok) {
            throw new Error(`${res.status}`);
        }

        const result = await res.json();
        return result;
    };

    authenticateUser = async (form: AuthForm) => {
        const res = await fetch(encodeURI(`${this.apiBase}/authenticate`), {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(form),
        });

        if (!res.ok) {
            throw new Error(`Error ${res.status} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };

    getUserInfo = async (token: string) => {
        const res = await fetch(encodeURI(`${this.apiBase}/users`), {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (!res.ok) {
            throw new Error(`${res.status}`);
        }

        const result = await res.json();
        return result;
    };

    putUserInfo = async (token: string, form: Partial<UserData>) => {
        const res = await fetch(encodeURI(`${this.apiBase}/users`), {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(form),
        });

        if (!res.ok) {
            throw new Error(`Error ${res.status} acquired while fetching ${res.url}`);
        }

        return true;
    };

    sendEmail = async (form: EmailSendingForm) => {
        const res = await fetch(encodeURI(`${this.apiBase}/password/recovery-email`), {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(form),
        });

        if (!res.ok) {
            throw new Error(`Error ${res.status} acquired while fetching ${res.url}`);
        }

        return true;
    };

    setNewPassword = async (form: PasswordSetForm) => {
        const res = await fetch(encodeURI(`${this.apiBase}/password/changing`), {
            method: "PUT",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(form),
        });

        if (!res.ok) {
            throw new Error(`Error ${res.status} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };

    sendMessage = async (form: MessageForm) => {
        const res = await fetch(encodeURI(`${this.apiBase}/users/message`), {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(form),
        });

        if (!res.ok) {
            throw new Error(`Error ${res.status} acquired while fetching ${res.url}`);
        }

        return true;
    };
}
