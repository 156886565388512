import { CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { theme } from "./theme";
import { PageWrapper } from "./components/PageWrapper/PageWrapper";

export const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <CssBaseline />
                <PageWrapper />
            </Router>
        </ThemeProvider>
    );
};
