import { FormGroup, FormLabel, TextField, TextFieldProps, useMediaQuery } from "@mui/material";
import clsx from "clsx";
import React from "react";
import s from "./DataInput.module.scss";

export type PDInputType = {
    label: string;
    position: number;
    multiline?: boolean;
    value?: string;
} & Partial<TextFieldProps>;

export const DataInput: React.FC<PDInputType> = React.memo(({ label, position, ...props }) => {
    const mobile = useMediaQuery("(max-width: 600px)");
    return (
        <FormGroup
            className={clsx({
                [s.formGroup]: true,
                [s.formGroup__first]: position === 0,
                [s.formGroup__second]: position === 1,
                [s.formGroup__third]: position === 2,
                [s.formGroup__min]: position === 3,
                [s.formGroup__mobile]: mobile,
            })}>
            <FormLabel>{label}</FormLabel>
            <TextField
                fullWidth
                {...props}
                color={props.color === undefined ? "secondary" : props.color}
                minRows={props.multiline ? 4 : undefined}
                variant={props.variant === undefined ? "filled" : props.variant}
            />
        </FormGroup>
    );
});
