import { Dialog, IconButton } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { ContentTabs } from "../tabs/ContentTabs/ContentTabs";
import s from "../Header/Header.module.scss";
import { ReactComponent as Close } from "../../assets/Close.svg";
import { EnterForm } from "../forms/EnterForm/EnterForm";
import { RegisterForm } from "../forms/RegisterForm/RegisterForm";

type Props = {
    open: boolean;
    mobile: boolean;
    handleClose: () => void;
    onClick: () => void;
};

const tabs = [
    {
        id: 0,
        value: "Войти",
        Content: ({ ...props }) => <EnterForm onClick={props.onClick} {...props} />,
    },
    {
        id: 1,
        value: "Создать аккаунт",
        Content: ({ ...props }) => <RegisterForm onClick={props.onClick} {...props} />,
    },
];

export const LoginDialog: React.FC<Props> = ({ open, mobile, handleClose, onClick }) => {
    return (
        <Dialog
            open={open}
            scroll="body"
            fullScreen={mobile}
            PaperProps={{
                sx: { verticalAlign: "top" },
            }}
            onClose={handleClose}>
            <div className={clsx({ [s.enterDialog]: true, [s.enterDialog__mobile]: mobile })}>
                <div className={s.close__dialog}>
                    <IconButton color="secondary" onClick={handleClose}>
                        <Close />
                    </IconButton>
                </div>

                <ContentTabs tabs={tabs} onClick={onClick} />
            </div>
        </Dialog>
    );
};
