import { Box, IconButton, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import clsx from "clsx";
import { colors } from "../../theme";
import s from "./Slider.module.scss";
import { ReactComponent as Left } from "../../assets/Chevron-Left.svg";
import { ReactComponent as Right } from "../../assets/Chevron-right.svg";
import { ImageProps } from "../../types/type";

type Props = {
    imgs: Array<ImageProps>;
    id: number;
};

export const Slider: React.FC<Props> = ({ imgs, id }) => {
    const [mainPictureID, setMainPictureID] = useState(id);
    const mainPicture = imgs[mainPictureID];
    const tablet = useMediaQuery("(max-width: 800px)");
    const mobile = useMediaQuery("(max-width: 600px)");
    const ref = React.createRef<HTMLDivElement>();

    const setNext = () => {
        const newId = mainPictureID + 1 < imgs.length ? mainPictureID + 1 : 0;
        setMainPictureID(newId);
        if (tablet) {
            const element = ref.current;
            if (element) {
                if (element.scrollWidth > element.offsetWidth) {
                    const slide = mobile
                        ? Math.round(element.offsetWidth / 80 / 2)
                        : Math.round(element.offsetWidth / 100 / 2);
                    if (element.scrollLeft >= element.scrollWidth - element.offsetWidth - 40 && newId === 0) {
                        element.scrollLeft = 0;
                    } else if (newId >= slide) {
                        element.scrollLeft += mobile ? 100 : 120;
                    }
                }
            }
        }
    };

    const setPrev = () => {
        const newId = mainPictureID - 1 >= 0 ? mainPictureID - 1 : imgs.length - 1;
        setMainPictureID(newId);
        if (tablet) {
            const element = ref.current;
            if (element) {
                if (element.scrollWidth > element.offsetWidth) {
                    const slide = mobile
                        ? Math.round(element.offsetWidth / 80 / 2)
                        : Math.round(element.offsetWidth / 100 / 2);
                    if (element.scrollLeft <= 0 && newId === imgs.length - 1) {
                        element.scrollLeft = element.scrollWidth - element.offsetWidth;
                    } else if (newId < imgs.length - slide) {
                        element.scrollLeft -= mobile ? 100 : 120;
                    }
                }
            }
        }
    };

    const setId = (newId: number) => {
        setMainPictureID(newId);
        if (tablet) {
            const element = ref.current;
            if (element) {
                if (element.scrollWidth > element.offsetWidth) {
                    const slide = mobile ? 100 : 120;
                    element.scrollLeft = 0;
                    for (let i = 0; i < imgs.length; i++) {
                        if (i === newId) {
                            element.scrollLeft -= i !== imgs.length - 1 ? slide : 0;
                            break;
                        }
                        element.scrollLeft += i !== imgs.length - 1 ? slide : slide - 20;
                    }
                }
            }
        }
    };

    return (
        <div>
            <Box
                className={s.product_image}
                sx={{ background: `url(${mainPicture.imageUrl}) center/cover no-repeat, ${colors.white}` }}>
                <div className={clsx({ [s.position]: true, [s.position__left]: true })}>
                    <IconButton onClick={setPrev} color="primary" className={clsx({ [s.icon]: true })}>
                        <Left />
                    </IconButton>
                </div>
                <div className={clsx({ [s.position]: true, [s.position__right]: true })}>
                    <IconButton onClick={setNext} color="primary" className={clsx({ [s.icon]: true })}>
                        <Right />
                    </IconButton>
                </div>
            </Box>
            <Box
                ref={ref}
                className={clsx({ [s.imgBox]: true, [s.imgBox__tablet]: tablet, [s.imgBox__mobile]: mobile })}>
                {imgs.map((url, i) => (
                    <Box
                        key={`${url.imageId}`}
                        onClick={() => setId(i)}
                        className={clsx({
                            [s.product_image]: true,
                            [s.product_image__small]: true,
                            [s.product_image__mediumFixed]: tablet,
                            [s.product_image__smallFixed]: mobile,
                            [s.product_image__checked]: i === mainPictureID,
                        })}
                        sx={{ background: `url(${url.imageUrl}) center/cover no-repeat, ${colors.white}` }}
                    />
                ))}
            </Box>
        </div>
    );
};
