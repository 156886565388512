import React from "react";
import { Container } from "@mui/material";
import { PageRouter } from "../../router/Router";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";

type Props = unknown;

export const PageWrapper: React.FC<Props> = () => {
    return (
        <>
            <Header />

            <Container component="main" maxWidth="lg">
                <PageRouter />
            </Container>

            <Footer />
        </>
    );
};
