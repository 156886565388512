import React, { useEffect, useState } from "react";
import { Button, Collapse, useMediaQuery } from "@mui/material";
import s from "./FilterBlock.module.scss";
import { ReactComponent as Options } from "../../assets/Filter.svg";
import { Filters, FiltersProps } from "../Filters/Filters";

export const FilterBlock: React.FC<FiltersProps> = ({ ...props }) => {
    const mobile = useMediaQuery("(max-width: 900px)");
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    useEffect(() => {
        if (mobile) setOpen(false);
        else setOpen(true);
    }, [mobile]);

    return (
        <>
            <div className={mobile ? s.button__open : s.hidden}>
                <Button variant="outlined" size="large" fullWidth onClick={handleClick} startIcon={<Options />}>
                    {open ? "Скрыть фильтр" : "Раскрыть фильтр"}
                </Button>
            </div>
            {mobile ? (
                <Collapse in={open} timeout={600}>
                    <Filters mobile {...props} />
                </Collapse>
            ) : (
                <Filters {...props} />
            )}
        </>
    );
};
