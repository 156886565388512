import {
    Months,
    RecievedOrder,
    SizeProps,
    SizeTableProps,
    OrderProps,
    FullOrderInfoBack,
    OrderInfo,
    OrderProductInfo,
    AdminProduct,
    Product,
} from "../../types/type";

export default class TransformTypes {
    sizeToSelect = (sizes: Array<SizeTableProps>) => {
        return sizes.map(({ ru }) => ({ id: ru, value: ru }));
    };

    sizeToCheckBox = (sizes: Array<SizeTableProps>) => {
        return sizes.map(({ ru }) => ({ key: ru, value: ru }));
    };

    valueToSelect = (values: Array<string | number>) => {
        return values.map((value) => ({ id: value, value }));
    };

    valueToCheckBox = (values: Array<string | number>) => {
        return values.map((value) => ({ key: `${value}`, value }));
    };

    productSizeToSelect = (sizes: Array<SizeProps>) => {
        return sizes.map(({ size }) => ({ id: size, value: size }));
    };

    sortParamsToSelect = (sorts: Array<{ key: string; value: string }>) => {
        return sorts.map(({ key, value }) => ({ id: key, value }));
    };

    ordersToOrders = (orders: Array<RecievedOrder>) => {
        return orders.map((value) => {
            const date = value.dateTime.split("T")[0].split("-");
            const year = date[0];
            const month = Months[+date[1] - 1];
            const day = date[2];
            const newOrder: OrderProps = {
                orderNumber: `${value.id}`,
                orderDate: `${day} ${month} ${year} г.`,
                orderTime: value.dateTime.split("T")[1].slice(0, 5),
                person: `${value.lastName ?? ""} ${value.firstName} ${value.patronymic ?? ""}`,
                address: `${value.zipCode ? `${value.zipCode}, ` : ""}${value.street} ${value.building}${
                    value.flat ? `, ${value.flat}` : ""
                }`,
                orderType: value.deliveryType,
                price: value.totalCost,
                userOrderStatus: value.status,
                adminOrderStatus: value.status,
                paymentStatus: value.paymentType ?? undefined,
            };
            return newOrder;
        });
    };

    orderToOrder = (order: FullOrderInfoBack, id: number) => {
        const date = order.dateTime.split("T")[0].split("-");
        const year = date[0];
        const month = Months[+date[1] - 1];
        const day = date[2];
        let products: OrderProductInfo[];
        if (!order.products || !order.products[0]) {
            products = [];
        } else {
            products = order.products.map((value) => ({
                id: value.id,
                brand: value.brand,
                modelName: value.modelName,
                mainImage: value.mainImage,
                article: value.article,
                amount: value.amount,
                colors: value.colors,
                price: value.price,
                size: {
                    size: value.size,
                    amount: value.amount,
                    price: value.price,
                    cardPrice: 0,
                },
            }));
        }
        const newOrder: OrderInfo = {
            orderNumber: `${id}`,
            orderDate: `${day} ${month} ${year} г.`,
            orderTime: order.dateTime.split("T")[1].slice(0, 5),
            person: `${order.lastName ?? ""} ${order.firstName} ${order.patronymic ?? ""}`,
            address: `${order.zipCode ? `${order.zipCode}, ` : ""}${order.street} ${order.building}${
                order.flat ? `, ${order.flat}` : ""
            }`,
            fullPrice: order.totalCost,
            orderStatus: order.status,
            phone: order.phone,
            mail: order.email,
            deliveryPrice: order.deliveryPrice,
            discountPrice: 0,
            comment: order.comment ?? "",
            goods: products,
            paymentStatus: order.paymentType ?? undefined,
        };
        return newOrder;
    };

    goodsToProducts = (goods: AdminProduct[]): Product[] => {
        const newGoods = goods.map((item: AdminProduct) => {
            const { sex, priority, amount } = item;
            const values = [
                {
                    key: "Принадлежность",
                    value: sex.join(", "),
                },
                {
                    key: "Кол-во",
                    value: `${amount} шт.`,
                },
                {
                    key: "Приоритет",
                    value: `${priority}`,
                },
            ];

            return { ...item, values };
        });
        return newGoods;
    };
}
