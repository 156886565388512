import { MainPageADCardProps, Sex } from "../types/type";

export const mainPageADCards: Array<MainPageADCardProps> = [
    {
        value: Sex.men,
        img: "./men2.jpg",
        urlKey: Sex.men,
    },
    {
        value: Sex.women,
        img: "./women.png",
        urlKey: Sex.women,
    },
    {
        value: Sex.child,
        img: "./children.jpg",
        urlKey: Sex.child,
    },
];
