import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { PathString, PathStringProps } from "../../components/PathString/PathString";
import { url, userData, UserEnum } from "../../constants/constants";
import { ReactComponent as Plus } from "../../assets/Pluse-regtangle.svg";
import { ReactComponent as Boot } from "../../assets/Boot.svg";
import { ContentTabs } from "../../components/tabs/ContentTabs/ContentTabs";
import { Snack } from "../../components/snacks/Snack";
import { PersonalData } from "../../components/PersonalData/PersonalData";
import { OrdersWrapper } from "../../components/orders/OrdersWrapper/OrdersWrapper";
import { ProductWrapper } from "../../components/ProductWrapper/ProductWrapper";
import { BannerWrapper } from "../../components/BannerWrapper/BannerWrapper";

type Props = unknown;

const personalAccountUser = [
    {
        id: 0,
        value: "Персональные данные",
        Content: ({ ...props }) => <PersonalData {...props} userData={userData.userData} />,
        key: "data",
    },
    {
        id: 1,
        value: "Мои заказы",
        Content: ({ ...props }) => <OrdersWrapper userType={UserEnum.user} ordersType="Заказы" {...props} />,
        key: "orders",
    },
    {
        id: 2,
        value: "Возвраты",
        Content: ({ ...props }) => <OrdersWrapper ordersType="Возвраты" userType={UserEnum.user} {...props} />,
        key: "returns",
    },
];
const personalAccountAdmin = [
    {
        id: 0,
        value: "Заказы",
        Content: ({ ...props }) => <OrdersWrapper userType={UserEnum.admin} {...props} />,
        key: "orders",
    },
    {
        id: 1,
        value: "Товары",
        Content: ({ ...props }) => <ProductWrapper url={url.createProduct} {...props} />,
        key: "goods",
    },
    {
        id: 2,
        value: "Баннеры",
        Content: ({ ...props }) => <BannerWrapper {...props} />,
        key: "banners",
    },
];

export const PersonalAccount: React.FC<Props> = () => {
    const userRole = window.localStorage.getItem("user-role");
    const personalTabs = userRole?.includes(UserEnum.admin) ? personalAccountAdmin : personalAccountUser;

    const open = localStorage.getItem("bootCreated");
    const [openSnack, setOpen] = useState(!!open);

    const handleClose = () => {
        setOpen(false);
        localStorage.removeItem("bootCreated");
    };

    const message = (
        <>
            <Boot />
            <Typography color="primary" variant="h3">
                Товар добавлен на сайт
            </Typography>
        </>
    );

    const history = useHistory();

    const pathStringValues: PathStringProps["values"] = [
        {
            value: "Главная",
            link: url.main,
        },
        {
            value: "Личный кабинет",
        },
    ];

    const logOut = () => {
        window.localStorage.removeItem("jwt");
        window.localStorage.removeItem("user-role");
        window.location.replace(url.shop);
    };

    const button = !userRole?.includes(UserEnum.admin) ? (
        <Button variant="outlined" onClick={logOut}>
            Выйти
        </Button>
    ) : (
        <Box display="flex" gap="2rem">
            <Button variant="contained" startIcon={<Plus />} onClick={() => history.push(url.createProduct)}>
                Добавить товар
            </Button>
            <Button variant="outlined" onClick={logOut}>
                Выйти
            </Button>
        </Box>
    );

    return (
        <Box width="100%">
            <PathString values={pathStringValues} />
            <Box display="flex" justifyContent="space-between" width="100%" flexWrap="wrap" gap="0.75rem">
                <Typography variant="h1">
                    {userRole !== UserEnum.admin ? "Личный кабинет" : "Кабинет администратора"}
                </Typography>
                {button}
            </Box>
            <ContentTabs tabKey="person_tabs" tabs={personalTabs} mt="1rem" />
            <Snack open={openSnack} handleClose={handleClose} message={message} />
        </Box>
    );
};
