import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BannerCarousel } from "../../components/BannerCarousel/BannerCarousel";
import { ADCard } from "../../components/cards/AD/ADCard";
import { ProductCard } from "../../components/cards/ProductCard/ProductCard";
import { Loader } from "../../components/Loader/Loader";
import { Snack } from "../../components/snacks/Snack";
import { url, OrderStatusOptions } from "../../constants/constants";
import { mainPageADCards } from "../../constants/stub";
import { useRequest } from "../../hooks/hooks";
import GoodsService from "../../services/GoodsService";
import { Goods } from "../../types/type";
import s from "./Main.module.scss";

type Props = unknown;

export const Main: React.FC<Props> = () => {
    const history = useHistory();
    const mobile = useMediaQuery("(max-width: 900px)");
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const gs = new GoodsService();
    const [goods, errorGoods] = useRequest<Goods>(
        () => gs.getPopularGoods(8),
        (res) => res
    );

    useEffect(() => {
        const search = history.location.search.split("?")[1];
        if (search) {
            Object.entries(OrderStatusOptions).forEach((key) => {
                if (search === key[0]) {
                    setMessage(key[1]);
                    setOpen(true);
                    history.push("/");
                }
            });
        }
    }, [history, history.location.search]);

    const handleClick = () => {
        history.push(url.shop);
    };

    const handleCardClick = (urlKey: string) => {
        history.push(
            `${url.shop}?itemsPerPage=12&pageNumber=1&sortParameter=По%20популярности&sex=${urlKey}&brands=&colors=&sizes=`
        );
    };

    let content;
    if (errorGoods) {
        content = <Typography variant="h2">Что-то пошло не так</Typography>;
    } else if (!goods) {
        content = <Loader />;
    } else if (goods) {
        content = (
            <>
                {goods
                    .filter((item, index) => index < 8)
                    .map((itemValue) => {
                        return <ProductCard {...itemValue} key={`pr_card_${itemValue.id}`} />;
                    })}
            </>
        );
    }

    return (
        <Box className={s.container}>
            <BannerCarousel />
            <Box className={s.cardsBox}>
                {mainPageADCards.map((props) => (
                    <ADCard {...props} onClick={() => handleCardClick(props.urlKey)} key={`mainADCards_${props.img}`} />
                ))}
            </Box>
            <Box mt="2.5rem">
                <Typography variant="h2">Рейтинг продаж</Typography>
                <Box className={clsx({ [s.goodsBox]: true, [s.goodsBox__mobile]: mobile })}>{content}</Box>
                <Button variant="outlined" fullWidth onClick={handleClick}>
                    Перейти в каталог
                </Button>
            </Box>
            <Snack
                open={open}
                handleClose={() => setOpen(false)}
                message={<Typography variant="body1">{message}</Typography>}
            />
        </Box>
    );
};
