import { Box, Divider, Typography, useMediaQuery } from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";
import { OrderForm } from "../../components/forms/OrderForm/OrderForm";
import { Loader } from "../../components/Loader/Loader";
import { PathString } from "../../components/PathString/PathString";
import { ShopBag } from "../../components/ShopBag/ShopBag";
import { url } from "../../constants/constants";
import s from "./OrderCreation.module.scss";

type Props = unknown;

const pathStringValues = [
    {
        link: url.main,
        value: "Главная",
    },
    {
        value: "Оформить заказ",
    },
];

export const OrderCreation: React.FC<Props> = () => {
    const tablet = useMediaQuery("(max-width: 1025px)");
    const mobile = useMediaQuery("(max-width: 600px)");
    const micro = useMediaQuery("(max-width: 375px)");
    const [disabled, setDisabled] = useState(false);
    const handleChange = () => setDisabled(true);
    const [prevSumm, setPrevSumm] = useState(0);
    const handlePrevSummChange = (sum: number) => setPrevSumm(sum);
    const [summ, setSumm] = useState(0);
    const handleSummChange = (sum: number) => setSumm(sum);
    const [cashPay, setCashPay] = useState(true);
    const handleCashPayChange = (val: boolean) => setCashPay(val);

    let content = <Loader />;

    if (summ !== 0 || disabled) {
        content = (
            <OrderForm disabled={disabled} summ={summ} prevSumm={prevSumm} handleCashPayChange={handleCashPayChange} />
        );
    }

    return (
        <Box width="100%">
            <PathString values={pathStringValues} />
            <Typography variant={micro ? "h2" : "h1"}>Оформить заказ</Typography>
            <div className={clsx({ [s.container]: true, [s.container__mobile]: tablet })}>
                <div className={clsx({ [s.order]: true, [s.order__big]: !mobile })}>
                    <div className={clsx({ [s.goodsBox]: true, [s.goodsBox__mobile]: mobile })}>
                        <Divider />
                        <ShopBag
                            buttonRemove
                            changing
                            handleChange={handleChange}
                            handleSummChange={handleSummChange}
                            handlePrevSummChange={handlePrevSummChange}
                            cashPay={cashPay}
                        />
                        <Divider />
                    </div>
                    {content}
                </div>
            </div>
        </Box>
    );
};
