import { Box, IconButton, Link, Typography, useMediaQuery } from "@mui/material";
import clsx from "clsx";
import React, { useRef, useState, useEffect } from "react";
import { useRequest } from "../../hooks/hooks";
import BannerService from "../../services/BannerService";
import { BannerUserType } from "../../types/type";
import { ReactComponent as Left } from "../../assets/Round-Left.svg";
import { ReactComponent as Right } from "../../assets/Round-Right.svg";
import s from "./BannerCarousel.module.scss";

type Props = unknown;

export const BannerCarousel: React.FC<Props> = () => {
    const bs = new BannerService();
    const [banners, error] = useRequest<BannerUserType>(bs.getUserBanners, (res) => res);
    const [id, setBannerId] = useState(0);
    const mobile = useMediaQuery("(max-width: 900px)");
    const aLink: React.RefObject<HTMLAnchorElement> | null | undefined = useRef(null);

    useEffect(() => {
        const interval = setInterval(() => {
            if (banners) {
                setBannerId(id + 1 < banners.length ? id + 1 : 0);
            }
        }, 5000);
        return () => clearInterval(interval);
    });

    if (error) {
        return <Typography variant="h3">Что-то пошло не так</Typography>;
    }
    if (!banners || !banners[0]) {
        return <></>;
    }

    const setNext = () => {
        const newId = id + 1 < banners.length ? id + 1 : 0;
        setBannerId(newId);
    };

    const setPrev = () => {
        const newId = id - 1 >= 0 ? id - 1 : banners.length - 1;
        setBannerId(newId);
    };

    const setId = (newId: number) => {
        setBannerId(newId);
    };

    const banner = banners[id];

    const handleClick = () => {
        if (aLink) {
            aLink?.current?.click();
        }
    };

    return (
        <Box
            className={clsx({ [s.container]: !!banners, [s.container__mobile]: mobile })}
            sx={{
                background: `url(${
                    mobile ? banner.mobileImage.imageUrl : banner.desktopImage.imageUrl
                }) center center/contain no-repeat`,
            }}>
            <Link hidden ref={aLink} href={banner.link} target="_blank" />
            <Box className={s.clickable} onClick={handleClick} />
            {banners.length > 1 && (
                <>
                    <div
                        className={clsx({
                            [s.position]: true,
                            [s.position__left]: true,
                            [s.position__left_mobile]: mobile,
                        })}>
                        <IconButton
                            onClick={setPrev}
                            color="primary"
                            className={clsx({ [s.icon]: true, [s.icon__mobile]: mobile })}>
                            <Left />
                        </IconButton>
                    </div>
                    <div
                        className={clsx({
                            [s.position]: true,
                            [s.position__right]: true,
                            [s.position__right_mobile]: mobile,
                        })}>
                        <IconButton
                            onClick={setNext}
                            color="primary"
                            className={clsx({ [s.icon]: true, [s.icon__mobile]: mobile })}>
                            <Right />
                        </IconButton>
                    </div>
                </>
            )}
            <div className={s.round_wrapper}>
                {banners.map((_, index) => (
                    <Box
                        className={clsx({ [s.round]: true, [s.round__checked]: index === id })}
                        key={_.id}
                        onClick={() => setId(index)}
                    />
                ))}
            </div>
        </Box>
    );
};
