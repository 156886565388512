import { useEffect, useState } from "react";

// eslint-disable-next-line
export function useRequest<T>(get: () => Promise<any>, change: (res: any) => T[]): [Array<T> | null, boolean] {
    const [filter, setFilter] = useState<Array<T> | null>(null);
    const [loadingFilter, setLoadingFilter] = useState(false);
    const [errorFilter, setErrorFilter] = useState(false);

    useEffect(() => {
        if (!filter && !loadingFilter && !errorFilter) {
            setLoadingFilter(true);
            get()
                .then((res) => change(res))
                .then((res) => {
                    setFilter(res);
                    setErrorFilter(false);
                })
                .catch(() => setErrorFilter(true))
                .finally(() => setLoadingFilter(false));
        }
    }, [filter, loadingFilter, errorFilter, get, change]);

    return [filter, errorFilter];
}
