import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { url } from "../../constants/constants";
import s from "./Error.module.scss";

type Props = unknown;

export const Error: React.FC<Props> = () => {
    return (
        <Container>
            <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                height="100%"
                gap="1rem"
                py="3rem"
                margin="auto auto">
                <div className={s.err} />
                <Typography variant="body2">Что-то пошло не так (</Typography>
                <Button variant="contained" component={Link} to={url.main}>
                    Перейти на главную
                </Button>
            </Box>
        </Container>
    );
};
