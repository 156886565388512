import { Box, FormGroup, FormLabel, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormikErrors, useFormik } from "formik";
import { CheckButton } from "../../CheckButton/CheckButton";
import { InputBlock } from "../../InputBlock/InputBlock";
import s from "./PersonalOrderForm.module.scss";
import { SizeTable } from "../../SizeTable/SizeTable";
import { DataInput } from "../../DataInput/DataInput";
import { CheckBoxFilterProps, PersonalOrderType } from "../../../types/type";
import EnumsService from "../../../services/EnumsServices";
import TransformTypes from "../../../utils/typeTransformers/TransformTypes";
import { Loader } from "../../Loader/Loader";
import OrdersService from "../../../services/OrdersService";
import { Snack } from "../../snacks/Snack";
import { useRequest } from "../../../hooks/hooks";
import { inputValues } from "../../../constants/constants";

type Props = unknown;

const validate = (values: PersonalOrderType) => {
    const errors: FormikErrors<PersonalOrderType> = {};

    if (!values.firstName) {
        errors.firstName = "Обязательное поле";
    }

    if (!values.email) {
        errors.email = "Обязательное поле";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = "Неверный адрес почты";
    }

    if (!values.phone) {
        errors.phone = "Обязательное поле";
    } else if (!/^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/.test(values.phone)) {
        errors.phone = "Неверный номер телефона";
    }

    if (!values.modelName) {
        errors.modelName = "Обязательное поле";
    }

    if (!values.sizes[0]) {
        errors.sizes = "Нужно выбрать хотя бы один размер";
    }

    return errors;
};

export const PersonalOrderForm: React.FC<Props> = () => {
    const [openSnack, setOpenSnack] = useState(false);
    const [message, setMessage] = useState("");
    const [validationWay, setValidationWay] = useState(false);
    const es = new EnumsService();
    const tt = new TransformTypes();
    const [sizes, errorSizes] = useRequest<CheckBoxFilterProps>(es.getSizes, tt.sizeToCheckBox);

    useEffect(() => {
        if (errorSizes) {
            setMessage(
                "Что-то пошло не так, пожалуйста, проверьте подключение к интернету, перезагрузите страницу и попробуйте еще раз."
            );
            setOpenSnack(true);
        }
    }, [errorSizes]);

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            comment: "",
            modelName: "",
            sizes: [""],
        },
        validate,
        validateOnChange: validationWay,
        onSubmit: (values) => {
            const formValues: PersonalOrderType = {
                modelName: values.modelName,
                sizes: values.sizes,
                comment: values.comment,
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phone: values.phone,
            };
            const os = new OrdersService();
            os.createPersonalOrder(formValues)
                .then(() => {
                    setMessage("Заказ был передан в магазин. Мы свяжемся с вами в ближайшее время.");
                    setOpenSnack(true);
                    formik.resetForm();
                    formik.setFieldValue("sizes", [], false);
                })
                .catch(() => {
                    setMessage(
                        "Что-то пошло не так, пожалуйста, проверьте подключение к интернету, перезагрузите страницу и попробуйте еще раз."
                    );
                    setOpenSnack(true);
                });
        },
    });

    useEffect(() => {
        if (formik.submitCount >= 1) setValidationWay(true);
    }, [formik.submitCount]);

    const handleClick = (index: string) => {
        let newSizes = formik.values.sizes;
        if (newSizes.includes(index)) {
            newSizes = newSizes.filter((ind) => ind !== index);
        } else {
            newSizes.push(index);
        }
        if (newSizes.includes("")) {
            newSizes = newSizes.filter((ind) => ind !== "");
        }
        formik.setValues({ ...formik.values, sizes: newSizes });
    };

    let content;

    if (errorSizes) {
        content = <Typography variant="body1">Что-то пошло не так</Typography>;
    } else if (!sizes) {
        content = <Loader size={40} />;
    } else {
        content = (
            <>
                {sizes.map(({ value, key }) => (
                    <CheckButton
                        key={key}
                        size
                        onClick={() => handleClick(key)}
                        value={value}
                        checked={formik.values.sizes.includes(key)}
                    />
                ))}
            </>
        );
    }

    return (
        <form onSubmit={formik.handleSubmit} className={s.container}>
            <InputBlock
                values={inputValues.map((val) => {
                    const newVal = val;
                    const name = val.name as keyof PersonalOrderType;
                    if (name !== "sizes") newVal.value = formik.values[name];
                    newVal.helperText = formik.errors[name];
                    newVal.color = formik.errors[name] ? "error" : "primary";
                    newVal.onChange = formik.handleChange;
                    return newVal;
                })}
                noDivider
            />
            <FormGroup row>
                <FormLabel>
                    <Box display="flex" justifyContent="space-between">
                        Размер
                        <SizeTable />
                    </Box>
                </FormLabel>
                {content}
                {formik.errors.sizes ? (
                    <Typography width="100%" variant="caption" align="right" color="error">
                        {formik.errors.sizes}
                    </Typography>
                ) : null}
            </FormGroup>
            <DataInput
                label="Дополнительно"
                placeholder="Текст вашего сообщения"
                color="primary"
                variant="outlined"
                position={0}
                multiline
                value={formik.values.comment}
                name="comment"
                onChange={formik.handleChange}
            />
            <Button type="submit" variant="contained" fullWidth>
                Отправить заказ
            </Button>

            <Snack
                open={openSnack}
                handleClose={() => setOpenSnack(false)}
                message={<Typography variant="body2">{message}</Typography>}
            />
        </form>
    );
};
