import React from "react";
import { InputBase, IconButton, InputBaseProps } from "@mui/material";
import { ReactComponent as SearchIcon } from "../../assets/Search.svg";
import s from "./Search.module.scss";

type Props = {
    hidden?: boolean;
    mobile?: boolean;
    handleClick?: () => void;
} & InputBaseProps;

/**
 *
 * @param {function} setSearch - changing value of search visibility (true, false) on click
 * @returns react fc containing search input and often searches
 */
export const Search: React.FC<Props> = ({ hidden, mobile, handleClick, ...props }) => {
    if (hidden) return null;
    return (
        <>
            <InputBase
                className={mobile ? s.searchInput__mobile : s.searchInput}
                {...props}
                endAdornment={
                    <IconButton color="secondary" onClick={handleClick}>
                        <SearchIcon />
                    </IconButton>
                }
                color="secondary"
            />
        </>
    );
};
