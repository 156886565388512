import { Box, Button, Dialog, IconButton, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as Lamp } from "../../assets/Lamp.svg";
import { ReactComponent as Close } from "../../assets/Close.svg";

type Props = {
    title?: string;
};

export const WhyCheaper: React.FC<Props> = ({ title }) => {
    const [open, setOpen] = useState(false);
    const mobile = useMediaQuery("(max-width: 600px)");

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    return (
        <div>
            <Button variant="outlined" startIcon={<Lamp />} onClick={handleOpen}>
                <Typography variant="subtitle2">{title ?? "Почему дешевле?"}</Typography>
            </Button>
            <Dialog maxWidth={mobile ? false : "xs"} fullScreen={mobile} open={open} onClose={handleClose}>
                <Box p="3.5rem 2rem 2rem 2rem" display="flex" flexDirection="column" gap="2rem" alignItems="center">
                    <Box position="absolute" top="1rem" right="1rem">
                        <IconButton size="small" onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Typography variant="h3" color="primary">
                        Почему дешевле наличными
                    </Typography>
                    <Typography variant="body1" color="primary">
                        При оплате наличными возврат средств покупателю происходит значительно быстрее (банк может
                        задерживать деньги в течение 30 дней).
                        <br />
                        <br />
                        Кроме того средства быстрее поступают нам на счёт, а значит мы быстрее сможем обновить наш
                        ассортимент последними релизами!
                    </Typography>
                </Box>
            </Dialog>
        </div>
    );
};
