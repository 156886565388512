export default class Image {
    id: number;
    imagePath: string;
    imageFile: File | undefined;
    serverId: number | undefined;

    constructor(id: number, imagePath: string, imageFile: File | undefined, serverId: number | undefined) {
        this.id = id;
        this.imageFile = imageFile;
        this.imagePath = imagePath;
        this.serverId = serverId;
    }
}
