export default class EnumsService {
    apiBase = `${process.env.REACT_APP_SITE_URL}/rest`;

    getSortings = async () => {
        const res = await fetch(encodeURI(`${this.apiBase}/sortings`));

        if (!res.ok) {
            throw new Error(`Error ${res.status} ${res.statusText} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };

    getSizes = async () => {
        const res = await fetch(encodeURI(`${this.apiBase}/sizes`));

        if (!res.ok) {
            throw new Error(`Error ${res.status} ${res.statusText} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };

    getSex = async () => {
        const res = await fetch(encodeURI(`${this.apiBase}/sex`));

        if (!res.ok) {
            throw new Error(`Error ${res.status} ${res.statusText} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };

    getOrderStatus = async () => {
        const res = await fetch(encodeURI(`${this.apiBase}/order-statuses`));

        if (!res.ok) {
            throw new Error(`Error ${res.status} ${res.statusText} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };

    getMaterials = async () => {
        const res = await fetch(encodeURI(`${this.apiBase}/materials`));

        if (!res.ok) {
            throw new Error(`Error ${res.status} ${res.statusText} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };

    getColors = async () => {
        const res = await fetch(encodeURI(`${this.apiBase}/colors`));

        if (!res.ok) {
            throw new Error(`Error ${res.status} ${res.statusText} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };

    getBrands = async () => {
        const res = await fetch(encodeURI(`${this.apiBase}/brands`));

        if (!res.ok) {
            throw new Error(`Error ${res.status} ${res.statusText} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };

    getPaymentTypes = async () => {
        const res = await fetch(encodeURI(`${this.apiBase}/payment-types`));

        if (!res.ok) {
            throw new Error(`Error ${res.status} ${res.statusText} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };

    getDeliveryWays = async () => {
        const res = await fetch(encodeURI(`${this.apiBase}/delivery`));

        if (!res.ok) {
            throw new Error(`Error ${res.status} ${res.statusText} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };
}
