import { Box, Typography, useMediaQuery } from "@mui/material";
import clsx from "clsx";
import React, { ChangeEvent, useState } from "react";
import { colors } from "../../theme";
import { SelectOption } from "../SelectOption/SelectOption";
import { ProductCard } from "../cards/ProductCard/ProductCard";
import s from "./SortBootsPage.module.scss";
import { Goods, SelectOptions } from "../../types/type";
import EnumsService from "../../services/EnumsServices";
import { Loader } from "../Loader/Loader";
import TransformTypes from "../../utils/typeTransformers/TransformTypes";
import { useRequest } from "../../hooks/hooks";

type Props = {
    goods: Array<Goods>;
    sortWay: string;
    handleSort: (e: React.ChangeEvent<HTMLInputElement>) => void;
    amount: number;
};

export const SortBootsPage: React.FC<Props> = ({ goods, sortWay, handleSort, amount }) => {
    const mobile = useMediaQuery("(max-width: 900px)");
    const [open, setOpen] = useState(false);

    const es = new EnumsService();
    const tt = new TransformTypes();
    const [sortVariants, errorSortVar] = useRequest<SelectOptions>(es.getSortings, tt.sortParamsToSelect);

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    if (!sortVariants) {
        return <Loader />;
    }

    return (
        <Box width="100%">
            <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap-reverse"
                gap="1rem"
                alignItems="center"
                p="2rem 0 1.5rem">
                <Box display="flex" gap="0.25rem">
                    <Typography variant="body2" color={colors["500"]}>
                        Найдено:
                    </Typography>
                    <Typography variant="body2">{amount}</Typography>
                </Box>

                {errorSortVar ? null : (
                    <SelectOption
                        value={sortWay}
                        onChange={handleSort}
                        options={sortVariants}
                        fullWidth={open}
                        selectProps={{ onOpen, onClose }}
                        sx={{ maxWidth: mobile ? "9.4rem" : "172.7px" }}
                        color="secondary"
                    />
                )}
            </Box>

            <Box className={clsx({ [s.goods_container]: true, [s.goods_container__mobile]: mobile })}>
                {goods.map((itemValue) => {
                    return <ProductCard {...itemValue} key={`pr_card_${itemValue.id}`} />;
                })}
            </Box>
        </Box>
    );
};
