import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { UserEnum } from "../../../constants/constants";
import OrdersService from "../../../services/OrdersService";
import { OrderProps } from "../../../types/type";
import TransformTypes from "../../../utils/typeTransformers/TransformTypes";
import { Loader } from "../../Loader/Loader";
import { ShortOrder } from "../ShortOrder/ShortOrder";

type Props = {
    userType: string;
    ordersType?: "Заказы" | "Возвраты";
};

export const OrdersWrapper: React.FC<Props> = ({ userType, ordersType }) => {
    const [orders, setOrders] = useState<OrderProps[] | null>(null);
    const [changed, setChanged] = useState(false);
    const [loadingOrders, setLoadingOrders] = useState(false);
    const [errorOrders, setErrorOrders] = useState(false);

    useEffect(() => {
        if ((!orders || changed) && !loadingOrders && !errorOrders) {
            const token = window.localStorage.getItem("jwt") ?? "";
            const tt = new TransformTypes();
            const os = new OrdersService();
            setLoadingOrders(true);
            setChanged(false);
            if (userType === UserEnum.user) {
                if (ordersType === "Возвраты") {
                    os.getUserRefunds(token)
                        .then((res) => tt.ordersToOrders(res))
                        .then((res) => {
                            setOrders(res);
                            setErrorOrders(false);
                        })
                        .catch(() => setErrorOrders(true))
                        .finally(() => setLoadingOrders(false));
                } else {
                    os.getUserOrders(token)
                        .then((res) => tt.ordersToOrders(res))
                        .then((res) => {
                            setOrders(res);
                            setErrorOrders(false);
                        })
                        .catch(() => setErrorOrders(true))
                        .finally(() => setLoadingOrders(false));
                }
            } else {
                os.getAdminOrders(token)
                    .then((res) => tt.ordersToOrders(res))
                    .then((res) => {
                        setOrders(res);
                        setErrorOrders(false);
                    })
                    .catch(() => setErrorOrders(true))
                    .finally(() => setLoadingOrders(false));
            }
        }
    }, [orders, changed, loadingOrders, userType, ordersType, errorOrders]);

    if (errorOrders) {
        return <Typography variant="h2">Что-то пошло не так</Typography>;
    }

    if (!orders || loadingOrders) {
        return <Loader />;
    }

    if (!orders[0]) {
        return <Typography variant="h2">Пока пусто</Typography>;
    }

    return (
        <div>
            {orders.map(({ ...props }) => (
                <ShortOrder
                    key={{ ...props }.orderNumber}
                    change={[changed, setChanged]}
                    {...props}
                    userType={userType}
                />
            ))}
        </div>
    );
};
