import { Button, Divider, Typography, useMediaQuery } from "@mui/material";
import clsx from "clsx";
import { FormikErrors, useFormik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import { DataInput } from "../../components/DataInput/DataInput";
import { url } from "../../constants/constants";
import UserService from "../../services/UserService";
import s from "./ResetPassword.module.scss";

type Props = unknown;

interface FormValues {
    newPassword: string;
    newPassword2: string;
}

const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.newPassword) {
        errors.newPassword = "Обязательное поле";
    }

    if (values.newPassword2 !== values.newPassword) {
        errors.newPassword2 = "Пароли должны совпадать";
    }

    return errors;
};

export const RestorePassword: React.FC<Props> = () => {
    const mobile = useMediaQuery("(max-width: 600px)");
    const history = useHistory();
    const splitted = history.location.pathname.split("/");
    const email = splitted[3];
    const uuid = splitted[2];

    const formik = useFormik({
        initialValues: {
            newPassword: "",
            newPassword2: "",
        },
        validate,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values) => {
            const formValues = {
                email,
                uuid,
                newPassword: `${values.newPassword}`,
            };

            const us = new UserService();
            us.setNewPassword(formValues)
                .then((res) => {
                    window.localStorage.setItem("jwt", res.jwt);
                    window.localStorage.setItem("user-role", res.tokenPayload.roles.toString());
                    history.push(url.shop);
                })
                .catch((error) => console.log(error));
        },
    });

    return (
        <form onSubmit={formik.handleSubmit} className={clsx({ [s.container]: true, [s.container__mobile]: mobile })}>
            <Typography variant="h2">Восстановить пароль</Typography>
            <Divider />
            <Typography variant="body1">{email}</Typography>
            <DataInput
                label="Пароль"
                name="newPassword"
                type="password"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                helperText={formik.errors.newPassword}
                color={formik.errors.newPassword ? "error" : "primary"}
                position={0}
            />
            <DataInput
                label="Повторите пароль"
                name="newPassword2"
                type="password"
                value={formik.values.newPassword2}
                onChange={formik.handleChange}
                helperText={formik.errors.newPassword2}
                color={formik.errors.newPassword2 ? "error" : "primary"}
                position={0}
            />
            <Button type="submit" variant="contained" fullWidth>
                Сохранить
            </Button>
        </form>
    );
};
