import {
    Box,
    Dialog,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as Close } from "../../assets/Close.svg";
import { useRequest } from "../../hooks/hooks";
import EnumsService from "../../services/EnumsServices";
import { SizeTableProps } from "../../types/type";
import { Loader } from "../Loader/Loader";

type Props = unknown;

export const SizeTable: React.FC<Props> = () => {
    const [open, setOpen] = useState(false);
    const es = new EnumsService();
    const [rows, errorRows] = useRequest<SizeTableProps>(es.getSizes, (res) => res);

    if (errorRows) {
        return <Typography variant="h3">Что-то пошло не так</Typography>;
    }

    if (!rows) {
        return <Loader size={40} />;
    }

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    return (
        <>
            <Box onClick={handleOpen} sx={{ cursor: "pointer" }}>
                Таблица размеров
            </Box>
            <Dialog open={open} scroll="body" onClose={handleClose}>
                <Box p="3.5rem 2rem 2rem 2rem" display="flex" flexDirection="column" gap="2rem" alignItems="center">
                    <Box position="absolute" top="1rem" right="1rem">
                        <IconButton size="small" onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Typography variant="h3" color="primary">
                        Таблица размеров
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 256 }} aria-label="simple table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>RU</TableCell>
                                    <TableCell>EU</TableCell>
                                    <TableCell>US</TableCell>
                                    <TableCell>UK</TableCell>
                                    <TableCell>Длина стопы</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow hover key={row.eu}>
                                        <TableCell>{row.ru}</TableCell>
                                        <TableCell>{row.eu}</TableCell>
                                        <TableCell>{row.us}</TableCell>
                                        <TableCell>{row.uk}</TableCell>
                                        <TableCell>{row.footLength}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Dialog>
        </>
    );
};
