import { Box, Button, FormGroup, FormLabel, Link, TextField } from "@mui/material";
import { FormikErrors, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link as BrowserLink } from "react-router-dom";
import { url } from "../../../constants/constants";
import UserService from "../../../services/UserService";
import { colors } from "../../../theme";
import s from "./EnterForm.module.scss";

type Props = {
    onClick: () => void;
};

interface FormValues {
    username: string;
    password: string;
}

const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.password) {
        errors.password = "Обязательное поле";
    }

    if (!values.username) {
        errors.username = "Обязательное поле";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)) {
        errors.username = "Неверный адрес почты";
    }

    return errors;
};

export const EnterForm: React.FC<Props> = ({ onClick, ...props }) => {
    const [validationWay, setValidationWay] = useState(false);
    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
        },
        validate,
        validateOnChange: validationWay,
        validateOnBlur: validationWay,
        onSubmit: (values) => {
            const us = new UserService();
            us.authenticateUser(values)
                .then((res) => {
                    window.localStorage.setItem("jwt", res.jwt);
                    window.localStorage.setItem("user-role", res.tokenPayload.roles.toString());
                    onClick();
                })
                .catch(() => {
                    formik.setErrors({
                        username: "Неверный e-mail или пароль",
                        password: "Неверный e-mail или пароль",
                    });
                });
        },
    });

    useEffect(() => {
        if (formik.submitCount >= 1) setValidationWay(true);
    }, [formik.submitCount]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box display="flex" flexDirection="column" gap="2.5rem">
                <div>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>Email</FormLabel>
                        <TextField
                            type="email"
                            name="username"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            helperText={formik.errors.username}
                            color={formik.errors.username ? "error" : "secondary"}
                        />
                    </FormGroup>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>Пароль</FormLabel>
                        <TextField
                            type="password"
                            helperText={
                                formik.errors.password ? (
                                    <Box display="flex" width="100%" justifyContent="space-between">
                                        <Link
                                            component={BrowserLink}
                                            color={`${colors.black} !important`}
                                            {...props}
                                            align="left"
                                            mr="auto"
                                            to={url.emailEnter}>
                                            Напомнить пароль?
                                        </Link>
                                        {formik.errors.password}
                                    </Box>
                                ) : (
                                    <Link component={BrowserLink} {...props} onClick={onClick} to={url.emailEnter}>
                                        Напомнить пароль?
                                    </Link>
                                )
                            }
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            color={formik.errors.password ? "error" : "secondary"}
                        />
                    </FormGroup>
                </div>
                <Button type="submit" fullWidth {...props} variant="contained">
                    Войти
                </Button>
            </Box>
        </form>
    );
};
