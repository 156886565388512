import React, { memo } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { Goods } from "../../../types/type";
import s from "./ProductCard.module.scss";
import { colors } from "../../../theme";
import { url } from "../../../constants/constants";

type Props = Goods;
/**
 *
 * @returns react fc, card with image, and text description
 *
 * @param {string} name - product name
 * @param {number} price - price in rubles
 * @param {string} brand - product brand
 * @param {string} mainImage - product image url
 */

export const ProductCard: React.FC<Props> = memo(({ id, modelName, minPrice, brand, mainImage }) => {
    const mobile = useMediaQuery("(max-width: 900px)");
    const micro = useMediaQuery("(max-width: 375px)");

    return (
        <Box
            component={Link}
            to={`${url.shop}/${id}`}
            className={clsx({
                [s.product_container]: true,
                [s.product_container__mobile]: mobile,
                [s.product_container__micro]: micro,
            })}>
            <Box
                className={clsx({
                    [s.product_image]: true,
                    [s.product_image__mobile]: mobile,
                    [s.product_image__micro]: micro,
                })}
                sx={{ background: `url(${mainImage.imageUrl}) center/cover no-repeat, ${colors.white}` }}
            />
            <Box p="0.75rem 1rem">
                <Typography variant="caption" color={colors["500"]}>
                    {brand}
                </Typography>

                <Box mt="0.25rem" mb="0.75rem">
                    <Typography variant={mobile ? "subtitle2" : "body2"} color="primary" component="span">
                        {modelName}
                    </Typography>
                </Box>

                <Typography variant="subtitle1">
                    <Typography variant="subtitle2" color={colors["700"]} component="span">
                        от
                    </Typography>
                    {` ${minPrice} ₽`}
                </Typography>
            </Box>
        </Box>
    );
});
