import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useHistory } from "react-router-dom";
import { FilterBlock } from "../../components/FilterBlock/FilterBlock";
import { Pagination } from "../../components/Pagination/Pagination";
import { SortBootsPage } from "../../components/SortBootsPage/SortBootsPage";
import { Goods } from "../../types/type";
import GoodsService from "../../services/GoodsService";
import { Loader } from "../../components/Loader/Loader";
import { FilterValue } from "../../components/Filters/Filters";

type Props = unknown;

export const Shop: React.FC<Props> = () => {
    const mobile = useMediaQuery("(max-width: 900px)");
    const history = useHistory();
    const { search } = history.location;
    const [currentPage, setPage] = useState(1);
    const [sexArr, setSex] = useState<Array<string>>([]);
    const [brandArr, setBrand] = useState<Array<string>>([]);
    const [minPrice, setMinPrice] = useState<string>("");
    const [maxPrice, setMaxPrice] = useState<string>("");
    const [colorArr, setColor] = useState<Array<string>>([]);
    const [sizeArr, setSize] = useState<Array<string>>([]);
    const [url, setUrl] = useState(search);
    const [changed, setChanged] = useState(false);
    const [goods, setGoods] = useState<Goods[] | null>(null);
    const [count, setCount] = useState(0);
    const [amount, setAmount] = useState(0);
    const [pageError, setPageError] = useState(false);

    const [sortWay, setSortWay] = useState("PRIORITY");
    const handleSort = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSortWay(e.target.value);
        setChanged(true);
        setPage(1);
    };

    const handleSexChange = (key: string) => {
        if (sexArr.includes(key)) {
            setSex([...sexArr.slice(0, sexArr.indexOf(key)), ...sexArr.slice(sexArr.indexOf(key) + 1)]);
        } else {
            setSex([...sexArr, key]);
        }
        if (!mobile) {
            setChanged(true);
            setPage(1);
        }
    };
    const handleBrandChange = (key: string) => {
        if (brandArr.includes(key)) {
            setBrand([...brandArr.slice(0, brandArr.indexOf(key)), ...brandArr.slice(brandArr.indexOf(key) + 1)]);
        } else {
            setBrand([...brandArr, key]);
        }
        if (!mobile) {
            setChanged(true);
            setPage(1);
        }
    };
    const handleColorChange = (key: string) => {
        if (colorArr.includes(key)) {
            setColor([...colorArr.slice(0, colorArr.indexOf(key)), ...colorArr.slice(colorArr.indexOf(key) + 1)]);
        } else {
            setColor([...colorArr, key]);
        }
        if (!mobile) {
            setChanged(true);
            setPage(1);
        }
    };

    const handleSizeClick = (value: string) => {
        if (sizeArr.includes(value)) {
            setSize([...sizeArr.slice(0, sizeArr.indexOf(value)), ...sizeArr.slice(sizeArr.indexOf(value) + 1)]);
        } else {
            setSize([...sizeArr, value]);
        }
        if (!mobile) {
            setChanged(true);
            setPage(1);
        }
    };

    const handleMinPriceChange = (value: string) => {
        if (+value || value === "") {
            setMinPrice(value);
            if (!mobile) {
                setChanged(true);
                setPage(1);
            }
        }
    };
    const handleMaxPriceChange = (value: string) => {
        if (+value || value === "") {
            setMaxPrice(value);
            if (!mobile) {
                setChanged(true);
                setPage(1);
            }
        }
    };

    const getGoods = useCallback(
        (newUrl: string) => {
            const gs = new GoodsService();
            gs.getFilteredBoots(decodeURI(newUrl))
                .then((res) => {
                    if (res.url === decodeURI(history.location.search)) {
                        setGoods(res.res.productResponseList);
                        setAmount(res.res.totalCount);
                    }
                })
                .catch(() => setPageError(true));
        },
        [history]
    );

    const changeUrl = useCallback(async () => {
        const sexString = sexArr[0] !== undefined ? `&sex=${sexArr.join(", ")}` : "&sex=";
        const brandString = brandArr[0] !== undefined ? `&brands=${brandArr.join(", ")}` : "&brands=";
        const colorString = colorArr[0] !== undefined ? `&colors=${colorArr.join(", ")}` : "&colors=";
        const sizeString = sizeArr[0] !== undefined ? `&sizes=${sizeArr.join(", ")}` : "&sizes=";
        const priceFrom = minPrice ? `&priceFrom=${minPrice}` : "";
        const priceTo = maxPrice ? `&priceTo=${maxPrice}` : "";
        const newUrl = `?itemsPerPage=12&pageNumber=${currentPage}&sortParameter=${sortWay}${sexString}${brandString}${colorString}${sizeString}${priceFrom}${priceTo}`;
        await setUrl(newUrl);
        history.push(`${history.location.pathname}${newUrl}`);
        setGoods(null);
        window.scrollTo(0, 0);
        getGoods(newUrl);
    }, [sexArr, brandArr, colorArr, sizeArr, minPrice, maxPrice, history, currentPage, sortWay, getGoods]);

    const handleFiltersReset = () => {
        setMaxPrice("");
        setSex([]);
        setSize([]);
        setBrand([]);
        setMinPrice("");
        setColor([]);
        setPage(1);
        setChanged(true);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (changed) {
                changeUrl();
                setChanged(false);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [changed, changeUrl]);

    useEffect(() => {
        if (search !== null) {
            const searchParams = new URLSearchParams(search);
            const urlSex = searchParams.get("sex");
            const urlSexArr = urlSex ? urlSex.split(", ") : [];
            if (urlSexArr !== null) setSex(urlSexArr);
            const urlBrand = searchParams.get("brands");
            const urlBrandArr = urlBrand ? urlBrand.split(", ") : [];
            if (urlBrandArr !== null) setBrand(urlBrandArr);
            const urlSizes = searchParams.get("sizes");
            const urlSizesArr = urlSizes ? urlSizes.split(", ") : [];
            if (urlSizesArr) setSize(urlSizesArr);
            const urlColors = searchParams.get("colors");
            const urlColorsArr = urlColors ? urlColors.split(", ") : [];
            if (urlColorsArr !== null) setColor(urlColorsArr);
            const urlPriceFrom = searchParams.get("priceFrom");
            const urlMinPrice = urlPriceFrom ?? "";
            if (urlMinPrice !== null) setMinPrice(urlMinPrice);
            const urlPriceTo = searchParams.get("priceTo");
            const urlMaxPrice = urlPriceTo ?? "";
            if (urlMaxPrice !== null) setMaxPrice(urlMaxPrice);
            const urlSortWay = searchParams.get("sortParameter");
            const urlSort = urlSortWay ?? "";
            setSortWay(urlSort);
            const urlPageNum = searchParams.get("pageNumber");
            const urlPage = urlPageNum ? +urlPageNum : 1;
            setPage(urlPage);
        }
    }, [search]);

    useEffect(() => {
        if (!url) {
            changeUrl();
        } else if (!goods && count < 1) {
            getGoods(url);
            setCount(count + 1);
        }
    }, [changeUrl, url, goods, getGoods, count]);

    const handleClick = (page: number) => {
        setPage(page);
        setGoods(null);
        setChanged(true);
        window.scrollTo(0, 0);
    };

    const values: FilterValue[] = [
        { key: "sex", value: sexArr, func: handleSexChange },
        { key: "brand", value: brandArr, func: handleBrandChange },
        { key: "minPrice", value: minPrice, func: handleMinPriceChange },
        { key: "maxPrice", value: maxPrice, func: handleMaxPriceChange },
        { key: "color", value: colorArr, func: handleColorChange },
        { key: "size", value: sizeArr, func: handleSizeClick },
    ];

    let content;
    if (pageError) {
        content = (
            <Typography mt="1.5rem" variant="h2">
                Что-то пошло не так
            </Typography>
        );
    } else if (!goods) {
        content = <Loader />;
    } else if (!goods[0]) {
        content = (
            <Typography mt="1.5rem" variant="h2">
                Пока пусто
            </Typography>
        );
    } else {
        content = (
            <Box width="100%" display="flex" flexDirection="column">
                <SortBootsPage goods={goods} sortWay={sortWay} amount={amount} handleSort={handleSort} />

                <Pagination
                    containerProps={{ py: "3.5rem" }}
                    count={Math.ceil(amount / 12)}
                    page={currentPage}
                    onClick={handleClick}
                />
            </Box>
        );
    }

    return (
        <Box display={mobile ? "block" : "flex"} gap="3rem" width="100%">
            <FilterBlock changeUrl={changeUrl} handleFiltersReset={handleFiltersReset} values={values} />
            {content}
        </Box>
    );
};
