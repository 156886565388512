import { Divider, Typography } from "@mui/material";
import React from "react";
import { PDInputType, DataInput } from "../DataInput/DataInput";
import s from "./InputBlock.module.scss";

export type DataType = {
    blockName?: string | ((email: string) => JSX.Element) | (() => JSX.Element);
    values: Array<PDInputType>;
    noDivider?: boolean;
    email?: string;
};

export const InputBlock: React.FC<DataType> = ({ blockName, values, noDivider, email }) => {
    let content = null;
    if (typeof blockName === "string") content = <Typography variant="h3">{blockName}</Typography>;
    else if (blockName !== undefined) content = blockName(email ?? "");
    return (
        <>
            {content}
            <div className={s.inputs}>
                {values.map((props) => (
                    <DataInput key={props.label} {...props} />
                ))}
            </div>
            {noDivider ? null : <Divider flexItem />}
        </>
    );
};
