import { Box, Typography } from "@mui/material";
import React from "react";

type Props = {
    way: string;
};

export const DeliveryWay: React.FC<Props> = ({ way }) => (
    <Box py="1.5rem">
        <Typography variant="body1" color="secondary">
            {way}
        </Typography>
    </Box>
);
