import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import BannerService from "../../services/BannerService";
import { BannerAdminServerType, BannerType } from "../../types/type";
import { Banner } from "../Banner/Banner";
import { Loader } from "../Loader/Loader";

type Props = unknown;

export const BannerWrapper: React.FC<Props> = () => {
    const [banners, setBanners] = useState<Array<BannerType> | null>(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!banners && !loading && !error) {
            const token = window.localStorage.getItem("jwt") ?? "";
            const bs = new BannerService();
            setLoading(true);
            bs.getAdminBanners(token)
                .then((res) => {
                    setBanners(
                        res.map((value: BannerAdminServerType) => ({
                            ...value,
                            mobileImage: value.mobileImage.imageUrl,
                            desktopImage: value.desktopImage.imageUrl,
                        }))
                    );
                    setError(false);
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        }
    }, [banners, error, loading]);

    if (error) {
        return <Typography variant="h3">Что-то пошло не так</Typography>;
    }
    if (banners === null) {
        return <Loader size={80} />;
    }

    const addBanner = (banner: BannerType) => {
        setBanners([banner, ...banners]);
    };

    const deleteBanner = (tid: number) => {
        const token = window.localStorage.getItem("jwt") ?? "";
        const bs = new BannerService();
        bs.deleteBanner(tid, token)
            .then(() => {
                const newBanners = banners.filter(({ id }) => id !== tid);
                setBanners(newBanners);
            })
            .catch((err) => console.log(err));
    };

    return (
        <Box display="flex" flexDirection="column" gap="1.5rem">
            <Banner creating addBanner={addBanner} />
            {banners.map((banner) => (
                <Banner key={banner.id} creating={false} {...banner} delBanner={deleteBanner} />
            ))}
        </Box>
    );
};
