import { Box, Button, Checkbox, FormControlLabel, FormGroup, FormLabel, TextField, Typography } from "@mui/material";
import { FormikErrors, useFormik } from "formik";
import React, { useState, useEffect } from "react";
import UserService from "../../../services/UserService";
import { colors } from "../../../theme";
import { Snack } from "../../snacks/Snack";
import s from "./RegisterForm.module.scss";

type Props = {
    onClick: () => void;
};

interface FormValues {
    name: string;
    email: string;
    password: string;
    password2: string;
    agreement: boolean;
    read: boolean;
}

const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};
    if (!values.name) {
        errors.name = "Обязательное поле";
    }

    if (!values.password) {
        errors.password = "Обязательное поле";
    }

    if (!values.email) {
        errors.email = "Обязательное поле";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = "Неверный адрес почты";
    }

    if (values.password2 !== values.password) {
        errors.password2 = "Пароли должны совпадать";
    }

    if (!values.agreement) {
        errors.agreement = "Обязательное поле";
    }

    if (!values.read) {
        errors.read = "Обязательное поле";
    }

    return errors;
};

export const RegisterForm: React.FC<Props> = ({ onClick, ...props }) => {
    const [validationWay, setValidationWay] = useState(false);
    const [open, setOpen] = useState(false);
    const formik = useFormik({
        initialValues: {
            email: "",
            name: "",
            password: "",
            password2: "",
            agreement: false,
            read: false,
        },
        validate,
        validateOnChange: validationWay,
        validateOnBlur: validationWay,
        onSubmit: (values) => {
            const formValues = {
                firstName: `${values.name}`,
                email: `${values.email}`,
                password: `${values.password}`,
            };

            const us = new UserService();
            us.postUser(formValues)
                .then((res) => {
                    window.localStorage.setItem("jwt", res.jwt);
                    window.localStorage.setItem("user-role", res.tokenPayload.roles.toString());
                    onClick();
                })
                .catch((error: Error) => {
                    if (error.message === "409") {
                        formik.setFieldError("email", "На этот e-mail уже зарегистрирован аккаунт");
                    } else {
                        setOpen(true);
                    }
                });
        },
    });

    useEffect(() => {
        if (formik.submitCount >= 1) setValidationWay(true);
    }, [formik.submitCount]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box display="flex" flexDirection="column" gap="2.5rem">
                <Box display="flex" flexDirection="column" gap="1.25rem">
                    <FormGroup className={s.formGroup}>
                        <FormLabel>Ваше имя</FormLabel>
                        <TextField
                            name="name"
                            fullWidth
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            helperText={formik.errors.name}
                            color={formik.errors.name ? "error" : "secondary"}
                        />
                    </FormGroup>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>Email</FormLabel>
                        <TextField
                            name="email"
                            type="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            helperText={formik.errors.email}
                            color={formik.errors.email ? "error" : "secondary"}
                        />
                    </FormGroup>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>Пароль</FormLabel>
                        <TextField
                            name="password"
                            type="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            helperText={formik.errors.password}
                            color={formik.errors.password ? "error" : "secondary"}
                        />
                    </FormGroup>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>Повторить пароль</FormLabel>
                        <TextField
                            name="password2"
                            type="password"
                            value={formik.values.password2}
                            onChange={formik.handleChange}
                            helperText={formik.errors.password2}
                            color={formik.errors.password2 ? "error" : "secondary"}
                        />
                    </FormGroup>
                </Box>
                <FormGroup>
                    <FormControlLabel
                        className={s.label}
                        control={<Checkbox color={formik.errors.read ? "error" : undefined} />}
                        name="read"
                        onChange={formik.handleChange}
                        checked={formik.values.read}
                        label={
                            <Typography variant="caption" color={formik.errors.read ? "error" : colors["800"]}>
                                При регистрации вы соглашаетесь со всеми пунктами документов «Условия пользования» и
                                «Политика конфиденциальности».
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        className={s.label}
                        control={<Checkbox color={formik.errors.agreement ? "error" : undefined} />}
                        name="agreement"
                        onChange={formik.handleChange}
                        checked={formik.values.agreement}
                        label={
                            <Typography variant="caption" color={formik.errors.agreement ? "error" : colors["800"]}>
                                Согласен на обработку персональных данных
                            </Typography>
                        }
                    />
                </FormGroup>
                <Button fullWidth {...props} variant="contained" type="submit">
                    Создать аккаунт
                </Button>
            </Box>
            <Snack
                open={open}
                handleClose={() => setOpen(false)}
                message={<Typography color="primary">Что-то пошло не так</Typography>}
            />
        </form>
    );
};
