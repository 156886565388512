import React, { useState } from "react";
import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    FormLabel,
    TextField,
    Typography,
} from "@mui/material";
import clsx from "clsx";
import { useRequest } from "../../hooks/hooks";
import EnumsService from "../../services/EnumsServices";
import { CheckBoxFilterProps } from "../../types/type";
import TransformTypes from "../../utils/typeTransformers/TransformTypes";
import { ReactComponent as Reset } from "../../assets/Retry-refresh.svg";
import { ReactComponent as Options } from "../../assets/Filter.svg";
import { Loader } from "../Loader/Loader";
import { CheckButton } from "../CheckButton/CheckButton";
import { Snack } from "../snacks/Snack";
import s from "../FilterBlock/FilterBlock.module.scss";

export type FiltersProps = {
    values: FilterValue[];
    changeUrl: () => void;
    handleFiltersReset: () => void;
};

export type FilterValue = {
    key: string;
    value: Array<string> | string;
    func: (e: string) => void;
};

type Props = {
    mobile?: boolean;
} & FiltersProps;

export const Filters: React.FC<Props> = ({ mobile, ...props }) => {
    const [open, setOpen] = useState(false);
    const es = new EnumsService();
    const tt = new TransformTypes();
    const [sexFilter, errorSex] = useRequest<CheckBoxFilterProps>(es.getSex, tt.valueToCheckBox);
    const [colorFilter, errorColor] = useRequest<CheckBoxFilterProps>(es.getColors, tt.valueToCheckBox);
    const [brandFilter, errorBrand] = useRequest<CheckBoxFilterProps>(es.getBrands, tt.valueToCheckBox);
    const [sizeFilter, errorSize] = useRequest<CheckBoxFilterProps>(es.getSizes, tt.sizeToCheckBox);

    const { changeUrl, handleFiltersReset, values } = { ...props };
    const sex = values.filter(({ key }) => key === "sex")[0];
    const brand = values.filter(({ key }) => key === "brand")[0];
    const minPrice = values.filter(({ key }) => key === "minPrice")[0];
    const maxPrice = values.filter(({ key }) => key === "maxPrice")[0];
    const color = values.filter(({ key }) => key === "color")[0];
    const size = values.filter(({ key }) => key === "size")[0];

    const handleButtonClick = () => {
        changeUrl();
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const message = (
        <>
            <Options />
            <Typography color="primary" variant="h3">
                Фильтр применен
            </Typography>
        </>
    );

    if (errorBrand || errorColor || errorSex || errorSize) {
        return (
            <div
                className={clsx({
                    [s.filterBlock]: true,
                    [s.filterBlock__mobile]: mobile,
                })}>
                <Typography variant="h3">Что-то пошло не так</Typography>
            </div>
        );
    }

    if (!sexFilter || !brandFilter || !sizeFilter || !colorFilter) {
        return (
            <div
                className={clsx({
                    [s.filterBlock]: true,
                    [s.filterBlock__mobile]: mobile,
                })}>
                <Loader size={80} />
            </div>
        );
    }

    return (
        <div
            className={clsx({
                [s.filterBlock]: true,
                [s.filterBlock__mobile]: mobile,
            })}>
            <FormLabel>Кроссовки</FormLabel>
            <FormGroup onChange={(e: React.ChangeEvent<HTMLInputElement>) => sex.func(e.target.value)}>
                {sexFilter.map(({ key, value }) => (
                    <FormControlLabel
                        key={key}
                        value={key}
                        checked={sex.value.includes(key)}
                        control={<Checkbox />}
                        label={value}
                    />
                ))}
            </FormGroup>
            <Divider />
            <FormLabel>Бренд</FormLabel>
            <FormGroup
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => brand.func(e.target.value)}
                className={s.formGroup_overflow}>
                {brandFilter.map((brandval) => (
                    <FormControlLabel
                        key={brandval.key}
                        value={brandval.key}
                        checked={brand.value.includes(brandval.key)}
                        control={<Checkbox />}
                        label={brandval.value}
                    />
                ))}
            </FormGroup>
            <Divider />
            <FormLabel className={s.formLabel}>Цена</FormLabel>
            <FormGroup row>
                <TextField
                    className={s.textField}
                    value={minPrice.value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => minPrice.func(e.target.value)}
                    placeholder="от"
                />
                <TextField
                    className={s.textField}
                    value={maxPrice.value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => maxPrice.func(e.target.value)}
                    placeholder="до"
                />
            </FormGroup>
            <Divider />
            <FormLabel>Цвет</FormLabel>
            <FormGroup
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => color.func(e.target.value)}
                className={s.formGroup_overflow}>
                {colorFilter.map(({ value, key }) => (
                    <FormControlLabel
                        key={key}
                        value={key}
                        control={<Checkbox />}
                        label={value}
                        checked={color.value.includes(key)}
                    />
                ))}
            </FormGroup>
            <Divider />
            <FormLabel>Размер</FormLabel>
            <FormGroup row className={s.formGroup_sizesOverflow}>
                {sizeFilter.map(({ value, key }) => (
                    <CheckButton
                        value={value}
                        size
                        key={key}
                        onClick={() => size.func(`${value}`)}
                        checked={size.value.includes(`${value}`)}
                    />
                ))}
            </FormGroup>
            <div className={s.buttonGroup}>
                <Button
                    variant="contained"
                    className={!mobile ? s.hidden : ""}
                    fullWidth={mobile}
                    size={mobile ? "large" : "medium"}
                    onClick={handleButtonClick}>
                    Применить
                </Button>
                <Button
                    variant="outlined"
                    fullWidth={mobile}
                    onClick={handleFiltersReset}
                    size={mobile ? "large" : "medium"}
                    startIcon={<Reset />}>
                    Сбросить
                </Button>
            </div>
            <Snack open={open} handleClose={handleClose} message={message} />
        </div>
    );
};
