import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GoodsService from "../../services/GoodsService";
import { colors } from "../../theme";
import { Product } from "../../types/type";
import TransformTypes from "../../utils/typeTransformers/TransformTypes";
import { Loader } from "../Loader/Loader";
import s from "./ProductWrapper.module.scss";

type Props = {
    url: string;
    allGoods?: Array<Product>;
};

export const ProductWrapper: React.FC<Props> = ({ url, allGoods }) => {
    const history = useHistory();
    const [goods, setGoods] = useState(allGoods);
    const [loadingGoods, setLoadingGoods] = useState(false);
    const [errorGoods, setErrorGoods] = useState(false);

    useEffect(() => {
        if (!goods && !loadingGoods && !errorGoods) {
            const gs = new GoodsService();
            const tt = new TransformTypes();
            const token = window.localStorage.getItem("jwt") ?? "";
            setLoadingGoods(true);
            gs.getAdminGoods(token)
                .then((res) => tt.goodsToProducts(res))
                .then((res) => {
                    setGoods(res);
                    setErrorGoods(false);
                })
                .catch(() => setErrorGoods(true))
                .finally(() => setLoadingGoods(false));
        }
    }, [goods, loadingGoods, errorGoods]);

    if (errorGoods) {
        return <Typography variant="h2">Что-то пошло не так</Typography>;
    }

    if (!goods) {
        return <Loader />;
    }

    return (
        <div>
            {goods.map(({ id, brand, modelName, mainImage, article, values, price }, ind) => (
                <Box display="flex" key={id} flexDirection="column" gap="1.5rem" mb="1.5rem">
                    {ind === 0 ? null : <Divider flexItem />}

                    <Box className={s.productWrapper} key={id} onClick={() => history.push(`${url}/${id}`)}>
                        <Box display="flex" gap="1.5rem" alignItems="center" flexGrow={1}>
                            <Box
                                className={s.productImg}
                                sx={{
                                    background: `url(${mainImage.imageUrl}) center/cover no-repeat, ${colors.white}`,
                                }}
                            />
                            <Box display="flex" flexDirection="column" gap="0.25rem" flexGrow={1}>
                                <Typography variant="caption" color={colors["700"]}>
                                    Артикул:{" "}
                                    <Typography variant="caption" color="primary">
                                        {article}
                                    </Typography>
                                </Typography>
                                <Typography variant="body1" color="primary" className={s.name}>
                                    {modelName}
                                </Typography>
                                <Typography variant="caption" color={colors["500"]}>
                                    {brand}
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" gap="4rem" alignItems="center">
                            {values?.map(({ key, value }) => (
                                <div key={`${key}_${value}`}>
                                    {key ? (
                                        <Typography variant="caption" color={colors["500"]}>
                                            {key}
                                        </Typography>
                                    ) : null}

                                    <Typography variant="subtitle2" color="primary" className={s.string}>
                                        {typeof value === "string" ? value : value.join(", ")}
                                    </Typography>
                                </div>
                            ))}
                            {price ? <Typography variant="subtitle1">{price}</Typography> : null}
                        </Box>
                    </Box>
                </Box>
            ))}
        </div>
    );
};
