export default class BannerService {
    apiBase = `${process.env.REACT_APP_SITE_URL}/rest/banners`;

    getAdminBanners = async (token: string) => {
        const res = await fetch(encodeURI(`${this.apiBase}/admin`), {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (!res.ok) {
            throw new Error(`Error ${res.status} ${res.statusText} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };

    getUserBanners = async () => {
        const res = await fetch(encodeURI(`${this.apiBase}/user`), {
            method: "GET",
        });

        if (!res.ok) {
            throw new Error(`Error ${res.status} ${res.statusText} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };

    createBanner = async (formData: FormData, token: string) => {
        const res = await fetch(encodeURI(`${this.apiBase}`), {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        });

        if (!res.ok) {
            throw new Error(`Error ${res.status} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };

    changeBanner = async (formData: FormData, token: string) => {
        const res = await fetch(encodeURI(`${this.apiBase}`), {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        });

        if (!res.ok) {
            throw new Error(`Error ${res.status} acquired while fetching ${res.url}`);
        }

        const result = await res.text();
        return result;
    };

    deleteBanner = async (id: number, token: string) => {
        const res = await fetch(encodeURI(`${this.apiBase}/${id}`), {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (!res.ok) {
            throw new Error(`Error ${res.status} acquired while fetching ${res.url}`);
        }

        return res;
    };
}
