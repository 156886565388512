class BadgeClass {
    amount: number;

    constructor(amount?: number) {
        if (amount) {
            this.amount = amount;
        } else {
            const am = window.localStorage.getItem("amount-to-get") ?? "0";
            this.amount = +am;
        }
    }

    plus(add: number) {
        const amount = this.amount + add;
        if (amount < 0) {
            this.amount = 0;
        } else {
            this.amount = amount;
        }
        this.reload();
    }

    set(amount: number) {
        if (amount < 0) {
            this.amount = 0;
        } else {
            this.amount = amount;
        }
        this.reload();
    }

    plusOpen(add: number) {
        const amount = this.amount + add;
        if (amount < 0) {
            this.amount = 0;
        } else {
            this.amount = amount;
        }
        const close: HTMLDivElement | null = document.querySelector("#close-shop-bag");
        if (close) close.click();
        const open: HTMLButtonElement | null = document.querySelector("button#open-shop-bag");
        if (open) open.click();
        window.localStorage.setItem("amount-to-get", `${this.amount}`);
    }

    private reload() {
        const close: HTMLDivElement | null = document.querySelector("#close-shop-bag");
        if (close) close.style.display = "none";
        const open: HTMLButtonElement | null = document.querySelector("button#open-shop-bag");
        setTimeout(() => {
            if (open) open.click();
            const shopBag: HTMLDivElement | null = document.querySelector("#shop-bag");
            if (shopBag) shopBag.style.display = "none";
        });
        setTimeout(() => {
            if (close) {
                close.click();
                close.style.display = "";
            }
        }, 0.2);
        window.localStorage.setItem("amount-to-get", `${this.amount}`);
    }
}

export const badgeAmount = new BadgeClass();
