import { MenuItem, TextField, TextFieldProps, Typography, SelectProps } from "@mui/material";
import React from "react";
import { SelectOptions } from "../../types/type";

type Props = { options: SelectOptions[]; selectProps?: SelectProps } & TextFieldProps;

type PlaceholderProps = { label?: string };

const Placeholder: React.FC<PlaceholderProps> = ({ label }) => {
    if (!label) return null;
    return (
        <Typography variant="subtitle2" color="secondary">
            {label}
        </Typography>
    );
};

export const SelectOption: React.FC<Props> = ({ options, selectProps, ...props }) => {
    return (
        <TextField
            {...props}
            select
            value={props.value === "none" ? "" : props.value}
            SelectProps={{
                renderValue: props.value !== "none" ? undefined : () => <Placeholder label={props.placeholder} />,
                displayEmpty: props.value === "none",
                ...selectProps,
            }}>
            {options.map((option) => (
                <MenuItem key={option.id} value={option.id} selected={option.id === props.value}>
                    <Typography variant="subtitle2">{option.value}</Typography>
                </MenuItem>
            ))}
        </TextField>
    );
};
