import { Divider, List, ListItem, Typography } from "@mui/material";
import React from "react";
import s from "./LineList.module.scss";
import { colors as themeColors } from "../../theme";

type Props = {
    values: ValuesProps[];
    light?: false;
};

type ValuesProps = {
    key: React.ReactNode;
    value: React.ReactNode;
};

export const LineList: React.FC<Props> = ({ values, light }) => {
    return (
        <List disablePadding className={s.list}>
            {values.map(({ key, value }) => (
                <ListItem disableGutters key={`${key}_${value}`}>
                    <Typography variant="subtitle2" component="span" color={themeColors["700"]}>
                        {key}
                    </Typography>
                    <Divider light={light === undefined} />
                    <Typography variant="subtitle2" component="span" color="primary" className={s.value}>
                        {value}
                    </Typography>
                </ListItem>
            ))}
        </List>
    );
};
