import React from "react";
import { Route, Switch } from "react-router-dom";
import { url } from "../constants/constants";
import { Shop } from "../pages/shop/Shop";
import { Error } from "../pages/error/Error";
import { Main } from "../pages/main/Main";
import { Product } from "../pages/product/Product";
import { PersonalAccount } from "../pages/personalAccount/PersonalAccount";
import { EmailEnter } from "../pages/resetPassword/EmailEnter";
import { OrderCreation } from "../pages/createOrder/OrderCreation";
import { SearchPage } from "../pages/search/SearchPage";
import { CreateProduct } from "../pages/createProduct/CreateProduct";
import { DeliveryPayment } from "../pages/deliveryPayment/DeliveryPayment";
import { Contacts } from "../pages/contacts/Contacts";
import { PersonalOrder } from "../pages/PersonalOrder/PersonalOrder";
import { RestorePassword } from "../pages/resetPassword/RestorePassword";

type Props = unknown;

export const PageRouter: React.FC<Props> = () => {
    return (
        <Switch>
            <Route path={`${url.shop}/:id`} render={() => <Product />} />
            <Route path={url.shop} render={() => <Shop />} />
            <Route path={url.contacts} render={() => <Contacts />} />
            <Route path={url.delivery} render={() => <DeliveryPayment />} />
            <Route path={url.emailEnter} render={() => <EmailEnter />} />
            <Route path={url.restorePassword} render={() => <RestorePassword />} />
            <Route path={url.personalAccount} render={() => <PersonalAccount />} />
            <Route path={url.createOrder} render={() => <OrderCreation />} />
            <Route path={url.createProduct} render={() => <CreateProduct />} />
            <Route path={url.search} render={() => <SearchPage />} />
            <Route path={url.personalOrder} render={() => <PersonalOrder />} />
            <Route path={url.main} exact render={() => <Main />} />

            <Route path="*" render={() => <Error />} />
        </Switch>
    );
};
