import React from "react";
import { Box, BoxProps, Button, IconButton, List } from "@mui/material";
import usePagination from "@mui/material/usePagination/usePagination";
import clsx from "clsx";
import s from "./Pagination.module.scss";
import { ReactComponent as Next } from "../../assets/Arrow-Right.svg";
import { ReactComponent as Prev } from "../../assets/Arrow-Left.svg";

type Props = { hidden?: boolean; page: number; count: number; onClick: (p: number) => void; containerProps?: BoxProps };

export const Pagination: React.FC<Props> = ({ hidden, page, count, onClick, containerProps }) => {
    const { items } = usePagination((() => ({ count, page, siblingCount: 0, boundaryCount: 1 }))());

    if (hidden) return null;
    return (
        <Box component="nav" width="100%" mt="auto" {...containerProps}>
            <List className={s.pages}>
                {items.map(({ page: p, type, selected, ...item }) => {
                    let children;

                    if (type === "start-ellipsis" || type === "end-ellipsis") {
                        children = "…";
                    } else if (type === "page") {
                        children = (
                            <Button
                                variant={selected ? "contained" : "text"}
                                {...item}
                                size="small"
                                onClick={() => onClick(p)}>
                                {p}
                            </Button>
                        );
                    } else {
                        children = (
                            <Box className={s.arrows} onClick={() => (!item.disabled ? onClick(p) : null)}>
                                <IconButton {...item}>{type === "next" ? <Next /> : <Prev />}</IconButton>
                            </Box>
                        );
                        return (
                            <li
                                key={`${p}_${type}`}
                                className={clsx({
                                    [s.arrows__left]: type !== "next",
                                    [s.arrows__right]: type === "next",
                                })}>
                                {children}
                            </li>
                        );
                    }
                    return <li key={`${p}_${type}`}>{children}</li>;
                })}
            </List>
        </Box>
    );
};
