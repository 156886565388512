import React, { useEffect, useState } from "react";
import { Button, FormLabel, Typography, Box, FormGroup, useMediaQuery, Dialog, IconButton } from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import clsx from "clsx";
import s from "./Product.module.scss";
import { deliveryText, returnText, url } from "../../constants/constants";
import { colors as themeColors } from "../../theme";
import { CheckButton } from "../../components/CheckButton/CheckButton";
import { ReactComponent as ShopBag } from "../../assets/Shop-bag.svg";
import { LineList } from "../../components/LineList/LineList";
import { PathString, PathStringProps } from "../../components/PathString/PathString";
import { Slider } from "../../components/Slider/Slider";
import { ContentTabs } from "../../components/tabs/ContentTabs/ContentTabs";
import { SizeTable } from "../../components/SizeTable/SizeTable";
import { ReactComponent as Close } from "../../assets/Close.svg";
import { ProductInfo } from "../../types/type";
import GoodsService from "../../services/GoodsService";
import { Error } from "../error/Error";
import { Loader } from "../../components/Loader/Loader";
import { badgeAmount } from "../../utils/BadgeClass";
import { WhyCheaper } from "../../components/WhyCheaper/WhyCheaper";

type Props = unknown;

export const Product: React.FC<Props> = () => {
    const location = useLocation();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [product, setProduct] = useState<ProductInfo>();
    const [loadingProduct, setLoadingProduct] = useState(false);
    const [error, setError] = useState(false);
    const smallPC = useMediaQuery("(max-width: 1000px)");
    const tablet = useMediaQuery("(max-width: 800px");
    const [message, setMessage] = useState("Товар добавлен в корзину");
    const id = +location.pathname.split(`${url.shop}/`)[1];
    useEffect(() => {
        if (!product && !loadingProduct && !error) {
            const gs = new GoodsService();
            setLoadingProduct(true);
            gs.getBoot(id)
                .then((res) => {
                    setProduct(res);
                })
                .catch(() => {
                    setError(true);
                })
                .finally(() => setLoadingProduct(false));
        }
    }, [product, id, loadingProduct, error]);

    const { modelName, article, manufacturerCountry, brand, colors, description, material, lining, top, mainImage } =
        product ?? {};
    const sizes = product?.sizes.filter(({ amount }) => +amount > 0);
    const [check, setCheck] = useState(0);
    const imgs = product?.images ?? [];
    const images = mainImage ? [mainImage, ...imgs] : imgs;

    if (error) {
        return <Error />;
    }
    if (!product) {
        return <Loader />;
    }

    const handleClick = (index: number) => setCheck(index);
    const handleAddClick = () => {
        badgeAmount.plus(1);
        setOpen(true);
        const pr = window.localStorage.getItem(`shop-bag-product-${id}`);
        const savedProduct = pr ? JSON.parse(pr) : null;
        let savingProduct;
        if (savedProduct) {
            const newSizes: number[] = savedProduct.sizes;
            if (newSizes.indexOf(sizes ? +sizes[check].size : 0) === -1 && sizes) {
                newSizes.push(+sizes[check].size);
            }
            savingProduct = { ...savedProduct, sizes: newSizes };
            if (sizes) {
                const sizePr = window.localStorage.getItem(`shop-bag-product-${id}-size-${sizes[check].size}`);
                const newSizePr = sizePr ? JSON.parse(sizePr) : { size: sizes[check], amount: 0 };
                window.localStorage.setItem(
                    `shop-bag-product-${id}-size-${sizes[check].size}`,
                    JSON.stringify({ ...newSizePr, amount: newSizePr.amount + 1 })
                );
                if (+newSizePr.amount + 1 > +sizes[check].amount) {
                    setMessage(
                        `В магазине сейчас нет необходимого вам количества кроссовок, в вашем заказе будет максимально доступное число.`
                    );
                    badgeAmount.plus(-1);
                } else {
                    setMessage("Товар добавлен в корзину");
                }
            }
        } else {
            savingProduct = {
                brand,
                modelName,
                colors,
                sizes: sizes ? [+sizes[check].size] : [],
                mainImage: images ? images[0] : "",
            };
            if (sizes) {
                const newSizePr = { size: sizes[check], amount: 1 };
                window.localStorage.setItem(
                    `shop-bag-product-${id}-size-${sizes[check].size}`,
                    JSON.stringify({ ...newSizePr })
                );
                setMessage("Товар добавлен в корзину");
            }
        }
        window.localStorage.setItem(`shop-bag-product-${id}`, JSON.stringify(savingProduct));
        const ids = window.localStorage.getItem("shop-bag-ids") ?? "[]";
        const indexes: [number] = JSON.parse(ids);
        if (indexes.indexOf(id) === -1) {
            indexes.push(id);
        }
        window.localStorage.setItem("shop-bag-ids", JSON.stringify(indexes));
    };
    const handleToShopBagClick = () => {
        setOpen(false);
        history.push(url.createOrder);
    };
    const handleReturnClick = () => setOpen(false);

    const lineListValues = [
        {
            key: "Бренд",
            value: brand,
        },
        {
            key: "Страна производитель",
            value: manufacturerCountry,
        },
        {
            key: "Цвет",
            value: colors?.join(", "),
        },
    ];
    const pathStringValues: PathStringProps["values"] = [
        {
            value: (
                <Typography variant="caption" color={themeColors["500"]}>
                    Главная
                </Typography>
            ),
            link: url.main,
        },
        {
            value: (
                <Typography variant="caption" color={themeColors["500"]}>
                    Кроссовки
                </Typography>
            ),
            link: url.shop,
        },
        {
            value: (
                <Typography variant="caption" color={themeColors["500"]}>
                    {brand ?? ""}
                </Typography>
            ),
            link: `${url.shop}?itemsPerPage=15&pageNumber=1&sortParameter=PRIORITY&sex=&brands=${brand}&colors=&sizes=`,
        },
        {
            value: modelName ?? "",
        },
    ];
    const descrLines = [
        {
            key: "Верх",
            value: top,
        },
        {
            key: "Подкладка",
            value: lining,
        },
        {
            key: "Материал",
            value: material,
        },
    ];
    const tabsValues: { id: number; value: string; Content: ({ ...props }) => JSX.Element }[] = [
        {
            id: 0,
            value: "Описание",
            Content: () => (
                <>
                    <Box mt={smallPC ? "unset" : "-1.125rem"} mb={smallPC ? "unset" : "0.375rem"}>
                        <Typography variant="subtitle2" component="p" color="primary">
                            {description}
                        </Typography>
                    </Box>
                    <LineList values={descrLines} />
                </>
            ),
        },
        {
            id: 1,
            value: "Доставка и оплата",
            Content: () => deliveryText,
        },
        {
            id: 2,
            value: "Возврат",
            Content: () => returnText,
        },
    ];

    return (
        <Box width="100%">
            <PathString values={pathStringValues} />

            <div className={clsx({ [s.container]: true, [s.container__wrap]: tablet })}>
                <div className={clsx({ [s.slider]: !tablet, [s.container_full]: tablet })}>
                    <Slider imgs={images ?? []} id={0} />
                </div>

                <div className={clsx({ [s.info]: !tablet, [s.container_full]: tablet })}>
                    <Typography variant="h1" color="primary">
                        {modelName}
                    </Typography>

                    <div className={s.article}>
                        <Typography variant="subtitle2" component="span" color={themeColors["500"]}>
                            Артикул:
                        </Typography>
                        <Typography variant="subtitle2" component="span" color="primary">
                            {` ${article}`}
                        </Typography>
                    </div>

                    <LineList values={lineListValues} />

                    <div className={s.optionsBlock}>
                        <FormGroup row>
                            <FormLabel>
                                <Box display="flex" justifyContent="space-between">
                                    Размер
                                    <SizeTable />
                                </Box>
                            </FormLabel>
                            {sizes?.map(({ size }, ind) => (
                                <CheckButton
                                    key={size}
                                    size
                                    onClick={() => handleClick(ind)}
                                    value={size}
                                    checked={ind === check}
                                />
                            ))}
                        </FormGroup>

                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            gap="0.5rem"
                            flexWrap={tablet ? "wrap" : undefined}>
                            <div className={s.price}>
                                <Typography variant="body1" color={themeColors["500"]}>
                                    <s>
                                        {sizes
                                            ?.filter(({ amount }) => +amount > 0)
                                            .filter((size, ind) => ind === check)[0]?.cardPrice ?? 0}{" "}
                                        ₽
                                    </s>
                                </Typography>
                                <Typography variant="h2">
                                    {sizes?.filter(({ amount }) => +amount > 0).filter((size, ind) => ind === check)[0]
                                        ?.price ?? 0}{" "}
                                    ₽
                                </Typography>
                            </div>
                            <WhyCheaper />
                        </Box>

                        <Button
                            variant="contained"
                            fullWidth
                            disabled={!sizes}
                            size="large"
                            startIcon={<ShopBag />}
                            onClick={sizes ? handleAddClick : undefined}>
                            В корзину
                        </Button>
                    </div>

                    {smallPC ? (
                        <Box display="flex" flexDirection="column" gap="1rem" mb="3rem">
                            {tabsValues.map(({ value, Content }) => (
                                <Box key={value} display="flex" flexDirection="column" gap="1rem">
                                    <Typography variant="h3">{value}</Typography>
                                    <Content />
                                </Box>
                            ))}
                        </Box>
                    ) : (
                        <ContentTabs tabKey="product_tabs" tabs={tabsValues} />
                    )}
                </div>
            </div>
            <Dialog open={open} onClose={handleReturnClick}>
                <Box p="3.5rem 2rem 2rem 2rem" display="flex" flexDirection="column" gap="2rem" alignItems="center">
                    <Box position="absolute" top="1rem" right="1rem">
                        <IconButton size="small" onClick={handleReturnClick}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Typography variant="h3" color="primary">
                        {message}
                    </Typography>
                    <Box display="flex" gap="1.5rem">
                        <Button variant="outlined" onClick={handleReturnClick}>
                            Вернуться к покупкам
                        </Button>
                        <Button variant="contained" onClick={handleToShopBagClick}>
                            Перейти в корзину
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </Box>
    );
};
