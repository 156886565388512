import {
    Card,
    CardMedia,
    Divider,
    Typography,
    CardActionArea,
    CardContent,
    CardActionAreaProps,
    useMediaQuery,
} from "@mui/material";
import React from "react";
import clsx from "clsx";
import { MainPageADCardProps } from "../../../types/type";
import { ReactComponent as ArrowRight } from "../../../assets/Arrow-Right.svg";
import { colors } from "../../../theme";

export const ADCard: React.FC<MainPageADCardProps & CardActionAreaProps> = ({ img, value, urlKey, ...props }) => {
    const mobile = useMediaQuery("(max-width: 400px)");
    const tablet = useMediaQuery("(max-width: 600px)");

    return (
        <Card>
            <CardActionArea {...props}>
                <CardMedia
                    component="img"
                    height={clsx({ "525px": true, "193": mobile, "250": tablet })}
                    image={img}
                    alt={`sport shoes image - ${img}`}
                />
                <CardContent>
                    <Typography variant="h3" color="primary">
                        {value}
                    </Typography>
                    <ArrowRight color={colors["700"]} />
                </CardContent>
                <Divider />
            </CardActionArea>
        </Card>
    );
};
