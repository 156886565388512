import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import clsx from "clsx";

type Props = unknown;

type BlocksType = {
    blockHeader: string;
    blockList: Array<BlockListValueProps>;
};

type ValueIcon = {
    value: string | JSX.Element;
    noIcon?: boolean;
};

type BlockListValueProps = {
    blockName: string;
    list: Array<ValueIcon>;
};

const blocks: Array<BlocksType> = [
    {
        blockHeader: "Доставка",
        blockList: [
            {
                blockName: "Курьером",
                list: [
                    { value: "Доставка осуществляется в пределах МКАД" },
                    { value: "Срок доставки 1-3 дня с момента оформления заказа" },
                    { value: "Примерка возможна в течение 15 минут" },
                    { value: "Стандартная стоимость доставки - 500 рублей" },
                    { value: "Бесплатная доставка при покупке от 25 000 рублей" },
                    { value: "Доставка оплачивается отдельно, если заказ не подошёл" },
                ],
            },
            {
                blockName: "Курьером СДЭК",
                list: [
                    { value: "Доставка осуществляется в пределах Московской области" },
                    { value: "Срок доставки ориентировочно 3-4 рабочих дня с момента оформления заказа" },
                    { value: "Примерка возможна в течение 15 минут" },
                    { value: "Стоимость доставки — 750 рублей вне зависимости от суммы покупки" },
                ],
            },
            {
                blockName: "СДЭК",
                list: [
                    { value: "Доставка осуществляется по Москве, Московской области и по России" },
                    {
                        value: "Срок доставки ориентировочно 2-5 рабочих дней с момента отправления заказа (срок отправки заказа 1-2 дня с момента оформления заказа)",
                    },
                    { value: "Услуга примерки отсутствует" },
                    { value: "Возможность отслеживания посылки по трек-номеру" },
                    { value: "Стоимость доставки — 790 рублей" },
                ],
            },
            {
                blockName: "Самовывоз",
                list: [
                    {
                        value: (
                            <>
                                Вы можете самостоятельно забрать заказ из нашего магазина, предварительно оформив резерв
                                через сайт или по телефону. Адрес магазина: Москва, Огородный проезд, д.10 (Торговый
                                центр &quot;Зеленый&quot;), 1 минута от станции метро &quot;Бутырская&quot;
                            </>
                        ),
                        noIcon: true,
                    },
                ],
            },
        ],
    },
    {
        blockHeader: "Способы оплаты",
        blockList: [
            {
                blockName: "Наличные средства",
                list: [
                    {
                        value: "Оплата заказа производится наличными в магазине или курьеру после получения и примерки товара",
                    },
                ],
            },
            {
                blockName: "Банковская карта",
                list: [
                    {
                        value: "В магазине, на сайте, а также при курьерской доставке по Москве мы принимаем банковские карты платежных систем Visa (включая Electron), MasterCard, Maestro и МИР",
                    },
                    {
                        value: "Если вы хотите оплатить заказ картой, обязательно скажите об этом оператору — он попросит курьера взять терминал",
                    },
                ],
            },
        ],
    },
];

export const DeliveryPayment: React.FC<Props> = () => {
    const smallPc = useMediaQuery("(max-width: 1000px)");
    const mobile = useMediaQuery("(max-width: 600px)");
    return (
        <Box mt={smallPc ? "-1.5rem" : "-1.875rem"} mb={mobile ? "3.75rem" : "102px"}>
            {blocks.map(({ blockHeader, blockList }) => {
                return (
                    <Box key={blockHeader} mt={smallPc ? "3rem" : "100px"}>
                        <Typography
                            variant="h1"
                            component="h2"
                            ml={smallPc ? undefined : "160px"}
                            mb={smallPc ? "1.5rem" : "2.625rem"}
                            align={smallPc && !mobile ? "center" : "left"}>
                            {blockHeader}
                        </Typography>

                        {blockList.map(({ blockName, list }) => (
                            <div key={blockName}>
                                <Divider />
                                <Box
                                    display="flex"
                                    width="100%"
                                    mt="1.5rem"
                                    mb="1.5rem"
                                    gap="120px"
                                    rowGap="1.5rem"
                                    flexDirection={smallPc ? "column" : undefined}>
                                    <Typography
                                        variant="h2"
                                        align="left"
                                        ml={smallPc ? undefined : "163px"}
                                        minWidth={clsx({ "334px": !smallPc, undefined: smallPc })}>
                                        {blockName}
                                    </Typography>
                                    <List>
                                        {list.map((v, ind) => (
                                            <ListItem
                                                disablePadding
                                                alignItems="flex-start"
                                                key={`${blockName}_${v.value.toString().slice(0, 2)}_${ind + 1}`}>
                                                {!v.noIcon && <ListItemIcon>&mdash;</ListItemIcon>}
                                                <ListItemText
                                                    primary={<Typography variant="body1">{v.value}</Typography>}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            </div>
                        ))}
                    </Box>
                );
            })}
            <Box display="none" sx={{ background: "url(./Logo.png)" }} />
            <Box display="none" sx={{ background: "url(./instagram@2x.png)" }} />
        </Box>
    );
};
