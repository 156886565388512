import { Box, Dialog, Divider, Drawer, Grid, IconButton, Typography, useMediaQuery } from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { colors } from "../../../theme";
import s from "./ShortOrder.module.scss";
import { LineList } from "../../LineList/LineList";
import { ReactComponent as Close } from "../../../assets/Close.svg";
import { FullOrder } from "../FullOrder/FullOrder";
import { OrderProps } from "../../../types/type";
import { url, UserEnum } from "../../../constants/constants";

type Props = {
    userType: string;
    change: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
} & OrderProps;

export const ShortOrder: React.FC<Props> = React.memo(
    ({
        orderNumber,
        orderDate,
        orderTime,
        address,
        person,
        price,
        orderType,
        userType,
        userOrderStatus,
        adminOrderStatus,
        paymentStatus,
        change,
    }) => {
        const smallPC = useMediaQuery("(max-width: 1000px)");
        const tablet = useMediaQuery("(max-width: 800px)");
        const mobile = useMediaQuery("(max-width: 600px)");
        const history = useHistory();
        const [open, setOpen] = useState(false);

        const personalId = history.location.pathname.split(url.personalAccount);

        const handleClose = () => {
            setOpen(false);
            history.push(`${url.personalAccount}${personalId[1]}`);
        };

        let content = (
            <div className={clsx({ [s.order]: true, [s.order__small]: smallPC })}>
                <Typography variant="caption" color="primary">
                    {userOrderStatus}
                </Typography>
            </div>
        );

        if (userType === UserEnum.admin) {
            content = (
                <>
                    <Grid className={s.centered} item md={1} xs={6}>
                        <Typography variant="subtitle2" color="primary">
                            {paymentStatus}
                        </Typography>
                    </Grid>
                    <Grid className={mobile ? s.price : s.centered} item md={2} xs={6}>
                        <Typography variant="subtitle2" color="primary">
                            {adminOrderStatus}
                        </Typography>
                    </Grid>
                </>
            );
        }

        return (
            <>
                <Box
                    className={s.container}
                    gap={mobile ? "0.75rem" : "2rem"}
                    onClick={() => {
                        setOpen(true);
                        history.push(`${url.personalAccount}${personalId[1]}?order=${orderNumber}`);
                    }}>
                    <Grid container spacing={mobile || userType === UserEnum.admin ? "0.75rem" : "2rem"}>
                        <Grid item md={2} sm={6} xs={12}>
                            <Box
                                display={mobile ? "flex" : undefined}
                                width={mobile ? "100%" : undefined}
                                justifyContent="space-between"
                                alignItems="center"
                                gap="0.5rem"
                                flexWrap="wrap">
                                <Typography variant="h2" color="primary">
                                    {`№ ${orderNumber}`}
                                </Typography>
                                <Typography variant="caption" color={colors["500"]}>
                                    {`${orderDate} / ${orderTime}`}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <div>
                                <Typography variant="body2" color="primary">
                                    {person}
                                </Typography>
                                <Typography variant="caption" color={colors["500"]}>
                                    {address}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Box width={tablet ? "100%" : undefined}>
                                {tablet ? (
                                    <LineList values={[{ key: "Тип заказа", value: orderType }]} />
                                ) : (
                                    <>
                                        <Typography variant="caption" color={colors["700"]}>
                                            Тип заказа
                                        </Typography>
                                        <Typography variant="subtitle2" color="primary">
                                            {orderType}
                                        </Typography>
                                    </>
                                )}
                            </Box>
                        </Grid>

                        {userType === UserEnum.admin ? (
                            <>
                                {content}
                                <Grid className={mobile ? s.centered : s.price} item md={1} sm={2} xs={6}>
                                    <Box width={mobile ? "100%" : undefined}>
                                        <Typography variant="h3" color="primary">
                                            {`${price} ₽`}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid className={tablet ? s.centered : s.price} item md={2} sm={3} xs={6}>
                                    <Typography variant="h3" color="primary">
                                        {`${price} ₽`}
                                    </Typography>
                                </Grid>
                                <Grid item md={2} sm={3} xs={6}>
                                    {content}
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Box>
                <Divider flexItem />
                {mobile ? (
                    <Dialog open={open} fullScreen onClose={handleClose}>
                        <div className={s.close}>
                            <IconButton color="secondary" onClick={handleClose}>
                                <Close />
                            </IconButton>
                        </div>
                        <Box padding="1.25rem 1.5rem">
                            <FullOrder change={change} handleClose={() => setOpen(false)} userType={userType} />
                        </Box>
                    </Dialog>
                ) : (
                    <Drawer open={open} onClose={handleClose} anchor="right">
                        <div className={s.close}>
                            <IconButton color="secondary" onClick={handleClose}>
                                <Close />
                            </IconButton>
                        </div>

                        <FullOrder change={change} handleClose={() => setOpen(false)} userType={userType} />
                    </Drawer>
                )}
            </>
        );
    }
);
