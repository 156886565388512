import { Box, Button, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { url } from "../../constants/constants";
import { OrderProduct } from "../cards/OrderProduct/OrderProduct";
import { ShopBagProductType, SizeCardPriceInterface } from "../../types/type";
import GoodsService from "../../services/GoodsService";
import { badgeAmount } from "../../utils/BadgeClass";

type Props = {
    onClick?: () => void;
    buttonRemove?: boolean;
    changing?: boolean;
    handleChange?: () => void;
    handleSummChange?: (sum: number) => void;
    handlePrevSummChange?: (sum: number) => void;
    cashPay?: boolean;
};

type ApiSizes = {
    productId: number;
    sizes: Array<SizeCardPriceInterface>;
}[];

export const ShopBag: React.FC<Props> = ({
    onClick,
    buttonRemove,
    changing,
    handleChange,
    handleSummChange,
    handlePrevSummChange,
    cashPay,
}) => {
    const history = useHistory();
    const shoppingIds = window.localStorage.getItem("shop-bag-ids");
    const [shoppingCards, setShoppingCards] = useState<Array<ShopBagProductType>>(
        !shoppingIds
            ? []
            : [].concat(
                  ...JSON.parse(shoppingIds).map((id: number) => {
                      const product = {
                          ...JSON.parse(window.localStorage.getItem(`shop-bag-product-${id}`) ?? "{}"),
                          id,
                      };
                      const values = product.sizes.map((size: number) => {
                          const newSize = JSON.parse(
                              window.localStorage.getItem(`shop-bag-product-${id}-size-${size}`) ?? "{}"
                          );
                          return {
                              ...product,
                              size: newSize.size,
                              amount: newSize.amount,
                          };
                      });
                      return values;
                  })
              )
    );

    const [summ, setSumm] = useState(
        shoppingCards.reduce((previousValue, currentValue) => {
            if (cashPay) return previousValue + +currentValue.size.price * currentValue.amount;
            return previousValue + +currentValue.size.cardPrice * currentValue.amount;
        }, 0)
    );

    const deleteProduct = (id: number, size: string) => {
        const am = shoppingCards.filter((card) => card.id === id && card.size.size === size)[0].amount;
        if (!changing) {
            badgeAmount.plusOpen(-am);
        } else {
            badgeAmount.plus(-am);
        }
        const newShoppingCards = shoppingCards.filter((card) => !(card.id === id && card.size.size === size));
        setShoppingCards(newShoppingCards);

        window.localStorage.removeItem(`shop-bag-product-${id}-size-${size}`);

        const product = JSON.parse(window.localStorage.getItem(`shop-bag-product-${id}`) ?? "{}");
        product.sizes = product.sizes.filter((val: number) => val !== +size);
        if (!product.sizes[0]) {
            window.localStorage.removeItem(`shop-bag-product-${id}`);

            const ids = window.localStorage.getItem("shop-bag-ids") ?? "";
            let indexes: number[] = JSON.parse(ids);

            indexes = indexes.filter((ind) => ind !== id);
            window.localStorage.setItem("shop-bag-ids", JSON.stringify(indexes));

            if (!indexes[0]) {
                window.localStorage.removeItem("shop-bag-ids");
                if (handleChange) handleChange();
            }
        } else {
            window.localStorage.setItem(`shop-bag-product-${id}`, JSON.stringify(product));
        }
    };

    const changeProduct = (id: number, size: string, amount: number) => {
        const shopCard = shoppingCards.filter((card) => card.id === id && card.size.size === size)[0];
        const ind =
            shoppingCards
                .map((card, index) => (card.id === id && card.size.size === size ? index : null))
                .filter((val) => val !== null)[0] ?? -1;
        badgeAmount.plus(amount - shopCard.amount);
        shopCard.amount = amount;
        shopCard.price = amount * +shopCard.size.price;

        const newShopCards = shoppingCards;
        newShopCards[ind] = shopCard;
        setShoppingCards(newShopCards);
        setSumm(
            newShopCards.reduce((previousValue, currentValue) => {
                if (cashPay) return previousValue + +currentValue.size.price * currentValue.amount;
                return previousValue + +currentValue.size.cardPrice * currentValue.amount;
            }, 0)
        );

        const sizePr = JSON.parse(window.localStorage.getItem(`shop-bag-product-${id}-size-${size}`) ?? "{}");
        sizePr.amount = amount;
        window.localStorage.setItem(`shop-bag-product-${id}-size-${size}`, JSON.stringify(sizePr));
    };

    const [sizeVariants, setSizeVariants] = useState<ApiSizes | null>(null);
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (!sizeVariants && changing && shoppingIds && count < 1) {
            setCount(count + 1);
            const gs = new GoodsService();
            const ids = JSON.parse(shoppingIds ?? "");
            let generalAmount = 0;
            gs.getBootsLeftBySize(ids)
                .then((res) => {
                    setSizeVariants(res);
                    res.map((v: { productId: number; sizes: SizeCardPriceInterface[] }) => {
                        v.sizes.map((val: SizeCardPriceInterface) => {
                            const sizePr = JSON.parse(
                                window.localStorage.getItem(`shop-bag-product-${v.productId}-size-${val.size}`) ?? "{}"
                            );
                            sizePr.size = val;
                            if (sizePr.amount) {
                                sizePr.amount = sizePr.amount > val.amount ? val.amount : sizePr.amount;
                                if (sizePr.amount === 0) {
                                    window.localStorage.removeItem(`shop-bag-product-${v.productId}-size-${val.size}`);
                                    const pr = JSON.parse(
                                        window.localStorage.getItem(`shop-bag-product-${v.productId}`) ?? "{}"
                                    );
                                    pr.sizes = pr.sizes.filter((sizeValue: string) => +sizeValue !== +val.size);
                                    if (!pr.sizes[0]) {
                                        window.localStorage.removeItem(`shop-bag-product-${v.productId}`);
                                        let sh = JSON.parse(window.localStorage.getItem("shop-bag-ids") ?? "[]");
                                        sh = sh.filter((prId: number) => +prId !== +v.productId);
                                        if (!sh[0]) {
                                            window.localStorage.removeItem("shop-bag-ids");
                                        } else {
                                            window.localStorage.setItem("shop-bag-ids", JSON.stringify(sh));
                                        }
                                    } else {
                                        window.localStorage.setItem(
                                            `shop-bag-product-${v.productId}`,
                                            JSON.stringify(pr)
                                        );
                                    }
                                } else {
                                    window.localStorage.setItem(
                                        `shop-bag-product-${v.productId}-size-${val.size}`,
                                        JSON.stringify(sizePr)
                                    );
                                }
                            }
                            const shopCard = shoppingCards.filter(
                                (card) => card.id === v.productId && card.size.size === val.size
                            )[0];
                            const ind =
                                shoppingCards
                                    .map((card, index) =>
                                        card.id === v.productId && card.size.size === val.size ? index : null
                                    )
                                    .filter((value) => value !== null)[0] ?? -1;

                            if (shopCard) {
                                shopCard.size = val;
                                shopCard.amount = +shopCard.amount > +val.amount ? val.amount : shopCard.amount;

                                generalAmount += shopCard.amount;

                                let newShopCards = shoppingCards;
                                newShopCards[ind] = shopCard;

                                if (shopCard.amount === 0) {
                                    newShopCards = newShopCards.filter((_, index) => ind !== index);
                                }
                                setShoppingCards(newShopCards);

                                setSumm(
                                    newShopCards.reduce((previousValue, currentValue) => {
                                        if (cashPay)
                                            return previousValue + +currentValue.size.price * currentValue.amount;
                                        return previousValue + +currentValue.size.cardPrice * currentValue.amount;
                                    }, 0)
                                );
                            }
                            return 1;
                        });
                        return 1;
                    });
                    badgeAmount.set(generalAmount);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [changing, sizeVariants, shoppingIds, count, shoppingCards, cashPay]);

    useEffect(() => {
        setSumm(
            shoppingCards.reduce((previousValue, currentValue) => {
                if (cashPay) return previousValue + +currentValue.size.price * currentValue.amount;
                return previousValue + +currentValue.size.cardPrice * currentValue.amount;
            }, 0)
        );
    }, [cashPay, shoppingCards]);

    useEffect(() => {
        if (handleSummChange) handleSummChange(summ);
        if (!cashPay) {
            if (handlePrevSummChange) {
                const prevSumm = shoppingCards.reduce((previousValue, currentValue) => {
                    return previousValue + +currentValue.size.price * currentValue.amount;
                }, 0);
                handlePrevSummChange(prevSumm);
            }
        }
    }, [summ, handleSummChange, cashPay, handlePrevSummChange, shoppingCards]);

    if (!shoppingCards[0]) {
        if (handleChange) handleChange();
        return (
            <Button
                variant="contained"
                fullWidth
                onClick={() => {
                    history.push(
                        `${url.shop}?itemsPerPage=12&pageNumber=1&sortParameter=PRIORITY&sex=&brands=&colors=&sizes=`
                    );
                    if (onClick) onClick();
                }}>
                Выберите свою первую пару обуви
            </Button>
        );
    }

    return (
        <>
            {shoppingCards
                .filter((card: ShopBagProductType) => card.amount !== 0)
                .map((card: ShopBagProductType, id: number) => {
                    return (
                        <Box
                            key={`${card.amount}_${card.id}_${card.size.size}`}
                            display="flex"
                            flexDirection="column"
                            gap="1.5rem">
                            {id === 0 ? null : <Divider flexItem />}
                            <OrderProduct
                                key={`${card.amount}_${card.id}_${card.size.size}_card`}
                                {...card}
                                color={typeof card.colors === "string" ? card.colors : card.colors.join(", ")}
                                onClick={onClick}
                                changing={!!changing}
                                url={url.shop}
                                deleteProduct={deleteProduct}
                                changeProduct={changeProduct}
                                cashPay={cashPay}
                            />
                        </Box>
                    );
                })}
            {buttonRemove ? null : (
                <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                        history.push(url.createOrder);
                        if (onClick) {
                            onClick();
                        }
                    }}>
                    Оформить заказ {summ} ₽
                </Button>
            )}
        </>
    );
};
