import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Typography,
    IconButton,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { FormikErrors, useFormik } from "formik";
import { DataInput } from "../../components/DataInput/DataInput";
import { SelectOption } from "../../components/SelectOption/SelectOption";
import s from "./CreateProduct.module.scss";
import { ReactComponent as Close } from "../../assets/Close.svg";
import { ReactComponent as Plus } from "../../assets/Pluse-regtangle.svg";
import { ReactComponent as Close2 } from "../../assets/Close-rectangle.svg";
import GoodsService from "../../services/GoodsService";
import { CheckBoxFilterProps, ProductInfo, SelectOptions, SizeType } from "../../types/type";
import EnumsService from "../../services/EnumsServices";
import TransformTypes from "../../utils/typeTransformers/TransformTypes";
import Image from "./Image";
import { Loader } from "../../components/Loader/Loader";
import { useRequest } from "../../hooks/hooks";
import { Snack } from "../../components/snacks/Snack";

type Props = unknown;

interface FormValues {
    modelName: string;
    brand: string;
    article: string;
    priority: string;
    manufacturerCountry: string;
    top: string;
    lining: string;
    material: string;
    sex: string[];
    colors: string[];
    description: string;
    sizes: Array<SizeType>;
}

const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.modelName) {
        errors.modelName = "Обязательное поле";
    }
    if (!values.brand) {
        errors.brand = "Обязательное поле";
    }
    if (!values.article) {
        errors.article = "Обязательное поле";
    }
    if (!values.priority) {
        errors.priority = "Обязательное поле";
    }
    if (!values.manufacturerCountry) {
        errors.manufacturerCountry = "Обязательное поле";
    }
    if (!values.top) {
        errors.top = "Обязательное поле";
    }
    if (!values.lining) {
        errors.lining = "Обязательное поле";
    }
    if (!values.material) {
        errors.material = "Обязательное поле";
    }
    if (!values.sex[0]) {
        errors.sex = "Выберете хотя бы один вариант";
    }
    if (!values.colors[0]) {
        errors.colors = "Выберете хотя бы один вариант";
    }

    for (let i = 0; i < values.sizes.length; i++) {
        if (!values.sizes[i].size || !values.sizes[i].amount || !values.sizes[i].price) {
            if (!errors.sizes || typeof errors.sizes === "string") {
                errors.sizes = new Array(values.sizes.length);
            }
            errors.sizes[i] = {
                size: !values.sizes[i].size ? "Необходимо указать размер" : "",
                amount: !values.sizes[i].amount ? "Необходимо указать количество" : "",
                price: !values.sizes[i].price ? "Необходимо указать цену" : "",
            };
        }
    }
    if (!values.sizes[0] || !values.sizes) {
        errors.sizes = "Необходимо добавить хотя бы один размер";
    }

    return errors;
};

export const CreateProduct: React.FC<Props> = () => {
    const location = useLocation();
    const history = useHistory();
    const productID = +location.pathname.split("/")[2];
    const [product, setProduct] = useState<ProductInfo>();
    const [open, setOpen] = useState(false);

    const es = new EnumsService();
    const tt = new TransformTypes();
    const [sexFilter, errorSex] = useRequest<CheckBoxFilterProps>(es.getSex, tt.valueToCheckBox);
    const [colorFilter, errorColor] = useRequest<CheckBoxFilterProps>(es.getColors, tt.valueToCheckBox);
    const [materialFilter, errorMaterial] = useRequest<SelectOptions>(es.getMaterials, tt.valueToSelect);
    const [brandFilter, errorBrand] = useRequest<SelectOptions>(es.getBrands, tt.valueToSelect);
    const [sizeFilter, errorSize] = useRequest<SelectOptions>(es.getSizes, tt.sizeToSelect);

    const [validationWay, setValidationWay] = useState(false);

    const [mainImage, setMainImage] = useState<Image | undefined>();
    const [mainImageError, setMainImageError] = useState("");
    const [images, setImages] = useState<Image[]>([]);
    const [imagesError, setImagesError] = useState("");
    const [deletedImages, setDeletedImages] = useState<number[]>([]);

    const [pageError, setError] = useState(false);
    const [loadingProduct, setLoadingProduct] = useState(false);

    const formik = useFormik({
        initialValues: {
            modelName: "",
            brand: "",
            article: "",
            priority: "",
            manufacturerCountry: "",
            top: "",
            lining: "",
            material: "",
            sex: [""],
            colors: [""],
            description: "",
            sizes: [{ size: "", amount: "", price: "" }],
        },
        validate: (values) => {
            if (!mainImage) {
                setMainImageError("Загрузите главное изображение");
            } else {
                setMainImageError("");
            }
            if (!images[0]) {
                setImagesError("Загрузите хотя бы одно дополнительное изображение");
            } else {
                setImagesError("");
            }

            const errors = validate(values);
            return errors;
        },
        validateOnChange: validationWay,
        validateOnBlur: validationWay,
        onSubmit: (values) => {
            if (!mainImage || !images[0]) {
                return;
            }
            const token = window.localStorage.getItem("jwt") ?? "";
            if (product) {
                const formdata = new FormData();
                if (mainImage && mainImage.imageFile)
                    formdata.append("newMainImage", mainImage.imageFile, mainImage.imageFile.name);
                else formdata.append("newMainImage", new Blob(undefined));
                let flag = true;
                for (let i = 0; i < images.length; i++) {
                    const newFile = images[i].imageFile;
                    if (newFile !== undefined) {
                        formdata.append("newImages", newFile, newFile.name);
                        flag = false;
                    }
                }
                if (flag) {
                    formdata.append("newImages", new Blob(undefined));
                }
                const obj = { ...values, id: productID, removedImageIds: deletedImages };

                formdata.append("productChangingDto", new Blob([JSON.stringify(obj)], { type: "application/json" }));

                const gs = new GoodsService();
                gs.putBoot(formdata, token)
                    .then(() => {
                        localStorage.setItem("bootCreated", "created");
                        history.goBack();
                    })
                    .catch((error) => {
                        console.log("error", error);
                        setOpen(true);
                    });
            } else {
                const formdata = new FormData();
                if (mainImage && mainImage.imageFile)
                    formdata.append("mainImage", mainImage.imageFile, mainImage.imageFile.name);
                for (let i = 0; i < images.length; i++) {
                    const newFile = images[i].imageFile;
                    if (newFile !== undefined) {
                        formdata.append("images", newFile, newFile.name);
                    }
                }
                const obj = { ...values };
                formdata.append("productCreatingDto", new Blob([JSON.stringify(obj)], { type: "application/json" }));

                const gs = new GoodsService();
                gs.postBoot(formdata, token)
                    .then(() => {
                        localStorage.setItem("bootCreated", "created");
                        history.goBack();
                    })
                    .catch((error) => {
                        console.log("error", error);
                        setOpen(true);
                    });
            }
        },
    });

    useEffect(() => {
        if (formik.submitCount >= 1) setValidationWay(true);
    }, [formik.submitCount]);

    useEffect(() => {
        if (!Number.isNaN(productID) && !product && !loadingProduct && !pageError) {
            const gs = new GoodsService();
            const token = window.localStorage.getItem("jwt") ?? "";
            setLoadingProduct(true);
            gs.getAdminBoot(productID, token)
                .then((res) => {
                    setProduct(res);
                    setImages(
                        res.images.map((val: { imageId: number; imageUrl: string }, ind: number) => {
                            const newImage = new Image(ind, val.imageUrl, undefined, val.imageId);
                            return newImage;
                        })
                    );
                    formik.setValues({ ...res });
                    setMainImage(new Image(0, res.mainImage.imageUrl, undefined, res.mainImage.imageId));
                    setError(false);
                })
                .catch(() => {
                    setError(true);
                })
                .finally(() => setLoadingProduct(false));
        }
    }, [productID, product, loadingProduct, pageError, formik]);

    // checkboxes
    const handleSexChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (formik.values.sex.includes(event.target.value)) {
            formik.setFieldValue(
                "sex",
                formik.values.sex.filter((val) => val !== event.target.value && val !== ""),
                validationWay
            );
        } else {
            const newSex = [...formik.values.sex.filter((val) => val !== ""), event.target.value];
            formik.setFieldValue("sex", newSex, validationWay);
        }
    };
    const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (formik.values.colors.includes(event.target.value)) {
            formik.setFieldValue(
                "colors",
                formik.values.colors.filter((val) => val !== event.target.value && val !== ""),
                validationWay
            );
        } else {
            const newColors = [...formik.values.colors.filter((val) => val !== ""), event.target.value];
            formik.setFieldValue("colors", newColors, validationWay);
        }
    };

    // sizes
    const handleSizesChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        id: number,
        type: "size" | "price" | "amount"
    ) => {
        const newSize = formik.values.sizes[id];
        newSize[type] = event.target.value;
        const newPrSizes = [...formik.values.sizes.slice(0, id), newSize, ...formik.values.sizes.slice(id + 1)];
        formik.setFieldValue("sizes", newPrSizes);
    };
    const handleSizeDelete = (id: number) => {
        const newSizes = [...formik.values.sizes.slice(0, id), ...formik.values.sizes.slice(id + 1)];
        formik.setFieldValue("sizes", newSizes);
    };
    const addSize = () => {
        formik.setFieldValue("sizes", [...formik.values.sizes, { size: "", price: "", amount: "" }]);
    };

    // photos inputs
    const inputEl: React.RefObject<HTMLInputElement> | null | undefined = useRef(null);
    const onButtonClick = () => {
        if (inputEl) {
            inputEl?.current?.click();
        }
    };
    const handleImagesChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        if (files) {
            const imagesArr: Image[] = [];
            for (let i = 0; i < files.length; i++) {
                const reader = new FileReader();
                reader.onloadend = (e) => {
                    if (e?.target?.result) {
                        const newImage = new Image(
                            images.length + imagesArr.length,
                            e?.target?.result.toString(),
                            files[i],
                            undefined
                        );
                        imagesArr.push(newImage);
                    }
                    setImages([...images, ...imagesArr]);
                    if (validationWay) setImagesError("");
                };
                reader.readAsDataURL(files[i]);
            }
        }
    };
    const deleteImages = (imageId: number | undefined, ind: number) => {
        if (imageId !== undefined) {
            setDeletedImages([...deletedImages, imageId]);
        }
        setImages(images.filter(({ id }) => id !== ind));
        setTimeout(() => {
            if (validationWay) formik.validateForm();
        }, 0.1);
    };

    const inputElMain: React.RefObject<HTMLInputElement> | null | undefined = useRef(null);
    const onButtonMainClick = () => {
        if (inputElMain) {
            inputElMain?.current?.click();
        }
    };
    const handleImgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        if (files) {
            const reader = new FileReader();
            reader.onloadend = (e) => {
                if (e?.target?.result) {
                    const newImage = new Image(1, e?.target?.result.toString(), files[0], undefined);
                    setMainImage(newImage);
                    if (validationWay) setMainImageError("");
                }
            };
            reader.readAsDataURL(files[0]);
        }
    };
    const deleteImg = (imageId: number | undefined) => {
        if (imageId !== undefined) {
            setDeletedImages([...deletedImages, imageId]);
        }
        setMainImage(undefined);
        if (validationWay) setMainImageError("Загрузите главное изображение");
    };

    const deleteBoot = () => {
        const token = window.localStorage.getItem("jwt") ?? "";
        const gs = new GoodsService();
        gs.deleteBoot(productID, token)
            .then(() => history.goBack())
            .catch((err) => console.log(err));
    };

    if (!materialFilter || !colorFilter || !brandFilter || !sexFilter || !sizeFilter) {
        return <Loader />;
    }

    if (!Number.isNaN(productID) && !product) {
        return <Loader />;
    }

    if (pageError || errorSize || errorBrand || errorSex || errorColor || errorMaterial) {
        return (
            <Box display="flex" width="100%" justifyContent="center" pt="4rem">
                <Typography variant="h3">Что-то пошло не так</Typography>
            </Box>
        );
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box display="flex" flexDirection="column" gap="2rem" py="2rem" width="100%">
                <Typography variant="h2">{productID ? "Редактировать товар" : "Добавить товар"}</Typography>
                <Box display="flex" flexDirection="column" gap="1.5rem" width="100%">
                    <Box display="flex" gap="1.25rem" alignItems="flex-end">
                        <DataInput
                            label="Название модели"
                            position={0}
                            value={formik.values.modelName}
                            onChange={formik.handleChange}
                            color={formik.errors.modelName ? "error" : "primary"}
                            helperText={formik.errors.modelName}
                            name="modelName"
                            variant="outlined"
                        />
                        <FormGroup className={s.brand}>
                            <FormLabel>Бренд</FormLabel>
                            <SelectOption
                                variant="outlined"
                                value={formik.values.brand}
                                onChange={formik.handleChange}
                                color={formik.errors.brand ? "error" : "primary"}
                                helperText={formik.errors.brand}
                                name="brand"
                                fullWidth
                                options={brandFilter}
                            />
                        </FormGroup>
                        <DataInput
                            label="Артикул"
                            position={3}
                            value={formik.values.article}
                            onChange={formik.handleChange}
                            color={formik.errors.article ? "error" : "primary"}
                            helperText={formik.errors.article}
                            name="article"
                            variant="outlined"
                        />
                        <DataInput
                            label="Приоритет"
                            position={3}
                            value={formik.values.priority}
                            onChange={formik.handleChange}
                            color={formik.errors.priority ? "error" : "primary"}
                            helperText={formik.errors.priority}
                            name="priority"
                            variant="outlined"
                        />
                    </Box>
                    <Divider flexItem />
                    <Box display="flex" gap="1.25rem" alignItems="flex-end">
                        <DataInput
                            label="Страна производитель"
                            position={1}
                            value={formik.values.manufacturerCountry}
                            onChange={formik.handleChange}
                            color={formik.errors.manufacturerCountry ? "error" : "primary"}
                            helperText={formik.errors.manufacturerCountry}
                            name="manufacturerCountry"
                            variant="outlined"
                        />
                        <FormGroup className={s.brand}>
                            <FormLabel>Верх</FormLabel>
                            <SelectOption
                                variant="outlined"
                                value={formik.values.top}
                                onChange={formik.handleChange}
                                color={formik.errors.top ? "error" : "primary"}
                                helperText={formik.errors.top}
                                name="top"
                                fullWidth
                                options={materialFilter}
                            />
                        </FormGroup>
                        <FormGroup className={s.brand}>
                            <FormLabel>Подкладка</FormLabel>
                            <SelectOption
                                variant="outlined"
                                fullWidth
                                options={materialFilter}
                                value={formik.values.lining}
                                onChange={formik.handleChange}
                                color={formik.errors.lining ? "error" : "primary"}
                                helperText={formik.errors.lining}
                                name="lining"
                            />
                        </FormGroup>
                        <FormGroup className={s.brand}>
                            <FormLabel>Низ</FormLabel>
                            <SelectOption
                                variant="outlined"
                                value={formik.values.material}
                                onChange={formik.handleChange}
                                color={formik.errors.material ? "error" : "primary"}
                                helperText={formik.errors.material}
                                name="material"
                                fullWidth
                                options={materialFilter}
                            />
                        </FormGroup>
                    </Box>
                    <Divider flexItem />
                    <Box display="flex" flexDirection="column" gap="1rem">
                        <Typography variant="h3">Кроссовки</Typography>
                        <FormGroup row className={s.gender} onChange={handleSexChange}>
                            {sexFilter.map(({ key, value }) => (
                                <FormControlLabel
                                    key={key}
                                    control={<Checkbox color={formik.errors.sex ? "error" : "primary"} />}
                                    label={value}
                                    checked={formik.values.sex.includes(`${value}`)}
                                    value={value}
                                />
                            ))}
                        </FormGroup>
                        <Typography variant="caption" color="error">
                            {formik.errors.sex}
                        </Typography>
                    </Box>
                    <Divider flexItem />

                    <Box display="flex" gap="90px">
                        <Box display="flex" flexDirection="column" gap="1rem" whiteSpace="nowrap">
                            <Typography variant="body1">Главное фото</Typography>
                            {mainImage ? (
                                <Box className={s.photosWrapper}>
                                    <div className={s.photo}>
                                        <img
                                            src={
                                                mainImage.imagePath.startsWith(".")
                                                    ? mainImage.imagePath.slice(1)
                                                    : mainImage.imagePath
                                            }
                                            alt=""
                                            className={s.photo}
                                        />
                                    </div>
                                    <IconButton onClick={() => deleteImg(mainImage.serverId)} color="primary">
                                        <Close2 />
                                    </IconButton>
                                </Box>
                            ) : (
                                <>
                                    <input
                                        type="file"
                                        ref={inputElMain}
                                        accept="image/*"
                                        hidden
                                        onChange={handleImgChange}
                                    />
                                    <Button onClick={onButtonMainClick} variant="outlined">
                                        <Box className={s.addPhoto}>
                                            <Plus />
                                            <Typography variant="caption">Добавить фото</Typography>
                                        </Box>
                                    </Button>
                                </>
                            )}
                            <Typography variant="caption" color="error">
                                {mainImageError}
                            </Typography>
                        </Box>
                        <Box display="flex" flexDirection="column" gap="1rem">
                            <Typography variant="body1">Фото товара</Typography>
                            <Box display="flex" gap="1rem" flexWrap="wrap">
                                {images.map(({ imagePath, id, serverId }) => (
                                    <Box className={s.photosWrapper} key={`${imagePath}_${id}`}>
                                        <div className={s.photo}>
                                            <img
                                                src={imagePath.startsWith(".") ? imagePath.slice(1) : imagePath}
                                                className={s.photo}
                                                alt=""
                                            />
                                        </div>
                                        <IconButton onClick={() => deleteImages(serverId, id)} color="primary">
                                            <Close2 />
                                        </IconButton>
                                    </Box>
                                ))}

                                <input
                                    type="file"
                                    ref={inputEl}
                                    accept="image/*"
                                    multiple
                                    hidden
                                    onChange={handleImagesChange}
                                />
                                <Button onClick={onButtonClick} variant="outlined">
                                    <Box className={s.addPhoto}>
                                        <Plus />
                                        <Typography variant="caption">Добавить фото</Typography>
                                    </Box>
                                </Button>
                            </Box>
                            <Typography variant="caption" color="error">
                                {imagesError}
                            </Typography>
                        </Box>
                    </Box>

                    <Divider flexItem />
                    <Box display="flex" flexDirection="column" gap="1rem">
                        <Typography variant="h3">Кроссовки</Typography>
                        <FormGroup className={s.gender__column} onChange={handleColorChange}>
                            {colorFilter.map(({ key, value }) => (
                                <FormControlLabel
                                    key={key}
                                    value={key}
                                    checked={formik.values.colors.includes(key)}
                                    control={<Checkbox color={formik.errors.colors ? "error" : "primary"} />}
                                    label={value}
                                />
                            ))}
                        </FormGroup>
                        <Typography variant="caption" color="error">
                            {formik.errors.colors}
                        </Typography>
                    </Box>

                    <Divider flexItem />

                    <Typography variant="h3">Размеры</Typography>

                    {typeof formik.errors.sizes === "string" ? (
                        <Typography variant="caption" color="error">
                            {formik.errors.sizes}
                        </Typography>
                    ) : null}
                    {formik.values.sizes.map(({ size, amount, price }, ind) => {
                        let sizeError = "";
                        let amountError = "";
                        let priceError = "";
                        if (formik.errors.sizes && formik.errors.sizes[ind]) {
                            const arr = formik.errors.sizes[ind] as { size: string; price: string; amount: string };
                            sizeError = arr.size;
                            amountError = arr.amount;
                            priceError = arr.price;
                        }
                        return (
                            <Box display="flex" gap="1.25rem" key={`${size}_${ind + 0}`} alignItems="flex-end">
                                <FormGroup className={s.brand}>
                                    <FormLabel>Размер</FormLabel>
                                    <SelectOption
                                        color={sizeError ? "error" : "primary"}
                                        helperText={sizeError}
                                        variant="outlined"
                                        value={size}
                                        fullWidth
                                        options={sizeFilter}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleSizesChange(e, ind, "size")
                                        }
                                    />
                                </FormGroup>
                                <DataInput
                                    label="Кол-во"
                                    position={4}
                                    value={amount}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleSizesChange(e, ind, "amount")
                                    }
                                    color={amountError ? "error" : "primary"}
                                    helperText={amountError}
                                    variant="outlined"
                                />
                                <DataInput
                                    label="Цена за штуку"
                                    position={4}
                                    value={price}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleSizesChange(e, ind, "price")
                                    }
                                    color={priceError ? "error" : "primary"}
                                    helperText={priceError}
                                    variant="outlined"
                                />
                                <Box pb="0.5rem">
                                    <IconButton color="primary" onClick={() => handleSizeDelete(ind)}>
                                        <Close />
                                    </IconButton>
                                </Box>
                            </Box>
                        );
                    })}
                    <Button variant="outlined" onClick={addSize}>
                        Добавить размер
                    </Button>
                    <DataInput
                        label="Описание товара"
                        position={0}
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        color={formik.errors.description ? "error" : "primary"}
                        helperText={formik.errors.description}
                        name="description"
                        variant="outlined"
                        multiline
                    />
                    <Box display="flex" gap="1.5rem">
                        <Button variant="contained" type="submit">
                            {!Number.isNaN(productID) ? "Изменить товар" : "Добавить товар"}
                        </Button>
                        {!Number.isNaN(productID) ? (
                            <Button variant="outlined" onClick={deleteBoot}>
                                Удалить товар
                            </Button>
                        ) : null}
                    </Box>
                </Box>
            </Box>
            <Snack
                open={open}
                handleClose={() => setOpen(false)}
                message={<Typography color="primary">Что-то пошло не так</Typography>}
            />
        </form>
    );
};
