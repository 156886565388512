import { Box, BoxProps, Divider, Tab, Tabs } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

type Props = {
    tabs: TabsProps[];
    onClick?: Dispatch<SetStateAction<boolean>>;
    tabKey?: string;
} & BoxProps;

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

type TabsProps = {
    id: number;
    value: string;
    Content: ({ ...props }) => JSX.Element;
    key?: string;
};

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            mt="2.625rem"
            mb="2.625rem"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && children}
        </Box>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export const ContentTabs: React.FC<Props> = ({ tabKey, tabs, onClick, ...props }) => {
    const tab = tabKey !== undefined ? window.localStorage.getItem(tabKey) : 0;
    const [tabValue, setTabValue] = useState(tab ? +tab : 0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        if (tabKey) {
            window.localStorage.setItem(tabKey, `${tabValue}`);
        }
        return () => window.localStorage.removeItem(`${tabKey}`);
    });

    const finalTabValue = tabValue < tabs.length ? tabValue : 0;

    return (
        <>
            <Box {...props}>
                <Tabs value={finalTabValue} onChange={handleChange}>
                    {tabs.map(({ id, value }) => (
                        <Tab label={value} {...a11yProps(id)} key={`tab_${value}_${id}`} />
                    ))}
                </Tabs>
                <Divider />
            </Box>
            {tabs.map(({ id, value, Content }) => (
                <TabPanel key={`tab_content_${value}_${id}`} index={id} value={finalTabValue}>
                    <Content onClick={onClick} />
                </TabPanel>
            ))}
        </>
    );
};
