import {
    AppBar,
    Badge,
    Box,
    Container,
    Dialog,
    Divider,
    Drawer,
    IconButton,
    Popover,
    PopoverPosition,
    Toolbar,
    useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as User } from "../../assets/User.svg";
import { ReactComponent as LoggedUser } from "../../assets/LoggedUser.svg";
import { ReactComponent as ShoppingBag } from "../../assets/Shop-bag.svg";
import { ReactComponent as ListCaption } from "../../assets/List-caption.svg";
import { ReactComponent as Close } from "../../assets/Close.svg";
import { ReactComponent as SearchIcon } from "../../assets/Search.svg";
import s from "./Header.module.scss";
import { Search } from "../Search/Search";
import { LinkTabs } from "../tabs/LinkTabs/LinkTabs";
import { LogoLink } from "../LogoLink/LogoLink";
import { url, UserEnum } from "../../constants/constants";
import { ShopBag } from "../ShopBag/ShopBag";
import { MobileSearch } from "../MobileSearch/MobileSearch";
import UserService from "../../services/UserService";
import OrdersService from "../../services/OrdersService";
import { badgeAmount } from "../../utils/BadgeClass";
import { LoginDialog } from "../LoginDialog/LoginDialog";

type Props = unknown;

export const Header: React.FC<Props> = () => {
    const mobile = useMediaQuery("(max-width: 900px)");
    const smallScreen = useMediaQuery("(max-width: 1200px)");
    const token = window.localStorage.getItem("jwt");
    const userRole = window.localStorage.getItem("user-role");

    const [openMenu, setOpenMenu] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const [openPCSearch, setOpenPCSearch] = useState(false);
    const [openEnterDialogWindow, setOpenEnterDialogWindow] = useState(false);
    const [anchorPosition, setAnchorPosition] = useState<undefined | PopoverPosition>(undefined);
    const [search, setSearch] = useState("");
    const [logged, setLogged] = useState(!!token);
    const history = useHistory();
    const openShopBag = anchorPosition !== undefined;

    const handleTogglePCSearch = () => {
        setOpenPCSearch(!openPCSearch);
        document.querySelector("body")?.classList.toggle("noScroll");
    };
    const handleSearchClose = () => {
        setOpenPCSearch(false);
        setAnchorPosition(undefined);
        document.querySelector("body")?.classList.remove("noScroll");
    };
    const handlePCSearchClose = () => {
        document.querySelector("body")?.classList.remove("noScroll");
        history.push(`${url.search}?search=${search}`);
        handleSearchClose();
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        handleSearchClose();
        const { right } = event.currentTarget.getBoundingClientRect();
        const header = document.querySelector("header .MuiToolbar-root")?.getBoundingClientRect();
        const top = header ? header.bottom : 0;
        setAnchorPosition({ left: right, top });
    };
    const handleClose = () => {
        setAnchorPosition(undefined);
    };
    const userClick = () => {
        if (window.localStorage.getItem("jwt")) {
            history.push(url.personalAccount);
        } else {
            setOpenEnterDialogWindow(true);
        }
    };

    const onKeyDownPC: React.KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
        if (event.code === "Enter") {
            document.querySelector("body")?.classList.remove("noScroll");
            handlePCSearchClose();
        }
    };

    const personEntered = () => {
        setOpenEnterDialogWindow(false);
        setLogged(true);
        if (history.location.pathname.includes(url.personalAccount)) {
            history.push(url.main);
            history.push(url.personalAccount);
        }
    };

    useEffect(() => {
        if (!mobile) {
            setOpenSearch(false);
            setOpenMenu(false);
        }
    }, [mobile]);

    useEffect(() => {
        if (token) {
            if (userRole === UserEnum.user) {
                const us = new UserService();
                us.getUserInfo(token).catch((err: Error) => {
                    if (err.message === "403") {
                        window.localStorage.removeItem("jwt");
                        setLogged(false);
                    }
                });
            } else {
                const os = new OrdersService();
                os.getAdminOrders(token).catch((err: Error) => {
                    if (err.message === "403") {
                        window.localStorage.removeItem("jwt");
                        setLogged(false);
                    }
                });
            }
        } else {
            setLogged(false);
        }
    }, [token, userRole]);

    return (
        <>
            <AppBar position="sticky" elevation={0}>
                <Container maxWidth="lg">
                    <Toolbar disableGutters className={!mobile ? s.header : s.header__small}>
                        <LogoLink small={smallScreen} />

                        <LinkTabs hidden={mobile} />

                        <div className={!smallScreen ? s.header_leftSide : s.header_leftSide__mobile}>
                            <div className={mobile ? s.hidden : ""}>
                                <IconButton color="secondary" onClick={handleTogglePCSearch}>
                                    <SearchIcon />
                                </IconButton>
                            </div>

                            <Badge badgeContent={badgeAmount.amount > 0 ? badgeAmount.amount : 0} color="primary">
                                <IconButton color="secondary" id="open-shop-bag" onClick={handleClick}>
                                    <ShoppingBag />
                                </IconButton>
                            </Badge>
                            <IconButton id="header_user_button" color="secondary" onClick={userClick}>
                                {logged ? <LoggedUser /> : <User />}
                            </IconButton>
                        </div>
                    </Toolbar>
                </Container>

                {/* Кнопки управления (открывашки поиска и меню) */}
                <Divider className={mobile ? "" : s.hidden} />
                <Container maxWidth="lg" className={mobile ? "" : s.hidden}>
                    <div className={mobile ? s.searchBox : s.hidden}>
                        <IconButton onClick={() => setOpenMenu(true)}>
                            <ListCaption />
                        </IconButton>
                        <IconButton color="secondary" onClick={() => setOpenSearch(true)}>
                            <SearchIcon />
                        </IconButton>
                    </div>
                </Container>
                <Divider />
            </AppBar>
            {/* Поиск для компьютера */}
            <div className={openPCSearch ? s.pcSearch : s.hidden}>
                <div className={s.pcSearch_search}>
                    <Search
                        fullWidth
                        onKeyDown={onKeyDownPC}
                        value={search}
                        onChange={(event) => setSearch(event.currentTarget.value)}
                        handleClick={handlePCSearchClose}
                    />
                </div>
                <IconButton color="secondary" onClick={handleSearchClose}>
                    <Close />
                </IconButton>
            </div>
            <Box
                className={openPCSearch || openShopBag ? s.pcSearch_modal : s.hidden}
                id="close-shop-bag"
                onClick={handleSearchClose}
            />

            {/* Менюшка */}
            <Drawer open={openMenu} onClose={() => setOpenMenu(false)}>
                <div className={s.close}>
                    <IconButton color="secondary" onClick={() => setOpenMenu(false)}>
                        <Close />
                    </IconButton>
                </div>

                <LinkTabs orientation="vertical" onClick={() => setOpenMenu(false)} />
            </Drawer>

            {/* Поиск */}
            <Dialog fullScreen open={openSearch} onClose={() => setOpenSearch(false)}>
                <MobileSearch onClick={() => setOpenSearch(false)} />
            </Dialog>

            {/* Вход на сайт */}
            <LoginDialog
                open={openEnterDialogWindow}
                mobile={mobile}
                handleClose={() => setOpenEnterDialogWindow(false)}
                onClick={personEntered}
            />

            {/*  Корзина  */}
            <Popover
                open={openShopBag}
                id="shop-bag"
                anchorReference="anchorPosition"
                anchorPosition={anchorPosition}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}>
                <div className={s.shopDialog}>
                    <ShopBag cashPay onClick={handleClose} />
                </div>
            </Popover>
        </>
    );
};
