import { Box, IconButton, Typography, useMediaQuery } from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { colors } from "../../../theme";
import { SelectOption } from "../../SelectOption/SelectOption";
import s from "./OrderProduct.module.scss";
import { ImageProps, SizeCardPriceInterface } from "../../../types/type";
import { ReactComponent as Close } from "../../../assets/Close.svg";

type Props = {
    id: number;
    mainImage: ImageProps;
    price?: number;
    brand: string;
    modelName: string;
    size: SizeCardPriceInterface;
    color: string;
    amount: number;
    onClick?: () => void;
    deleteProduct?: (id: number, size: string) => void;
    changeProduct?: (id: number, size: string, amount: number) => void;
    changing: boolean;
    url: string;
    cashPay?: boolean;
};

export const OrderProduct: React.FC<Props> = ({
    id,
    mainImage,
    price,
    brand,
    modelName,
    size,
    color,
    amount,
    onClick,
    deleteProduct,
    changeProduct,
    changing,
    url,
    cashPay,
}) => {
    const [amountSelect, setAmountSelect] = useState(amount > size.amount ? size.amount : amount);
    const newPrice = price ?? amountSelect * size.price;
    const cardPrice = !cashPay ? amountSelect * size.cardPrice : "";

    const history = useHistory();
    const mobile = useMediaQuery("(max-width: 680px)");

    const handleAmountSort = (e: ChangeEvent<HTMLInputElement>) => {
        setAmountSelect(+e.target.value);
        if (changeProduct) changeProduct(id, size.size, +e.target.value);
    };
    const handleProductDelete = () => {
        if (deleteProduct) deleteProduct(id, size.size);
    };

    const amountVariants = Array(size?.amount ?? 1);
    for (let i = 0; i < (size?.amount ?? 1); i++) {
        amountVariants[i] = {
            id: i + 1,
            value: i + 1,
        };
    }

    const options = [
        {
            value: "Цвет",
            select: color,
            textType: true,
        },
        {
            value: "Размер",
            select: size?.size,
            textType: true,
        },
        {
            value: "Кол-во",
            select: amountSelect,
            selectOptions: amountVariants,
            funct: handleAmountSort,
        },
    ];

    return (
        <Box display="flex" gap={mobile ? "1.25rem" : "1.5rem"} position="relative" flexWrap="wrap">
            <Box display="flex" gap="0.75rem" alignItems="center" maxWidth="100%">
                <Box
                    className={clsx({ [s.product_image]: true, [s.product_image__mobile]: mobile })}
                    onClick={() => {
                        history.push(`${url}/${id}`);
                        if (onClick) {
                            onClick();
                        }
                    }}
                    sx={{ background: `url(${mainImage.imageUrl}) center/cover no-repeat, ${colors.white}` }}
                />
                {mobile ? (
                    <Box width="calc(100% - 2.5rem - 0.75rem - 2.5rem)">
                        <Typography variant="caption" color={colors["500"]}>
                            {brand}
                        </Typography>

                        <Typography variant="body2" className={s.productName} color="primary">
                            {modelName}
                        </Typography>
                    </Box>
                ) : null}
            </Box>
            <Box flexGrow={1}>
                {!mobile ? (
                    <>
                        <Typography variant="caption" color={colors["500"]}>
                            {brand}
                        </Typography>

                        <Typography variant="body2" className={s.productName} color="primary">
                            {modelName}
                        </Typography>
                    </>
                ) : null}

                <Box
                    display="flex"
                    alignItems="flex-end"
                    justifyContent={mobile ? "space-between" : "unset"}
                    gap="1rem"
                    mt="0.75rem"
                    flexWrap="wrap">
                    {options.map(({ value, select, selectOptions, textType, funct }) => (
                        <div key={value} className={s.select}>
                            <Typography variant={mobile ? "caption" : "subtitle2"} color="secondary">
                                {value}
                            </Typography>
                            {changing && !textType ? (
                                <SelectOption
                                    value={select}
                                    onChange={funct}
                                    fullWidth
                                    options={selectOptions ?? []}
                                    color="primary"
                                />
                            ) : (
                                <Typography
                                    className={clsx({ [s.option]: true, [s.option__mobile]: mobile })}
                                    variant="subtitle2"
                                    color="primary">
                                    {select}
                                </Typography>
                            )}
                        </div>
                    ))}
                    <Box ml="auto">
                        <Typography variant="subtitle2" color="#E4007D">
                            {!cashPay && `${cardPrice} ₽`}
                        </Typography>
                        <Typography className={s.price} align="right" variant={!cashPay ? "h2" : "subtitle1"}>
                            {!cashPay ? <s>{`${newPrice} ₽`}</s> : `${newPrice} ₽`}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <div className={s.close}>
                <IconButton size="small" color="secondary" onClick={handleProductDelete}>
                    <Close />
                </IconButton>
            </div>
        </Box>
    );
};
