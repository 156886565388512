export default class GoodsService {
    apiBase = `${process.env.REACT_APP_SITE_URL}/rest/boots`;
    apiAdminBase = `${process.env.REACT_APP_SITE_URL}/rest/admins/boots`;

    getBoot = async (id: number) => {
        const res = await fetch(encodeURI(`${this.apiBase}?boot_id=${id}`));

        if (!res.ok) {
            throw new Error(`Error ${res.status} ${res.statusText} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };

    getPopularGoods = async (amount: number) => {
        const res = await fetch(encodeURI(`${this.apiBase}/most-popular?amount=${amount}`));

        if (!res.ok) {
            throw new Error(`Error ${res.status} ${res.statusText} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };

    getBootsSortedByName = async (sortingString: string) => {
        const res = await fetch(encodeURI(`${this.apiBase}/name-sorted?sortingString=${sortingString}`));

        if (!res.ok) {
            throw new Error(`Error ${res.status} ${res.statusText} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return { res: result, search: sortingString };
    };

    getBootsLeftBySize = async (ids: Array<number>) => {
        const stringIds = ids.map((value) => `boots_id=${value}`).join("&");
        const res = await fetch(encodeURI(`${this.apiBase}/left-by-size?${stringIds}`));

        if (!res.ok) {
            throw new Error(`Error ${res.status} ${res.statusText} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };

    getFilteredBoots = async (url: string) => {
        const res = await fetch(encodeURI(`${this.apiBase}/filtered/sorted${url}`));

        if (!res.ok) {
            throw new Error(`Error ${res.status} ${res.statusText} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return { res: result, url };
    };

    getAdminGoods = async (token: string) => {
        const res = await fetch(encodeURI(`${this.apiBase}/full-info`), {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (!res.ok) {
            throw new Error(`Error ${res.status} ${res.statusText} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };

    getAdminBoot = async (id: number, token: string) => {
        const res = await fetch(encodeURI(`${this.apiAdminBase}?boot_id=${id}`), {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (!res.ok) {
            throw new Error(`Error ${res.status} ${res.statusText} acquired while fetching ${res.url}`);
        }

        const result = await res.json();
        return result;
    };

    postBoot = async (formdata: FormData, token: string) => {
        const res = await fetch(encodeURI(this.apiBase), {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formdata,
            redirect: "follow",
        });

        if (!res.ok) {
            throw new Error(`Error ${res.status} acquired while fetching ${res.url}`);
        }

        const result = await res.text();
        return result;
    };

    putBoot = async (formdata: FormData, token: string) => {
        const res = await fetch(encodeURI(this.apiBase), {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formdata,
            redirect: "follow",
        });

        if (!res.ok) {
            throw new Error(`Error ${res.status} acquired while fetching ${res.url}`);
        }

        const result = await res.text();
        return result;
    };

    deleteBoot = async (id: number, token: string) => {
        const res = await fetch(encodeURI(`${this.apiBase}/${id}`), {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (!res.ok) {
            throw new Error(`Error ${res.status} acquired while fetching ${res.url}`);
        }

        return res;
    };
}
