import {
    Divider,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
    Button,
    Box,
    useMediaQuery,
    FormGroup,
    Checkbox,
} from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { FormikErrors, useFormik } from "formik";
import { shops, userData, deliveryWays, onSitePayment, orderText } from "../../../constants/constants";
import { InputBlock } from "../../InputBlock/InputBlock";
import { CheckButton } from "../../CheckButton/CheckButton";
import s from "./OrderForm.module.scss";
import { colors } from "../../../theme";
import { DataInput, PDInputType } from "../../DataInput/DataInput";
import EnumsService from "../../../services/EnumsServices";
import UserService from "../../../services/UserService";
import OrdersService from "../../../services/OrdersService";
import { OrderCreationType, UserData } from "../../../types/type";
import { Snack } from "../../snacks/Snack";
import { LineList } from "../../LineList/LineList";
import { useRequest } from "../../../hooks/hooks";
import { badgeAmount } from "../../../utils/BadgeClass";
import { WhyCheaper } from "../../WhyCheaper/WhyCheaper";
import { Loader } from "../../Loader/Loader";
import { ShopPlace } from "../../ShopPlace/ShopPlace";
import { DeliveryWay } from "../../DeliveryWay/DeliveryWay";

type Props = {
    disabled?: boolean;
    summ: number;
    handleCashPayChange: (v: boolean) => void;
    prevSumm: number;
};

type FormikDataType = {
    firstName: string;
    lastName: string;
    patronymic: string;
    email: string;
    phone: string;
    street: string;
    building: string;
    flat: string;
    zipCode: string;
    comment: string;
    check: boolean;
};

type DType = { price: number; deliveryType: string };

export const OrderForm: React.FC<Props> = React.memo(({ disabled, summ, handleCashPayChange, prevSumm }) => {
    const [nclicked, setNclicked] = useState(false);
    const [deliveryWay, setDeliveryWay] = useState(0);
    const [check, setCheck] = useState("");
    const [shop, setShop] = useState(0);
    const history = useHistory();
    const mobile = useMediaQuery("(max-width: 600px)");
    const [dPrice, setDPrice] = useState(0);
    const es = new EnumsService();
    const [paymentTypes, errorPaymentTypes] = useRequest<string>(es.getPaymentTypes, (res) => {
        setCheck(res.filter((value: string) => value !== onSitePayment)[0]);
        return res;
    });
    const [deliveryPrice, errorDeliveryPrice] = useRequest<DType>(es.getDeliveryWays, (res) => {
        setDPrice(res.filter((value: DType) => value.deliveryType === deliveryWays[0].way)[0].price);
        return res;
    });
    const [userInfo, setUserInfo] = useState<UserData>();
    const [requestError, setError] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [loadingUserInfo, setLoadingUserInfo] = useState(false);
    const data: Array<PDInputType[]> = [
        [
            ...userData.userData.personalData.values.slice(0, 3),
            { label: "Контактный телефон", position: 0, name: "phone", value: "" },
            { label: "Email", position: 0, name: "email", value: "" },
        ],
        [
            ...userData.userData.address.values,
            { label: "Комментарий к заказу", position: 0, multiline: true, name: "comment", value: "" },
        ],
        [
            {
                label: "Индекс",
                position: 2,
                name: "zipCode",
                value: "",
            },
            ...userData.userData.address.values.slice(0, 2),
        ],
    ];

    const handleClick = (id: number) => {
        setDeliveryWay(id);
        const { way } = deliveryWays.filter((val) => val.id === id)[0];
        const { price } =
            deliveryPrice && !errorDeliveryPrice
                ? deliveryPrice.filter(({ deliveryType }) => deliveryType === way)[0]
                : { price: 0 };
        setDPrice(price);
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        const prevVal = check;
        setCheck(value);
        if (value === onSitePayment && prevVal !== value) {
            handleCashPayChange(false);
        } else if (value !== onSitePayment && prevVal !== value) {
            handleCashPayChange(true);
        }
    };
    const handleShopClick = (id: number) => setShop(id);

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            patronymic: "",
            email: "",
            phone: "",
            street: "",
            building: "",
            flat: "",
            zipCode: "",
            comment: "",
            check: false,
        },
        validate: (values) => {
            const errors: FormikErrors<FormikDataType> = {};

            if (!values.firstName) {
                errors.firstName = "Обязательное поле";
            }

            if (!values.email) {
                errors.email = "Обязательное поле";
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = "Неверный адрес почты";
            }

            if (!values.phone) {
                errors.phone = "Обязательное поле";
            } else if (!/^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/.test(values.phone)) {
                errors.phone = "Неверный номер телефона";
            }

            if (!values.street && deliveryWay !== 3) {
                errors.street = "Обязательное поле";
            }

            if (!values.building && deliveryWay !== 3) {
                errors.building = "Обязательное поле";
            }

            if (!values.zipCode && !values.flat && deliveryWay !== 3) {
                if (deliveryWay === 0) {
                    errors.flat = "Обязательное поле";
                } else if (deliveryWay === 1) {
                    errors.zipCode = "Обязательное поле";
                }
            }

            if (!values.check) {
                errors.check = "Обязательное поле";
            }

            return errors;
        },
        enableReinitialize: true,
        validateOnBlur: true,
        validateOnChange: false,
        onSubmit: (values) => {
            setNclicked(true);
            let { street } = values;
            let { building } = values;
            let nflat: string | undefined = values.flat;
            let nzipCode: string | undefined = values.zipCode;
            if (deliveryWay === 3) {
                const shopCenter = shops.filter(({ id }) => shop === id)[0];
                street = shopCenter.street;
                building = shopCenter.building;
                nflat = undefined;
                nzipCode = undefined;
            } else if (deliveryWay === 1) {
                nflat = undefined;
            } else {
                nzipCode = undefined;
            }

            const deliveryType = deliveryWays.filter(({ id }) => id === deliveryWay)[0].way;
            const ids = window.localStorage.getItem("shop-bag-ids") ?? "[]";
            const nids = JSON.parse(ids).map((id: number) => {
                const sizes = window.localStorage.getItem(`shop-bag-product-${id}`) ?? "{}";
                const nsizes = JSON.parse(sizes).sizes.map((size: string) => {
                    const amount = window.localStorage.getItem(`shop-bag-product-${id}-size-${size}`) ?? "{}";
                    const namount: number = JSON.parse(amount).amount ?? 0;
                    if (!namount) return {};
                    return { productId: id, size, amount: namount };
                });
                return nsizes;
            });

            const productDto = [].concat(...nids);

            const formValues: OrderCreationType = {
                firstName: values.firstName,
                email: values.email,
                phone: values.phone,
                street,
                building,
                paymentType: check,
                deliveryType,
                orderProductDtoList: productDto,
            };

            if (values.lastName) formValues.lastName = values.lastName;
            if (values.patronymic) formValues.patronymic = values.patronymic;
            if (nflat) formValues.flat = nflat;
            if (nzipCode) formValues.zipCode = nzipCode;
            if (values.comment) formValues.comment = values.comment;

            const os = new OrdersService();
            os.createOrder(formValues)
                .then((res) => {
                    const indexes = window.localStorage.getItem("shop-bag-ids") ?? "[]";
                    JSON.parse(indexes).map((id: number) => {
                        const sizes = window.localStorage.getItem(`shop-bag-product-${id}`) ?? "{}";
                        JSON.parse(sizes).sizes.map((size: string) => {
                            window.localStorage.removeItem(`shop-bag-product-${id}-size-${size}`);
                            return true;
                        });
                        window.localStorage.removeItem(`shop-bag-product-${id}`);
                        return true;
                    });
                    window.localStorage.removeItem("shop-bag-ids");
                    window.localStorage.setItem("amount-to-get", "0");
                    badgeAmount.set(0);
                    setNclicked(false);
                    if (check === "На сайте") {
                        window.location.assign(res.invoiceUrl);
                    } else {
                        history.push(`/?order_created`);
                    }
                })
                .catch(() => {
                    setNclicked(false);
                    setMessage("Что-то пошло не так");
                    setOpen(true);
                });
        },
    });

    const lineListValues = [
        {
            key: "Общая стоимость",
            value:
                check === onSitePayment ? (
                    <>
                        <Typography variant="subtitle2" component="span" color="#E4007D">
                            {summ}
                        </Typography>
                        <Typography variant="subtitle1" component="span">
                            {" "}
                            / <s>{prevSumm}</s>
                        </Typography>
                    </>
                ) : (
                    `${summ} ₽`
                ),
        },
        {
            key: "Доставка",
            value: `${dPrice} ₽`,
        },
    ];

    const setFormikValues = useCallback(
        (res: Partial<UserData>) => {
            formik.setValues({ ...formik.initialValues, ...res });
        },
        [formik]
    );

    useEffect(() => {
        const token = window.localStorage.getItem("jwt") ?? "none";
        if (token !== "none" && !userInfo && !requestError && !loadingUserInfo) {
            const us = new UserService();
            setLoadingUserInfo(true);
            us.getUserInfo(token ?? "")
                .then((res) => {
                    setUserInfo(res);
                    setFormikValues(res);
                    setError(false);
                })
                .catch(() => {
                    setError(true);
                })
                .finally(() => setLoadingUserInfo(false));
        }
    }, [requestError, userInfo, setFormikValues, loadingUserInfo]);

    const mappingFunction = (val: PDInputType) => {
        const newItem = val;
        const name = val.name as keyof FormikDataType;
        if (name !== "check") newItem.value = formik.values[name] ?? "";
        newItem.helperText = formik.errors[name] ?? "";
        newItem.color = formik.errors[name] ? "error" : "secondary";
        newItem.onChange = formik.handleChange;
        return newItem;
    };

    const deliveryPlacesContent = [
        () => (
            <>
                {orderText[0]}
                <InputBlock blockName="Адрес доставки" values={data[1].map(mappingFunction)} />
            </>
        ),
        () => (
            <>
                {orderText[1]}
                <InputBlock blockName="Адрес доставки" values={data[1].map(mappingFunction)} />
            </>
        ),
        () => (
            <>
                {orderText[2]}
                <InputBlock blockName="Адрес вашего отделения" values={data[2].map(mappingFunction)} />
                <DataInput
                    label="Комментарий к заказу"
                    position={0}
                    multiline
                    name="comment"
                    value={formik.values.comment}
                    onChange={formik.handleChange}
                />
                <Divider flexItem />
            </>
        ),
        () => (
            <>
                <Typography variant="h3">Адрес магазина для самовывоза</Typography>
                <Box display="flex" flexDirection="column" gap="1rem">
                    {shops.map((shopPlace) => (
                        <CheckButton
                            key={`${shopPlace.address}_${shopPlace.id}`}
                            value={<ShopPlace {...shopPlace} />}
                            checked={shop === shopPlace.id}
                            onClick={() => handleShopClick(shopPlace.id)}
                            big
                            fullWidth
                        />
                    ))}
                </Box>
                <Divider flexItem />
            </>
        ),
    ];

    return (
        <form onSubmit={formik.handleSubmit} className={s.container}>
            <InputBlock blockName="Личные данные" values={data[0].map(mappingFunction)} />
            <Typography variant="h3">Способ доставки</Typography>
            <div className={s.boxes}>
                {deliveryWays.map(({ id, way }) => (
                    <CheckButton
                        key={`${way}_${id}`}
                        value={<DeliveryWay way={way} />}
                        checked={deliveryWay === id}
                        onClick={() => handleClick(id)}
                        fullWidth={mobile}
                        big
                    />
                ))}
            </div>
            <Divider flexItem />
            {deliveryPlacesContent[deliveryWay]()}
            <Typography variant="h3">Способ оплаты</Typography>
            <FormControl>
                <RadioGroup value={check} onChange={handleChange}>
                    {paymentTypes && !errorPaymentTypes
                        ? paymentTypes.map((value) => (
                              <FormControlLabel value={value} key={value} control={<Radio />} label={value} />
                          ))
                        : "проверьте подключение к интернету"}
                </RadioGroup>
            </FormControl>
            {deliveryWay === 0 ? (
                <Typography variant="subtitle2">
                    Человек платит уже по факту курьеру при личной встрече, предоплачивает посылку самостоятельно или по
                    договорённости созванивается и договаривается с представителем магазина.
                </Typography>
            ) : null}
            <Divider flexItem />
            {check === onSitePayment ? (
                <Box display="flex" flexWrap="wrap" gap="0.75rem" justifyContent="space-between">
                    <Typography variant="h3">Итого к оплате:</Typography>
                    <WhyCheaper title="Почему наличными дешевле" />
                </Box>
            ) : (
                <Typography height="36px" variant="h3">
                    Итого к оплате:
                </Typography>
            )}
            <LineList values={lineListValues} light={false} />
            <Typography variant="h1" component="h2" align="right">
                {summ + dPrice} ₽
            </Typography>
            <FormGroup className={s.formGroup}>
                <FormControlLabel
                    control={<Checkbox color={formik.errors.check ? "error" : undefined} />}
                    name="check"
                    checked={formik.values.check}
                    onChange={formik.handleChange}
                    label={
                        <Typography variant="caption" color={formik.errors.check ? "error" : colors["800"]}>
                            Согласен на обработку персональных данных
                        </Typography>
                    }
                />
            </FormGroup>
            <Button type="submit" variant="contained" fullWidth disabled={disabled || nclicked}>
                Оформить заказ
            </Button>
            {nclicked && (
                <Box
                    position="fixed"
                    left="0"
                    top="0"
                    width="100%"
                    height="100vh"
                    sx={{ background: "rgba(255,255,255, 0.5)" }}
                    display="flex"
                    alignItems="center">
                    <Loader />
                </Box>
            )}
            <Snack
                open={open}
                handleClose={() => setOpen(false)}
                message={<Typography variant="body2">{message}</Typography>}
            />
        </form>
    );
});
