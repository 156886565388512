import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { colors } from "../../theme";
import { ReactComponent as Location } from "../../assets/Location.svg";
import { ReactComponent as Clock } from "../../assets/Clock.svg";

type Props = {
    id: number;
    address: string;
    weekTime: string;
    weekendTime: string;
    phone: string;
};

export const ShopPlace: React.FC<Props> = ({ address, weekTime, weekendTime, phone }) => {
    const mobile = useMediaQuery("(max-width: 600px)");

    return (
        <Box width="100%" display="flex" py="1rem" gap={mobile ? "1rem" : "2.5rem"} flexWrap="wrap">
            <Box display="flex" width={mobile ? "100%" : "34%"} gap="0.5rem">
                <Box color={colors["700"]}>
                    <Location />
                </Box>
                <Typography variant="subtitle2" color="currentColor">
                    {address}
                </Typography>
            </Box>
            <Box display="flex" gap="0.5rem" width={mobile ? "100%" : undefined}>
                <Box color={colors["700"]}>
                    <Clock />
                </Box>
                <div>
                    <Typography variant="subtitle2" component="span" color={colors["700"]}>
                        Пн. — Пт.:{" "}
                    </Typography>
                    <Typography variant="subtitle2" component="span" color="currentColor">
                        {weekTime}
                    </Typography>
                    <br />
                    <Typography variant="subtitle2" component="span" color={colors["700"]}>
                        Сб. — Вс.:{" "}
                    </Typography>
                    <Typography variant="subtitle2" component="span" color="currentColor">
                        {weekendTime}
                    </Typography>
                </div>
            </Box>
            <Typography variant="body1" color="currentColor">
                {phone}
            </Typography>
        </Box>
    );
};
