import { Box, Breadcrumbs, Divider, Link, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { Link as BrowserLink } from "react-router-dom";
import { colors as themeColors } from "../../theme";

export type PathStringProps = {
    values: ValuesProps[];
};

type ValuesProps = {
    link?: string;
    value: ReactElement | string;
};

export const PathString: React.FC<PathStringProps> = ({ values }) => {
    return (
        <Breadcrumbs separator={<Divider orientation="vertical" flexItem />}>
            {values.map(({ link, value }) => {
                if (link) {
                    return (
                        <Link key={`${link}_${value}`} to={link} component={BrowserLink}>
                            <Typography variant="caption" color={themeColors["700"]}>
                                {value}
                            </Typography>
                        </Link>
                    );
                }
                return (
                    <Box key={`${link}_${value}`}>
                        <Typography variant="caption" color={themeColors["700"]}>
                            {value}
                        </Typography>
                    </Box>
                );
            })}
        </Breadcrumbs>
    );
};
