import { IconButton, Snackbar } from "@mui/material";
import React from "react";
import { ReactComponent as Close } from "../../assets/Close.svg";

type Props = {
    open: boolean;
    handleClose: () => void;
    message: JSX.Element;
};

export const Snack: React.FC<Props> = ({ open, handleClose, message }) => {
    const action = (
        <IconButton color="primary" onClick={handleClose} size="small">
            <Close />
        </IconButton>
    );

    return <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} action={action} message={message} />;
};
