import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Loader } from "../../components/Loader/Loader";
import { SortBootsPage } from "../../components/SortBootsPage/SortBootsPage";
import { url } from "../../constants/constants";
import GoodsService from "../../services/GoodsService";
import { Goods } from "../../types/type";
import s from "./SearchPage.module.scss";

type Props = unknown;

export const SearchPage: React.FC<Props> = () => {
    const location = useLocation();
    const search = location.search.split("=")[1];
    const history = useHistory();
    const [goods, setGoods] = useState<Array<Goods>>();
    const searchString = decodeURI(search);
    const [loadingGoods, setLoadingGoods] = useState(false);
    const [errorGoods, setErrorGoods] = useState(false);

    const [sortWay, setSortWay] = useState("PRIORITY");
    const handleSort = (e: React.ChangeEvent<HTMLInputElement>) => setSortWay(e.target.value);

    useEffect(() => {
        setGoods(undefined);
    }, [searchString]);

    useEffect(() => {
        if (!goods && !loadingGoods && !errorGoods) {
            const gs = new GoodsService();
            setLoadingGoods(true);
            gs.getBootsSortedByName(searchString)
                .then((res) => {
                    setGoods(res.res);
                    setErrorGoods(false);
                })
                .catch(() => setErrorGoods(true))
                .finally(() => setLoadingGoods(false));
        }
    }, [goods, searchString, loadingGoods, errorGoods]);

    if (errorGoods) {
        return <Typography variant="h2">Что-то пошло не так</Typography>;
    }

    return (
        <div className={s.container}>
            <Typography variant="h2">Вы искали &quot;{searchString}&quot;</Typography>
            {goods ? (
                <SortBootsPage amount={goods.length} goods={goods} sortWay={sortWay} handleSort={handleSort} />
            ) : (
                <Loader />
            )}
            <Box mt="2rem">
                <Button variant="outlined" fullWidth size="large" onClick={() => history.push(url.shop)}>
                    Перейти в каталог
                </Button>
            </Box>
        </div>
    );
};
