import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { colors as themeColors } from "../../theme";

type Props = {
    size?: string | number;
    thickness?: number;
};

export const Loader: React.FC<Props> = ({ size, thickness }) => {
    return (
        <Box position="relative" display="flex" justifyContent="center" width="100%" py="4rem">
            <Box position="relative">
                <CircularProgress
                    variant="determinate"
                    sx={{
                        color: themeColors["200"],
                    }}
                    size={size ?? 120}
                    thickness={thickness ?? 3}
                    value={100}
                />
                <CircularProgress
                    variant="indeterminate"
                    color="primary"
                    sx={{
                        position: "absolute",
                        left: 0,
                        animationDuration: "2s",
                    }}
                    size={size ?? 120}
                    thickness={thickness ?? 3}
                />
            </Box>
        </Box>
    );
};
