import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { tabs } from "../../../constants/constants";

type Props = { orientation?: "vertical" | "horizontal"; hidden?: boolean; onClick?: () => void };

const tabsUrls = tabs.header.map(({ url }) => url);

export const LinkTabs: React.FC<Props> = ({ hidden, orientation, onClick }) => {
    const location = useLocation();
    const tabVal = location.pathname.split("/")[1];
    const [value, setValue] = useState<string | boolean>(tabsUrls.includes(tabVal) ? tabVal : false);

    useEffect(() => {
        const splitLocation = location.pathname.split("/")[1];
        const newVal = tabsUrls.includes(splitLocation) ? splitLocation : false;
        setValue(newVal);
    }, [location.pathname]);

    if (hidden) return null;

    return (
        <Tabs value={value} orientation={orientation}>
            {tabs.header.map((t) => (
                <Tab to={`/${t.url}`} onClick={onClick} component={Link} label={t.name} value={t.url} key={t.url} />
            ))}
        </Tabs>
    );
};
